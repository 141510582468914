export default function RightArrowIcon({ id }) {
  return (
    <svg
      id={id}
      width="7"
      height="10"
      viewBox="0 0 7 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.218774 8.70573L3.8085 5.10816L0.218774 1.5106L1.32391 0.405461L6.02661 5.10816L1.32391 9.81087L0.218774 8.70573Z"
        fill="#323232"
      />
    </svg>
  );
}
