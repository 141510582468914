import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const activeChatSlice = createSlice({
  name: 'activeChat',
  initialState,
  reducers: {
    setActiveChat(state, action) {
      return action.payload;
    },
    setActiveChatConversation(state, action) {
      return { ...state, cleanConversation: action.payload };
    },
  },
});

export const readActiveChat = (state) => state.activeChat;

export const { setActiveChat, setActiveChatConversation } =
  activeChatSlice.actions;

export default activeChatSlice.reducer;
