import { apiSlice } from '../../app/api/apiSlice';
import { setCart } from '../cart/cartSlice';
import { setFavorites } from '../favorites/favoritesSlice';
import { setUserInfo } from './userSlice';
import Cookies from 'js-cookie';

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserInfo: builder.mutation({
      query: () => ({
        url: 'api/user/getInfo/',
        method: 'GET',
        credentials: 'include',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setCart(data.cart));
          dispatch(setFavorites(data.favorites));
          Cookies.set('cookieConsent', JSON.stringify(data.cookies), {
            expires: 180,
          });
        } catch (err) {}
      },
    }),
    getUserData: builder.mutation({
      query: () => ({
        url: 'api/user/data',
        method: 'GET',
        credentials: 'include',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUserInfo(data));
        } catch (err) {
          console.log(err);
        }
      },
    }),
    setBasicUserData: builder.mutation({
      query: (data) => ({
        url: 'api/user/data/basic',
        method: 'POST',
        credentials: 'include',
        body: data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUserInfo(data));
        } catch (err) {
          console.log(err);
        }
      },
    }),
    changePassword: builder.mutation({
      query: (data) => ({
        url: 'api/user/change-password',
        method: 'POST',
        credentials: 'include',
        body: data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          return data;
        } catch (err) {
          console.log(err);
        }
      },
    }),
    verifyChangePassword: builder.mutation({
      query: (data) => ({
        url: `api/user/change-password/${data.userId}/${data.token}`,
        method: 'POST',
        credentials: 'include',
        body: data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          console.log(err);
        }
      },
    }),
    changeEmail: builder.mutation({
      query: (data) => ({
        url: 'api/user/change-email',
        method: 'POST',
        credentials: 'include',
        body: data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          console.log(err);
        }
      },
    }),
    verifyChangeEmail: builder.mutation({
      query: (data) => ({
        url: `api/user/change-email/${data.userId}/${data.token}`,
        method: 'POST',
        credentials: 'include',
        body: data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          console.log(err);
        }
      },
    }),
    enabled2FA: builder.mutation({
      query: (data) => ({
        url: `api/user/2fa/enable`,
        method: 'POST',
        credentials: 'include',
        body: data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          console.log(err);
        }
      },
    }),
    activate2FA: builder.mutation({
      query: (data) => ({
        url: `api/user/2fa/activate`,
        method: 'POST',
        credentials: 'include',
        body: data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          console.log(err);
        }
      },
    }),
    disable2fa: builder.mutation({
      query: (data) => ({
        url: `api/user/2fa/disable`,
        method: 'POST',
        credentials: 'include',
        body: data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          console.log(err);
        }
      },
    }),
    deactivate2FA: builder.mutation({
      query: (data) => ({
        url: `api/user/2fa/deactivate`,
        method: 'POST',
        credentials: 'include',
        body: data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          console.log(err);
        }
      },
    }),
    deleteAccount: builder.mutation({
      query: (data) => ({
        url: `api/user/delete`,
        method: 'POST',
        credentials: 'include',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          console.log(err);
        }
      },
    }),
    confirmDeleteAccount: builder.mutation({
      query: (data) => ({
        url: `api/user/delete/${data.userId}/${data.token}`,
        method: 'DELETE',
        credentials: 'include',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          console.log(err);
        }
      },
    }),
    addQuestions: builder.mutation({
      query: (data) => ({
        url: 'api/user/questions/add-question',
        method: 'POST',
        credentials: 'include',
        body: data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          console.log(err);
        }
      },
    }),
    addAnswer: builder.mutation({
      query: (data) => ({
        url: 'api/user/questions/add-answer',
        method: 'POST',
        credentials: 'include',
        body: data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          console.log(err);
        }
      },
    }),
  }),
});

export const {
  useGetUserDataMutation,
  useGetUserInfoMutation,
  useSetBasicUserDataMutation,
  useChangePasswordMutation,
  useVerifyChangePasswordMutation,
  useChangeEmailMutation,
  useVerifyChangeEmailMutation,
  useAddQuestionsMutation,
  useAddAnswerMutation,
  useEnabled2FAMutation,
  useActivate2FAMutation,
  useDeleteAccountMutation,
  useConfirmDeleteAccountMutation,
  useDisable2faMutation,
  useDeactivate2FAMutation,
} = userApiSlice;
