import { createSlice } from '@reduxjs/toolkit';

const initialState = { value: 'Smartphone' };

const menuCategorySlice = createSlice({
  name: 'menuCategory',
  initialState,
  reducers: {
    setMenuCategory(state, action) {
      state.value = action.payload;
    },
  },
});

export const readMenuCategory = (state) => state.menuCategory.value;

export const { setMenuCategory } = menuCategorySlice.actions;

export default menuCategorySlice.reducer;
