import '../../styles/Footer/bottom-footer.scss';
import { useTranslation } from 'react-i18next';

export default function BottomFooter() {
  const { t } = useTranslation();
  return (
    <div className="bottom-footer">
      <div className="wrapper">
        <p>© 2023 Wilfind, {t('bottom_footer.all_rights_reserved')}</p>
        <div className="legal">
          <a href="/privacy-policy">{t('footer.privacy_policy')}</a>
        </div>
      </div>
    </div>
  );
}
