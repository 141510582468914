import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: { token: null, user: null, planType: null },
  reducers: {
    setCredentials: (state, action) => {
      const { accessToken, user, planType } = action.payload;
      state.token = accessToken;
      state.user = user;
      state.planType = planType;
    },
    logOut: (state, action) => {
      state.token = null;
      state.user = null;
      state.planType = null;
    },
  },
});

export const { setCredentials, logOut } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentPlanType = (state) => state.auth.planType;

export const selectCurrentToken = (state) => state.auth.token;
