import { createSlice } from '@reduxjs/toolkit';

const initialState = { value: '' };

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage(state, action) {
      state.value = action.payload;
      // set language in local storage
    },
  },
});

export const readLanguage = (state) => state.language.value;

export const { setLanguage } = languageSlice.actions;

export default languageSlice.reducer;
