export default function CartIcon() {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 4H14C15.1 4 16 4.9 16 6V18C16 19.1 15.1 20 14 20H2C0.9 20 0 19.1 0 18V6C0 4.9 0.9 4 2 4H4H6H8H10H12Z"
        fill="#232323"
      />
      <path d="M14 6H2V18H14V6Z" fill="white" />
      <path
        d="M4 6L4 4C4 4 4 0 8 0C12 0 12 4 12 4V6V8C12 8 12 9 11 9C10 9 10 8 10 8V6V4C10 4 10 2 8 2C6 2 6 4 6 4V6V8C6 8 6 9 5 9C4 9 4 8 4 8V6Z"
        fill="#232323"
      />
    </svg>
  );
}
