import '../../styles/PopUp/cookie-pop-up.scss';
import { useState } from 'react';
import Cookies from 'js-cookie';
import { Trans, useTranslation } from 'react-i18next';
import RightArrowIcon from '../../Icons/RightArrowIcon';
import { Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentToken } from '../../features/auth/authSlice';
import { useUpdateCookiesMutation } from '../../features/cookies/cookiesApiSlice';
import { toggleCookie } from '../../features/popUp/popUpSlice';

export default function CookiePopUp() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = useSelector(selectCurrentToken);
  const [showOptions, setShowOptions] = useState(false);
  const [cookieOptions, setCookieOptions] = useState({
    essential: true,
    analytics: false,
    ads: false,
  });
  const [updateCookies] = useUpdateCookiesMutation();
  const [showMore, setShowMore] = useState({
    essential: false,
    analytics: false,
    ads: false,
  });
  const cookieConsent = Cookies.get('cookieConsent')
    ? JSON.parse(Cookies.get('cookieConsent'))
    : null;

  const handleSavePreference = async () => {
    if (token) {
      await updateCookies({ cookies: cookieOptions });
    }
    Cookies.set('cookieConsent', JSON.stringify(cookieOptions), {
      expires: 180,
    });
    dispatch(toggleCookie());
  };

  const handleAcceptAll = async () => {
    if (token) {
      await updateCookies({
        cookies: { essential: true, analytics: true, ads: true },
      });
    }
    Cookies.set(
      'cookieConsent',
      JSON.stringify({ essential: true, analytics: true, ads: true }),
      {
        expires: 180,
      }
    );
    dispatch(toggleCookie());
  };

  const handleDeclineAll = () => {
    if (token) {
      updateCookies({
        cookies: { essential: true, analytics: false, ads: false },
      });
    }
    Cookies.set(
      'cookieConsent',
      JSON.stringify({ essential: true, analytics: false, ads: false }),
      {
        expires: 180,
      }
    );
    dispatch(toggleCookie());
  };

  return (
    <div className="privacy-pop-up">
      <div className="wrapper">
        <div className="header">
          <div className="logo">
            <img src="/img/wilfind.png" alt="Wilfind" height="20" width="75" />
          </div>
          <div className="continue" onClick={() => handleDeclineAll()}>
            <p>{t('cookies.continue_without_accepting')}</p>
            <RightArrowIcon />
          </div>
        </div>
        <p className="title">{t('cookies.policy')}</p>
        <div className="description">
          {showOptions ? (
            <>
              <p>
                <Trans i18nKey="cookies.manage_description">
                  <br />
                  <br />
                  <br />
                </Trans>
              </p>
              <div className="divider"></div>
              <div className="cookie-wrapper">
                <div className="text-wrapper">
                  <p
                    style={
                      showMore.essential ? { WebkitLineClamp: 'initial' } : {}
                    }
                  >
                    <Trans i18nKey="cookies.essential">
                      <span></span>
                    </Trans>
                  </p>
                  <span
                    className="see-more"
                    onClick={() =>
                      setShowMore({
                        ...showMore,
                        essential: !showMore.essential,
                      })
                    }
                  >
                    {showMore.essential
                      ? t('global.view_less')
                      : t('global.view_more')}
                  </span>
                </div>
                {showOptions && <Switch defaultChecked disabled />}
              </div>
              <div className="cookie-wrapper">
                <div className="text-wrapper">
                  <p
                    style={
                      showMore.analytics ? { WebkitLineClamp: 'initial' } : {}
                    }
                  >
                    <Trans i18nKey="cookies.analytics">
                      <span></span>
                    </Trans>
                  </p>
                  <span
                    className="see-more"
                    onClick={() =>
                      setShowMore({
                        ...showMore,
                        analytics: !showMore.analytics,
                      })
                    }
                  >
                    {showMore.essential
                      ? t('global.view_less')
                      : t('global.view_more')}
                  </span>
                </div>
                {showOptions && (
                  <Switch
                    onChange={(value) => {
                      setCookieOptions({ ...cookieOptions, analytics: value });
                    }}
                    defaultChecked={cookieConsent?.analytics}
                  />
                )}
              </div>
              <div className="cookie-wrapper">
                <div className="text-wrapper">
                  <p style={showMore.ads ? { WebkitLineClamp: 'initial' } : {}}>
                    <Trans i18nKey="cookies.ads">
                      <span></span>
                    </Trans>
                  </p>
                  <span
                    className="see-more"
                    onClick={() =>
                      setShowMore({ ...showMore, ads: !showMore.ads })
                    }
                  >
                    {showMore.ads
                      ? t('global.view_less')
                      : t('global.view_more')}
                  </span>
                </div>
                {showOptions && (
                  <Switch
                    onChange={(value) =>
                      setCookieOptions({ ...cookieOptions, ads: value })
                    }
                    defaultChecked={cookieConsent?.ads}
                  />
                )}
              </div>
            </>
          ) : (
            <p>
              <Trans i18nKey="cookies.description">
                <span></span>
                <br />
                <br />
                <br />
                <br />
              </Trans>
            </p>
          )}
          <a href="/cookies-policy">{t('cookies.consult_policy')}</a>
        </div>
        <div className="button-wrapper">
          <button className="button-28" onClick={() => handleDeclineAll()}>
            {t('cookies.decline')}
          </button>
          <button
            className={showOptions ? 'button-27' : 'button-28'}
            onClick={() => setShowOptions(!showOptions)}
          >
            {t('cookies.manage')}
          </button>
          {showOptions ? (
            <button
              className="button-29"
              onClick={() => handleSavePreference()}
            >
              {t('cookies.save')}
            </button>
          ) : (
            <button className="button-29" onClick={() => handleAcceptAll()}>
              {t('cookies.accept')}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
