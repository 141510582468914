import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  type: '',
  value: '',
};

const topMessageSlice = createSlice({
  name: 'topMessage',
  initialState,
  reducers: {
    setTopMessage: (state, action) => {
      state.type = action.payload.type;
      state.value = action.payload.value;
    },
    clearTopMessage: (state) => {
      state.type = '';
      state.value = '';
    },
  },
});

export const readTopMessage = (state) => state.topMessage;

export const { setTopMessage, clearTopMessage } = topMessageSlice.actions;

export default topMessageSlice.reducer;
