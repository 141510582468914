const es = {
  translation: {
    header: {
      top: {
        news_1: 'Nuevo Wilbot AI',
        news_2: 'Iphone 15',
        help: 'Ayuda',
        faq: 'FAQ',
        affiliation: 'Afiliación',
        subscriptions: 'Suscripciones',
      },
      pages: {
        home: 'Inicio',
        comparator: 'Comparador',
        ai_availability: 'AI Disponible',
        'product-results': 'Búsqueda',
        'product-page': 'Producto',
        favorites: 'Favoritos',
        cart: 'Carrito',
        history: 'Historial',
        'legal-notice': 'Legales',
        affiliate: 'Afiliación',
        'about-us': 'Acerca de',
        help: 'Ayuda',
        faq: 'FAQ',
        subscriptions: 'Suscripciones',
        'subscriptions-success': 'Suscripciones',
        'reset-password': 'Contraseña',
        'password-reset': 'Contraseña',
        'change-password': 'Contraseña',
        'change-email': 'Correo electrónico',
        'verify-account': 'Cuenta',
        'delete-account': 'Cuenta',
        account: 'Cuenta',
        'subscription-success': 'Éxito',
        'terms-conditions': 'Condiciones',
        'cookies-policy': 'Cookies',
        'manage-cookies': 'Cookies',
        'privacy-policy': 'Privacidad',
      },
    },
    global: {
      products: 'productos',
      see_the_products: 'Ver los productos',
      search_plus: 'Buscar +',
      page_not_found: 'Página no encontrada',
      manage_cookie: 'Administrar cookies',
      coming_soon: 'Muy pronto...',
      discover: 'Descubrir',
      currency: '€',
      close: 'Cerrar',
      home: 'Inicio',
      or: 'O',
      view: 'Ver',
      view_more: 'Ver más',
      view_less: 'Ver menos',
      register: 'Registrarse',
      login: 'Iniciar sesión',
      login_short: 'Conexión',
      logout: 'Cerrar sesión',
      my_account: 'Mi cuenta',
      quit: 'Salir',
      remove: 'Eliminar',
      add_to_cart: 'Añadir al carrito',
      remove_from_cart: 'Quitar del carrito',
      add_to_favorite: 'Añadir a favoritos',
      remove_from_favorite: 'Quitar de favoritos',
      add_to_comparator: 'Añadir al comparador',
      remove_from_comparator: 'Quitar del comparador',
      buy_on_the_site: 'Comprar en el sitio',
      available: 'Disponible',
      unavailable: 'No disponible',
      loading: 'Cargando...',
      buy: 'Comprar',
      confirm: 'Confirmar',
      send: 'Enviar',
      redirect_protected: 'Su redirección está protegida',
      alert_buy:
        'El sitio no permite comprar los productos. Una vez comprado el producto, será redirigido al sitio del vendedor.',
      all: 'Todos',
      products: 'Productos',
      total: 'Total',
      off: 'Descuento',
      modify: 'Modificar',
      my_profile: 'Mi perfil',
      information: 'Informaciones',
      security: 'Seguridad',
      reviews: 'opiniones',
      save: 'Guardar',
      delete: 'Eliminar',
      data_use: 'Uso de datos',
      confidential: 'Acuerdo de confidencialidad',
      history: 'Historial',
      authorize_redirection: 'Autorizo la redirección',
      forgot_password: '¿Olvidó su contraseña?',
      fill_all_fields: 'Debe llenar todos los campos.',
      default_error: 'Ocurrió un error.',
      email_confirmation: 'Recibirá un correo electrónico de confirmación.',
      fill_captcha: 'Por favor, complete el captcha.',
      email_sent: 'Correo electrónico enviado.',
      password_changed: 'Su contraseña ha sido modificada correctamente.',
      link_invalid_or_expired: 'Enlace inválido o expirado.',
      thanks_for_using:
        'Gracias por usar nuestros servicios, esperamos verlo de nuevo.',
      no_results: 'No hay resultados para: ',
      search: 'Buscar',
      result_search: 'Resultado para:',
      filter: 'Filtrar',
      validate: 'Validar',
      cancel: 'Cancelar',
      next: 'Siguiente',
      precedent: 'Anterior',
      return: 'Regresar',
      redirect_in_3_seconds: 'Redirección en 3 segundos...',
      category: {
        title: 'Categorías',
        Smartphone: 'Smartphone',
        Tablette: 'Tableta',
        'Ordinateur Portable': 'Ordenador portátil',
        'Unité Centrale': 'Unidad central',
        'Ordinateur Tout en Un': 'Ordenador todo en uno',
      },
    },
    subscriptions: {
      title: 'Suscripciones Wilfind',
      description: 'Nuestras diferentes ofertas.',
      use: 'Uso',
      features: {
        title: 'Elija la oferta que mejor se adapte a sus necesidades.',
        compare_offer: 'Compare nuestras diferentes ofertas.',
        recommended: 'Recomendado',
        number_of_research_by_day: 'Número de búsquedas por día',
        access_to_early_access: 'Acceso anticipado',
        priority_access: 'Acceso prioritario',
        question: 'Pregunta +',
        priority_customer_service: 'Servicio al cliente prioritario',
        expert: 'Experto +',
        on_mesure_offer: 'Oferta a medida',
      },
      subscription: {
        contact_us: 'Contáctenos',
        contact: 'Contacto',
        try_for_free: 'Pruebe gratis',
        get_started: 'Comenzar',
        billed_monthly: 'Facturado mensualmente',
        resiliation: 'Rescisión en cualquier momento',
        description_0:
          'Para particulares que quieran probar Wilbot de forma gratuita.',
        description_1:
          'El plan Plus es perfecto para poder usar Wilbot libremente.',
        description_2:
          'Este plan le permite usar Wilbot y otras funcionalidades.',
        description_3:
          'El plan Ultra es el más completo, disfrute de Wilfind sin límites.',
        description_4: 'Para empresas que deseen usar Wilbot para su negocio.',
        features: {
          '1 product / day': '1 producto / día',
          '5 product / day': '5 productos / día',
          '10 product / day': '10 productos / día',
          '30 product / day': '30 productos / día',
          '∞ product / day': '∞ productos / día',
          'Early access': 'Acceso anticipado',
          'Priority access': 'Acceso prioritario',
          'Question +': 'Pregunta +',
          'Priority customer service': 'Servicio al cliente prioritario',
          'Expert +': 'Experto +',
          'On mesure offer': 'Oferta a medida',
        },
      },
    },
    manage_subscriptions: {
      title: 'Administrar mi suscripción',
      manage_my_subscription: 'Administrar mi suscripción',
      see_different_plans: 'Ver diferentes planes',
      redirect_stripe: 'Será redirigido a Stripe.com',
      actual_subscription:
        'Actualmente tiene la suscripción <0>{0}</0> hasta el <1></1>.',
    },
    home: {
      title: {
        1: 'Bienvenido, ¿cómo puedo ayudarte hoy ?',
        2: 'Bienvenido a Wilfind.com, ¿ qué producto estás buscando ?',
        3: 'Bienvenido a Wilfind.com, ¿ qué te interesa ?',
        4: 'Bienvenido a Wilfind.com, ¿ qué buscas ?',
        5: 'Buscar está bien, encontrar es mejor.',
        6: '¿ Buscas un teléfono inteligente ?',
        7: '¿ Buscas una computadora ?',
        8: '¿ Buscas una tablet ?',
        9: 'Hola, soy Wilbot, tu asistente de búsqueda.',
        10: 'Hola, bienvenido/a a Wilfind.com.',
        11: 'Hola, soy tu asistente de búsqueda, Wilbot.',
        12: 'Encuentra lo que necesitas en Wilfind.com.',
      },
      discover: 'Descubra el futuro del comercio electrónico con ',
      description: 'Encuentre el producto ideal para usted.',
      try_wilbot: 'Pruebe Wilbot',
      try_wilbot_details: 'Pruebe Wilbot gratis (una vez al día)',
      support: '¿Necesita ayuda? Contáctenos.',
      support_link: 'Soporte',
      account: 'Si ya es cliente, inicie sesión.',
      account_link: 'Cuenta',
      new_macbook_pro: 'Nuevo Macbook<0></0><1>PRO</1><2>Comprar ></2>',
      buy_now: 'Comprar ahora',
      text_1: '<0>Wilbot</0>, busca entre <1>miles de productos.</1>',
      text_2:
        'Encuentre el <0>producto ideal</0> para usted <1>fácilmente.</1>',
      text_3: '¿Publicidad KickStart AQUÍ?',
      partners: 'Nuestros socios',
    },
    search: {
      placeholder: 'Buscar un producto',
    },
    menu_pop_up: {
      description:
        'Con Wilbot, encontrará el producto que mejor se adapte a usted. ¡Pruebe la experiencia Wilbot ahora!',
      try_wilbot: 'Pruebe Wilbot',
    },
    cart_pop_up: {
      title: 'Carrito',
      empty: 'Su carrito está vacío.',
      empty_description: 'Aún no ha añadido ningún artículo.',
      view_cart: 'Ver carrito',
    },
    redirection_pop_up: {
      title: 'Permitir pop-ups',
      description:
        'Para ser redirigido a las páginas del comerciante, debe permitir las ventanas emergentes en su navegador web.',
    },
    comparator: {
      title: 'Comparador',
      add: 'Añadir',
      description: 'Compare productos para tomar la decisión correcta.',
      add_product: 'Añadir un producto',
      by_default: 'Por defecto',
      performance: 'Rendimiento',
      screen: 'Calidad de pantalla',
      photo: 'Calidad de foto',
      video: 'Calidad de video',
      gaming: 'Juegos',
      work: 'Trabajo',
      school: 'Estudios',
      transport: 'Transporte',
      asc_price: 'Precio ascendente',
      desc_price: 'Precio descendente',
      error_min: 'Debe agregar al menos 2 productos.',
      error_max: 'Puede agregar un máximo de 3 productos.',
      placeholder: 'Buscar un producto para comparar',
      empty: 'Su comparador está vacío.',
      suggestions: 'Sugerencias',
    },
    price_chart: {
      title: 'Evolución de los precios',
      week: '1 semana',
      month: '1 mes',
      six_months: '6 meses',
      year: 'Un año',
      all: 'Todos',
    },
    cart: {
      title: 'Su carrito',
      description: 'Encuentre los artículos añadidos a su carrito.',
      only_fnac: 'Solo Fnac',
      only_darty: 'Solo Darty',
      empty: 'Su carrito está vacío.',
      empty_description: 'Aún no ha añadido ningún artículo.',
      error_redirect: 'Debe aceptar la redirección para acceder a los enlaces.',
      redirect_prevention: 'He verificado los comerciantes seleccionados.',
    },
    favorites: {
      title: 'Sus favoritos',
      description: 'Encuentre sus productos favoritos.',
      empty: 'Aún no tiene favoritos.',
      empty_description: 'Aún no ha añadido ningún producto a sus favoritos.',
    },
    product: {
      reparability_title: 'ÍNDICE DE REPARABILIDAD',
      merchant_selection: 'Selección del comerciante:',
      unit: 'Unidad',
      compare: 'Comparar',
      not_compare: 'No comparar',
      see_the_product: 'Ver el producto',
      not_found: 'Producto no encontrado',
      available: 'Disponible',
      unavailable: 'No disponible',
      tags: {
        cheapest: 'El más barato',
        mostEfficient: 'El más eficiente',
        bestReviews: 'El mejor valorado',
        bestSeller: 'El más vendido',
        new: 'Novedad',
        best_price: 'Mejor precio',
      },
      photo: {
        title: 'Foto',
        captor: 'Sensor de imagen',
        with_pixel_size: 'con píxeles de un tamaño de',
        photo_lens: {
          'Grand Angle': 'Gran Angular',
          'Ultra Grand Angle': 'Ultra Gran Angular',
          Périscope: 'Periscopio',
          'Télé-objectif': 'Teleobjetivo',
          Depth: 'Profundidad',
          Macro: 'Macro',
          Selfie: 'Selfie',
          'Selfie 2': 'Selfie 2',
        },
      },
      features_title: {
        Global: 'General',
        Performance: 'Rendimiento',
        Stockage: 'Almacenamiento',
        Écran: 'Pantalla',
        Photo: 'Foto',
        Vidéo: 'Video',
        Autre: 'Otros',
      },
      features: {
        true: 'Sí',
        false: 'No',
        performance: 'Rendimiento',
        photo: 'Foto',
        video: 'Video',
        photo_video: 'Foto y video',
        screen: 'Pantalla',
        total: 'Total',
        Os: 'Sistema Operativo',
        "Taille d'écran": 'Tamaño de pantalla',
        "Dalle d'écran": 'Panel de pantalla',
        "Densité de pixels sur l'écran": 'Densidad de píxeles en la pantalla',
        "Définition de l'écran": 'Resolución de pantalla',
        "Taux de rafraîchissement de l'écran":
          'Tasa de actualización de la pantalla',
        "Taux de rafraîchissement de l'écran adaptatif":
          'Tasa de actualización adaptativa de la pantalla',
        "Luminosité maximal de l'écran": 'Brillo máximo de la pantalla',
        Processeur: 'Procesador',
        'Processeur graphique': 'Tarjeta gráfica',
        RAM: 'RAM',
        'Définition vidéo': 'Resolución de video',
        'Fréquence maximal vidéo': 'Frecuencia máxima de video',
        'HDR écran': 'HDR pantalla',
        'HDR caméra': 'HDR cámara',
        'Dolby Vision caméra': 'Cámara Dolby Vision',
        'ProRES caméra': 'Cámara ProRES',
        "Temps de réponse de l'écran": 'Tiempo de respuesta de la pantalla',
        'Always On Display': 'Always On Display',
        Couleur: 'Color',
        'RAW Caméra': 'Cámara RAW',
        Stockage: 'Almacenamiento',
        Masse: 'Peso',
        Dimensions: 'Dimensiones',
        'Indice DAS Tronc': 'Índice DAS Tronco',
        'Indice DAS Tête': 'Índice DAS Cabeza',
        'Indice DAS membre': 'Índice DAS Miembro',
        'Note de réparabilité': 'Puntuación de reparabilidad',
        'Autres informations': 'Otra información',
        Sécurité: 'Seguridad',
        Capteurs: 'Sensores',
        Connectivité: 'Conectividad',
        Connectiques: 'Conectores',
        'Définition photo': 'Resolución de la foto',
        'Regroupement des pixels': 'Agrupación de píxeles',
        'Taille des pixels': 'Tamaño de píxeles',
        'Taille focal': 'Longitud focal',
        'Ouverture du diaphragme': 'Apertura del diafragma',
        Stabilisation: 'Estabilización',
        'Écran tactile': 'Pantalla táctil',
        'Carte graphique': 'Tarjeta gráfica',
        'Type de stockage': 'Tipo de almacenamiento',
        Webcam: 'Cámara web',
        'Zoom Maximal': 'Zoom Máximo',
        'True Tone': 'True Tone',
      },
      colors: {
        Graphite: 'Grafito',
        Bleu: 'Azul',
        Noir: 'Negro',
        Crème: 'Crema',
        Argent: 'Plata',
        'Gris sidéral': 'Gris sidéral',
        Rouge: 'Rojo',
        'Noir sidéral': 'Negro sidéral',
        'Lumière Stellaire': 'Luz Estelar',
        'Violet intense': 'Violeta intenso',
        Or: 'Oro',
        Gris: 'Gris',
        Lavande: 'Lavanda',
        Ivoire: 'Marfil',
        'Lumière stellaire': 'Luz Estelar',
        Mauve: 'Malva',
        Minuit: 'Medianoche',
        Violet: 'Violeta',
        Vert: 'Verde',
        'Noir Volcanique': 'Negro Volcánico',
        'Vert Citron': 'Verde Limón',
        'Vert Sauge': 'Verde Salvia',
        'Blanc Tempête': 'Blanco Tormenta',
        Menthe: 'Menta',
        'Blanc Neige': 'Blanco Nieve',
        Neige: 'Nieve',
        'Noir Fantôme': 'Negro Fantasma',
        'Rouge Coucher de Soleil': 'Rojo Atardecer',
        'Blanc Clair de Lune': 'Blanco Luz de Luna',
        Beige: 'Beige',
        Blanc: 'Blanco',
        'Noir Minuit': 'Negro Medianoche',
        'Gris Sidéral': 'Gris Sidéral',
        'Noir Glacé': 'Negro Glacé',
        'Or rose': 'Oro rosa',
        'Blanc Givré': 'Blanco Helado',
        'Arc-en-Ciel': 'Arco iris',
        'Bleu étoilé': 'Azul estrellado',
        'Vert Glacé': 'Verde Glacé',
        'Gris ardoise': 'Gris pizarra',
        'Bleu nuit': 'Azul noche',
        'Argent naturel': 'Plata natural',
        'Bleu espace': 'Azul espacio',
        Platine: 'Platino',
        'Blanc lunaire': 'Blanco lunar',
        Anthracite: 'Antracita',
        'Bleu Glacier': 'Azul glaciar',
        'Gris fer': 'Gris hierro',
        Sable: 'Arena',
        'Vert Sauge ': 'Verde Salvia',
        'Argent platine': 'Plata platino',
        'Gris arctique': 'Gris ártico',
        'Gris graphite': 'Gris grafito',
        'Gris onyx': 'Gris ónix',
        Startblue: 'Startblue',
        'Gris platine': 'Gris platino',
        'Gris lunaire': 'Gris lunar',
        'Gris acier': 'Gris acero',
        Jaune: 'Amarillo',
        'Noir et argent': 'Negro y plata',
        'Blanc et noir': 'Blanco y negro',
        'Noir avec insert Or': 'Negro con inserto de Oro',
        'Gris et blanc': 'Gris y blanco',
        'Argent métallique': 'Plata metálica',
        Rose: 'Rosa',
        Orange: 'Naranja',
        'Noir étoile': 'Negro estelar',
        'Noir et bleu': 'Negro y azul',
        'Gris anthracite': 'Gris antracita',
        'Bleu caméléon': 'Azul camaleón',
        'Gris minéral': 'Gris mineral',
        'Noir corbeau': 'Negro cuervo',
        'Métal argenté mica': 'Metal plateado mica',
        'Bronze Mystique': 'Bronce místico',
        'Gris sidéra': 'Gris sidéra',
        'Natural Titanium': 'Titanio natural',
        Pêche: 'Melocotón',
        Argenté: 'Plateado',
        Porcelaine: 'Porcelana',
      },
    },
    most_viewed: {
      title: 'Los más vistos',
    },
    profile_pop_up: {
      title: 'Hola, bienvenido a Wilfind',
      description:
        'En Wilfind, nos comprometemos a ofrecer una experiencia de compra en línea sin igual para productos de alta tecnología. Nuestra plataforma ofrece una amplia selección de artículos de calidad de las marcas más reconocidas del mercado.',
      support_contact:
        'Para cualquier problema de conexión o registro, contacte al soporte.',
      support_link: 'Soporte',
    },
    login: {
      title: 'Inicio de sesión',
      description:
        'Inicie sesión para acceder a su cuenta personal y aprovechar al máximo la experiencia de Wilbot.',
      no_account: '¿Aún no tienes una cuenta?',
      register: 'Registrarse',
      two_factor_title: 'Autenticación de dos factores',
      two_factor_description:
        'Por favor, introduzca su código de autenticación de dos factores para iniciar sesión.',
      with_google: 'Continuar con Google',
      success: 'Inicio de sesión exitoso, será redirigido...',
      alert:
        'Al iniciar sesión, usted acepta nuestros <0>Términos y Condiciones</0> y nuestra <1>Política de Privacidad.</1>',
    },
    register: {
      title: 'Registro',
      description:
        'Regístrese para acceder a Wilbot de forma gratuita y a todas las funcionalidades de Wilfind.',
      already_account: '¿Ya tiene una cuenta?',
      login: 'Iniciar sesión',
      with_google: 'Continuar con Google',
      validation_title: 'Validar su cuenta por correo electrónico',
      validation_description:
        'Le hemos enviado un correo electrónico con un código de validación. Por favor, introdúzcalo a continuación.',
      register_success: 'Registro exitoso, será redirigido...',
      continue_register: 'Continuar el registro',
      alert:
        'Al registrarse, usted acepta nuestros <0>Términos y Condiciones</0> y nuestra <1>Política de Privacidad.</1>',
    },
    input: {
      name: 'Nombre',
      name_placeholder: 'Introduzca su nombre',
      firstname: 'Apellido',
      firstname_placeholder: 'Introduzca su apellido',
      email: 'Correo electrónico',
      email_placeholder: 'Introduzca su correo electrónico',
      actual_password: 'Contraseña actual',
      actual_password_placeholder: 'Introduzca su contraseña actual',
      password: 'Contraseña',
      password_placeholder: 'Introduzca su contraseña',
      verify_password: 'Verificación de contraseña',
      verify_password_placeholder: 'Confirme su contraseña',
      two_factor: 'Código de autenticación de dos factores',
      two_factor_placeholder: 'Introduzca su código de autenticación',
      verification_code: 'Código de verificación',
      verification_code_placeholder: 'Introduzca su código de verificación',
      new_password: 'Nueva contraseña',
      new_password_placeholder: 'Introduzca su nueva contraseña',
      new_email: 'Nuevo correo electrónico',
      new_email_placeholder: 'Introduzca su nuevo correo electrónico',
      type_of_problem: 'Tipo de problema',
      your_problem: 'Su problema',
      your_problem_placeholder: 'Describa su problema',
      search_placeholder: 'Buscar',
    },
    pass_checklist: {
      min_length: 'Al menos 8 caracteres',
      special_char: 'Al menos un carácter especial',
      number: 'Al menos un número',
      capital: 'Al menos una letra mayúscula',
      match: 'Las contraseñas deben coincidir',
    },
    reset_password: {
      title: 'Restablecimiento de contraseña',
      description:
        'Por favor, introduzca la dirección de correo electrónico asociada a su cuenta para restablecer su contraseña.<0 /><1/> Se le enviará un enlace de restablecimiento por correo electrónico.<2 /><3/> Asegúrese de revisar su bandeja de entrada y su carpeta de spam.<4/><5/> Gracias por su confianza y estamos aquí para ayudarle durante todo el proceso. Su seguridad es nuestra máxima prioridad.',
      success:
        'Se le ha enviado un correo electrónico de restablecimiento si su cuenta existe.',
    },
    password_reset: {
      title: 'Restablecimiento de contraseña',
      description:
        'Por favor, introduzca su nueva contraseña a continuación. Asegúrese de elegir una contraseña segura. Una vez que haya confirmado su nueva contraseña, podrá acceder a su cuenta de forma segura. Si necesita ayuda, no dude en ponerse en contacto con nosotros. Estamos aquí para ayudarle a proteger su cuenta.',
      success:
        'Su contraseña ha sido cambiada con éxito, será redirigido a la página de inicio de sesión.',
    },
    brand_footer: {
      title: 'Marcas',
      description_1:
        'Wilfind, la primera plataforma de compras en línea que integra una inteligencia artificial (IA) innovadora, reinventa la búsqueda del producto perfecto combinando la experiencia en tecnología y el poder de la IA. Disfrute de una experiencia personalizada, una que le guía hacia las opciones más relevantes según sus necesidades y preferencias.',
      description_2:
        'Haga su búsqueda eficiente y agradable con nuestra avanzada IA que le presenta recomendaciones personalizadas al instante. Sumérjase hoy en esta nueva era de compras en línea. Confíe en Wilfind y encontrará lo que busca.',
    },
    footer: {
      description_1:
        'Wilfind ofrece una variedad de productos de calidad de las marcas más reconocidas del mercado (Apple, Asus, Samsung, Sony...). Estos productos son vendidos por nuestros socios como Amazon, Fnac y Darty, que nos ayudan a ofrecer una experiencia de compra en línea confiable y completa.',
      description_2:
        'Use el comparador de productos para facilitar su proceso de decisión. Compare las especificaciones, precios y valoraciones de los diferentes comerciantes disponibles en nuestra plataforma. Así podrá seleccionar la opción que mejor se adapte a sus expectativas y presupuesto.',
      description_3:
        'Para una experiencia de compra aún más personalizada, aproveche nuestra IA (WilBot) diseñada para analizar sus preferencias y necesidades. Le presenta sugerencias personalizadas para guiarle hacia el producto ideal, ahorrándole tiempo valioso y ofreciéndole una experiencia de compra única.',
      description_4:
        'Wilfind está afiliado a socios de confianza como Amazon, Fnac y Darty, que contribuyen a nuestra rentabilidad. Esto significa que cuando compra un producto de uno de estos socios a través de nuestra plataforma, recibimos una comisión sin que ello afecte el precio que paga.',
      who_are_we: '¿Quiénes somos?',
      about: 'Acerca de Wilfind',
      legal_notice: 'Avisos legales',
      privacy_policy: 'Política de privacidad',
      CGS: 'Términos y condiciones',
      account: 'Cuenta',
      favorites: 'Favoritos',
      cart: 'Carrito',
      my_account: 'Mi cuenta',
      security: 'Seguridad',
      data: 'Datos',
      services: 'Servicios',
      support: 'Soporte y ayuda',
      FAQ: 'Preguntas frecuentes',
      affiliation: 'Afiliación',
      cookies_policy: 'Política de cookies',
      terms_conditions: 'Términos y condiciones',
    },
    bottom_footer: {
      all_rights_reserved: 'Todos los derechos reservados',
      legal_notice: 'Avisos legales',
      privacy_policy: 'Política de privacidad',
    },
    account_menu: {
      informations: 'Información',
      subscription: 'Suscripción',
      security: 'Seguridad',
      history: 'Historial',
      agreements: 'Acuerdos',
      data: 'Datos',
      support: 'Soporte',
    },
    personal_data: {
      title: 'Sus datos',
      success: 'Sus datos han sido actualizados.',
    },
    security: {
      title: 'Su seguridad',
      change_password: 'Cambiar contraseña',
      change_email: 'Cambiar correo electrónico',
    },
    data_usage: {
      title: 'Sus datos',
      delete_account: 'Eliminar mi cuenta',
      delete_account_description:
        'Recibirá un correo electrónico para validar la eliminación.',
    },
    two_factor: {
      title: 'Autenticación de dos factores (2FA)',
      enable: 'Activar 2FA',
      enable_description:
        'Recibirá un correo electrónico para validar la activación.',
      success: '2FA activada.',
      scan_qr_code:
        'Escanee este código QR con su aplicación de autenticación:',
      code: 'Código',
      modal_title: 'Código de doble autenticación (2FA)',
      manually: 'O introduzca este código manualmente:',
      email_confirmation: 'Código de confirmación por correo electrónico',
    },
    legal_notice: {
      title: 'Avisos legales',
      privacy_policy: 'Política de privacidad',
      CGS: 'Términos y condiciones',
    },
    filter_menu: {
      unit: {
        gb: 'Gb',
        inch: '"',
      },
      result: 'Resultados para',
      filters: {
        Marque: 'Marca',
        Catégorie: 'Categoría',
        Couleur: 'Color',
        Stockage: 'Almacenamiento',
        "Taille d'écran": 'Tamaño de pantalla',
      },
      price: {
        title: 'Precio',
        minus_150: 'Menos de 150€',
        from_150_to_300: 'De 150€ a 300€',
        from_300_to_500: 'De 300€ a 500€',
        from_500_to_800: 'De 500€ a 800€',
        from_800_to_1000: 'De 800€ a 1000€',
        more_1000: 'Más de 1000€',
        to: 'a',
      },
      merchants: 'Comerciantes',
    },
    search_filter: {
      by_default: 'Por defecto',
      price_decreasing: 'Precio descendente',
      price_increasing: 'Precio ascendente',
      news: 'Novedades',
    },
    pagination: {
      go_to_page: 'Ir a la página',
      results_on: 'resultados en',
    },
    product_page: {
      similar_products: 'Productos similares',
      description: 'Descripción',
      features: 'Características',
      about: 'Acerca de',
      description_product: 'Descripción del producto',
      features_product: 'Características del producto',
      trust_arguments: {
        secure_payment: 'Pago seguro',
        rgpd: 'Cumple con GDPR',
        secure_redirection: 'Redirección segura',
      },
    },
    reviews: {
      title: 'Opiniones',
    },
    support: {
      title: 'Soporte',
      contact_form_title: 'Formulario de contacto',
      contact_form_description:
        'Complete el formulario a continuación para contactarnos.',
      other_contact_methods_title: 'Otros medios de contacto',
      other_contact_methods_description:
        'También puede contactarnos a través de nuestras redes sociales o por correo electrónico.',
      contact_reason_1: 'Problema de conexión',
      contact_reason_2: 'Problema con Wilbot',
      contact_reason_3: 'Problema con el sitio web',
      contact_reason_4: 'Otro',
      alert:
        'Acepta comunicar su dirección de correo electrónico, apellidos y nombre a efectos de su solicitud',
    },
    support_user: {
      title: 'Tus solicitudes de soporte',
      new_request: 'Nueva solicitud',
      status: {
        pending: 'Pendiente',
        in_progress: 'En progreso',
        closed: 'Resuelto',
      },
      type_of_demand: 'Tipo de solicitud',
      description: 'Descripción',
      request: 'Solicitud',
      write_your_message: 'Escribe tu mensaje...',
    },
    about: {
      title: 'Acerca de Wilfind',
      who_are_we: '¿Quiénes somos?',
    },
    faq: {
      title: 'Preguntas frecuentes',
      what_is_wilfind: '¿Qué es Wilfind?',
      what_is_wilfind_description:
        'Wilfind, la primera plataforma de compras en línea que integra una inteligencia artificial (IA) innovadora, reinventa la búsqueda del producto perfecto combinando la experiencia en tecnología y el poder de la IA. Disfrute de una experiencia personalizada, fluida y agradable, que le guía hacia las opciones más relevantes según sus necesidades y preferencias. Opte por una búsqueda eficiente y placentera con nuestra avanzada IA que le presenta recomendaciones personalizadas al instante. Sumérjase hoy en esta nueva era de compras en línea. Confíe en Wilfind y encontrará lo que busca.',
      what_is_wilbot: '¿Qué es Wilbot?',
      what_is_wilbot_description:
        'Wilbot es nuestro Chatbot con inteligencia artificial (IA) cuyo objetivo es ayudarle a encontrar el producto ideal. Simplemente hágale una pregunta y él continuará la conversación con usted para ayudarle a encontrar el producto perfecto. A medida que avanza la conversación, Wilbot le sugerirá productos que se ajusten a sus necesidades antes de presentarle los productos finales.',
      what_is_wilbot_description_2:
        'Wilbot combina inteligencia artificial (IA) y experiencia tecnológica para encontrar el producto perfecto, y esta tecnología sigue evolucionando con el tiempo.',
      how_to_access: '¿Cómo acceder a Wilbot?',
      how_to_access_description:
        'Para acceder a Wilbot, simplemente regístrese o inicie sesión en Wilfind, luego vaya a la página de Wilbot. Limitamos el uso a usuarios con una cuenta para evitar sobrecargar nuestros servidores y así poder ofrecer un servicio de calidad.',
      limitations: 'Limitaciones',
    },
    affiliation: {
      title: 'Afiliación',
      what_is_affiliation: '¿Qué es la afiliación?',
      what_is_affiliation_description:
        'Es una asociación comercial en la que nos asociamos con otros sitios web que ofrecen productos complementarios o similares a los nuestros.',
      what_is_affiliation_description_2:
        'Cuando compra un producto a través de nuestro sitio web, es posible que recibamos una comisión por esa venta. Sin embargo, tenga la seguridad de que esto no implica ningún costo adicional para usted.',
      what_is_affiliation_description_3:
        'La afiliación es simplemente una forma para nosotros de ofrecerle una mayor variedad de productos de calidad y continuar brindándole un servicio excepcional.',
      how_affiliation_works: '¿Cómo funciona la afiliación?',
      how_affiliation_works_description:
        'Cuando hace clic en un producto afiliado en nuestro sitio web, será redirigido al sitio asociado donde podrá realizar su compra de manera segura.',
      how_affiliation_works_description_2:
        'Los sitios asociados son cuidadosamente seleccionados por su fiabilidad, calidad de servicio y relevancia de sus productos. Cuando realiza una compra, el sitio asociado nos informa de esta transacción y nos otorga una comisión por la venta.',
      how_affiliation_works_description_3:
        'Es gracias a esta comisión que podemos seguir ofreciéndole una experiencia de compra excepcional, ampliando constantemente nuestra gama de productos.',
      how_affiliation_works_description_4:
        'Entendemos que la confianza es fundamental en el proceso de compra en línea. Es por eso que nos comprometemos a colaborar solo con sitios asociados de renombre y confiables, para garantizar que su experiencia de compra siga siendo transparente, segura y agradable.',
      why_affiliation: '¿Por qué la afiliación?',
      why_affiliation_description:
        'En Wilfind, creemos en el poder de la colaboración para ofrecer a nuestros clientes una experiencia de compra excepcional.',
      why_affiliation_description_2:
        'Es por eso que hemos establecido un programa de afiliación que le permite disfrutar de una selección aún más amplia de productos excepcionales de sitios asociados de confianza.',
      why_affiliation_description_3:
        'Le invitamos a explorar nuestro catálogo de productos y descubrir las oportunidades que ofrece nuestro programa de afiliación. Encontrará una variedad de productos de calidad de diferentes sitios asociados, cuidadosamente seleccionados para satisfacer sus necesidades y gustos.',
      why_affiliation_description_4:
        'No dude en contactarnos si tiene alguna pregunta o inquietud, nuestro equipo estará encantado de ayudarle.',
    },
    history: {
      title: 'Historial',
      empty: 'Su historial está vacío.',
      empty_description: 'Aún no ha realizado ninguna búsqueda.',
      product: 'Producto',
      search: 'Búsqueda',
      wilbot: 'Wilbot',
      elements_number: 'Número de elementos',
      i_want_to_delete: 'Quiero eliminar:',
    },
    wilbot: {
      category: {
        title: 'Categoría',
        smartphone: 'Smartphone',
        laptop: 'Ordenador portátil',
        tablet: 'Tableta',
        desktop: 'Ordenador de escritorio',
        'all-in-one': 'Ordenador todo en uno',
      },
      suggested_response: {
        smartphone: 'Smartphone',
        laptop: 'Ordenador portátil',
        tablet: 'Tableta',
        desktop: 'Ordenador de escritorio',
      },
      loading_chat_response: {
        0: 'Buscando los mejores productos ',
        1: 'Buscando los mejores productos .',
        2: 'Buscando los mejores productos ..',
        3: 'Buscando los mejores productos ...',
      },
      loading_product_chat_response: {
        0: 'Buscando información ',
        1: 'Buscando información .',
        2: 'Buscando información ..',
        3: 'Buscando información ...',
      },
      reset_date: 'Este límite se restablecerá el: ',
      subscription_required:
        'Has alcanzado tu <0>límite</0> de preguntas diarias, para aumentar este límite puedes ver las diferentes <1>ofertas aquí.</1>',
      product_subscription_required:
        'Para utilizar la función <0>Pregunta +</0> necesitas ser abonado, puedes ver las diferentes <1>ofertas aquí.</1>',
      default_response:
        '<0>Bienvenido a Wilbot,</0> puedes empezar por darme <1>la categoría</1> de producto que deseas buscar.',
      // default_response: {
      //   smartphone:
      //     '<0>Muy bien,</0> puedes empezar por ejemplo dándome <1>los usos</1> que quieres hacer con tu <2>smartphone.</2>',
      //   tablet:
      //     '<0>Muy bien,</0> puedes empezar por ejemplo dándome <1>los usos</1> que quieres hacer con tu <2>tablet.</2>',
      //   laptop:
      //     '<0>Muy bien,</0> puedes empezar por ejemplo dándome <1>los usos</1> que quieres hacer con tu <2>portátil.</2>',
      //   desktop:
      //     '<0>Muy bien,</0> puedes empezar por ejemplo dándome <1>los usos</1> que quieres hacer con tu <2>ordenador de escritorio.</2>',
      //   'all-in-one':
      //     '<0>Muy bien,</0> puedes empezar por ejemplo dándome <1>los usos</1> que quieres hacer con tu <2>ordenador todo en uno.</2>',
      // },
      on: 'sobre',
      best_product_for_you: 'El mejor producto para ti!',
      chat_with_expert: 'Chatear con un experto',
      new_discussion: 'Nueva conversación',
      product_question:
        'Cuando se selecciona un producto, puede hacer preguntas sobre ese producto, luego el hilo de la conversación volverá a la normalidad.',
      welcome: 'Hola, bienvenido a Wilfind. ¿Qué está buscando?',
      guide: 'Guía',
      no_product_found:
        'Lo siento, no encontré un producto que coincida con sus necesidades, intente modificar sus criterios de búsqueda.',
      no_category_found:
        'Lo siento, no encontré una categoría que coincida con sus necesidades, intente modificar sus criterios de búsqueda.',
      message_too_long: 'El mensaje es demasiado largo.',
      chat_error: 'Ocurrió un error, por favor intente nuevamente.',
      dislike_message_success: 'Gracias por sus comentarios.',
      like_message_success: 'Gracias por sus comentarios.',
      dislike_message_error: 'Ocurrió un error.',
      dislike_placeholder: '¿Por qué esta respuesta no es adecuada para usted?',
      dislike_title: 'Danos tu opinión.',
      like_title: 'Danos tu opinión.',
      like_placeholder: '¿Desea especificar algo?',
      write_your_message: 'Escriba su mensaje...',
      data_secure: 'Sus datos personales están protegidos.',
      suggestions: 'Sugerencias',
      product_find: 'Producto encontrado',
      product_right_title: 'Resultado basado en sus respuestas:',
      product_right_subtitle: 'Encuentre el producto ideal para usted.',
      product_right_not_found: 'Sin resultados',
      redirect_message:
        ', una vez que haga clic en el producto, será redirigido.',
      view_on_wilfind: 'Ver en Wilfind',
      final_product:
        'Le recomiendo este producto, si tiene más preguntas, ¡no dude en preguntar!',
      final_products:
        'Le recomiendo estos productos, si tiene más preguntas, ¡no dude en preguntar!',
      warning_message:
        'Wilbot está en desarrollo, puede mostrar información inexacta o falsa.',
      missing_filters: {
        title:
          'Desafortunadamente, no encontré un producto que coincida exactamente con su búsqueda, pero encontré estos productos:',
        smaller_screen: 'Pantalla más pequeña',
        bigger_screen: 'Pantalla más grande',
        smaller_storage: 'Menos almacenamiento',
        bigger_storage: 'Más almacenamiento',
        smaller_price: 'Más barato',
        bigger_price: 'Más caro',
        different_color: 'Color diferente',
        foldable_screen: 'Pantalla plegable',
        not_foldable_screen: 'Pantalla no plegable',
      },
      not_same_filter: {
        screen_size_plus: 'Pantalla más grande',
        screen_size_minus: 'Pantalla más pequeña',
        screen_size: 'No es el mismo tamaño de pantalla',
        storage_plus: 'Más almacenamiento',
        storage_minus: 'Menos almacenamiento',
        storage: 'No es el mismo almacenamiento',
        price_plus: 'Más caro',
        price_minus: 'Más barato',
        price: 'No es el mismo precio',
        color: 'No es el mismo color',
        brand: 'No es la misma marca',
        extra_filter: 'Faltan ciertas informaciones adicionales',
      },
      exemples: {
        0: '<0>Estoy buscando un nuevo smartphone</0> para jugar.',
        1: '<0>Quiero comprar un teléfono</0> para tomar fotos y hacer videos.',
        2: '<0>Teléfono</0> con el que pueda hacer música.',
        3: '<0>Encuéntrame un smartphone</0> con un presupuesto de 500€.',
      },
      history: {
        history: 'Historial',
        title: 'Historial de conversaciones:',
        description: 'Encuentra tus conversaciones antiguas.',
        filters: {
          dateAsc: 'Más recientes',
          dateDesc: 'Más antiguas',
        },
        chats: 'Chats',
        liked_chats: 'Chats favoritos',
        empty: 'Tu historial está vacío.',
      },
    },
    manage_cookies: {
      title: 'Gestión de cookies',
    },
    cookies: {
      continue_without_accepting: 'Continuar sin aceptar',
      policy: 'Política de cookies',
      description:
        '<0>Wilfind</0> utiliza cookies en este sitio con el fin de garantizar el buen funcionamiento del sitio, la seguridad, la medición de audiencia, el análisis, la mejora de su experiencia de usuario y con fines publicitarios.<1></1><2></2>Su consentimiento para la instalación de cookies no estrictamente necesarias es voluntario y puede modificarse en cualquier momento.<3></3><3></3>Puede dar o retirar su consentimiento globalmente o configurar sus preferencias por finalidad de cookies.',
      manage_description:
        'Puede dar o retirar su consentimiento ya sea de forma global o por finalidad de cookies.<0></0>Debe aceptar o rechazar las cookies de cada categoría y luego guardar sus elecciones detalladas.<1></1><2></2>Si su navegador está configurado para rechazar todas las cookies, no podrá disfrutar de funciones esenciales de nuestro sitio, como por ejemplo, guardar sus preferencias de inicio de sesión para sesiones que requieren identificación.',
      essential:
        '<0>Esencial:</0> Cookies necesarias para la personalización y el funcionamiento del sitio web: le permiten utilizar las principales funciones del sitio, como guardar información entre dos visitas al sitio en el mismo dispositivo, guardar credenciales de inicio de sesión de la sesión o elementos de personalización de la interfaz (elección de idioma o presentación). No requieren su consentimiento previo. Estas cookies son indispensables para el buen funcionamiento del sitio. Si se niega a almacenar estas cookies en su dispositivo o navegador, o si elimina las que ya están almacenadas, se le informará que su navegación y experiencia en el sitio pueden verse limitadas.',
      analytics:
        '<0>Analíticas:</0> Cookies analíticas, de estadísticas o de medición de audiencia del sitio web que nos permiten conocer el uso y el rendimiento de audiencia del sitio y mejorar su funcionamiento para nuestros visitantes, por ejemplo, estableciendo estadísticas y volúmenes de asistencia y uso de diversos elementos que componen el sitio (secciones y contenido visitado, recorridos), con el fin de mejorar el interés y la ergonomía del sitio.',
      ads: '<0>Publicitarias:</0> Cookies publicitarias que nos permiten elegir en tiempo real qué publicidad mostrar en sitios de terceros. Estas cookies solo se depositan cuando usted ha dado su consentimiento expreso a través del banner presentado durante su primera conexión.',
      consent:
        'Al marcar la casilla a continuación, usted consiente la recopilación, el procesamiento y el almacenamiento de sus datos personales de acuerdo con nuestra política de privacidad.',
      privacy_policy: 'Política de privacidad',
      manage: 'Preferencias',
      accept: 'Aceptar todo',
      decline: 'Rechazar todo',
      save: 'Guardar',
      consult_policy: 'Consulte la política de cookies y protección de datos',
    },
    error: {
      subscription_required: 'No tiene la suscripción requerida.',
      missing_fields: 'Por favor, complete todos los campos.',
      google_user: 'No es posible porque inició sesión con Google.',
      missing_message: 'Por favor, escriba un mensaje.',
      wilbot_limit_message: 'Ha alcanzado el límite de mensajes diarios.',
      missing_category: 'Por favor, elija una categoría.',
      fill_all_fields: 'Por favor, complete todos los campos.',
      password_not_secure: 'Su contraseña no es lo suficientemente segura.',
      password_not_valid: 'Su contraseña es inválida.',
      name_length: 'Su nombre debe tener entre 2 y 20 caracteres.',
      firstname_length: 'Su primer nombre debe tener entre 2 y 20 caracteres.',
      invalid_code: 'Código inválido.',
      product_not_found: 'Producto no encontrado.',
      captcha_error: 'Captcha inválido.',
      deleted_account: 'Su cuenta ha sido eliminada.',
      invalid_credentials: 'Correo electrónico / contraseña incorrectos.',
      too_many_attempts: 'Demasiados intentos, intente de nuevo en una hora.',
      email_not_valid: 'Su correo electrónico es inválido.',
      email_already_used: 'Su correo electrónico ya está en uso.',
      code_invalid_or_expired: 'Código inválido o expirado',
      link_invalid_or_expired: 'Enlace inválido o expirado.',
      email_not_sent: 'Correo electrónico no enviado.',
      chat_not_found: 'Conversación no encontrada.',
      chat_error: 'Ocurrió un error en la conversación.',
      default_error: 'Ocurrió un error.',
      global: {
        server_error: 'Ocurrió un error.',
        email_error: 'Problema al enviar el correo electrónico.',
        link_invalid_or_expired: 'Enlace inválido o expirado.',
        captcha_error: 'Captcha inválido.',
      },
    },
    loading: {
      loading_0: 'Cargando',
      loading_1: 'Cargando.',
      loading_2: 'Cargando..',
      loading_3: 'Cargando...',
    },
    delete_account: {
      success: 'Su cuenta ha sido eliminada correctamente.',
    },
    change_password: {
      success: '¡Su contraseña ha sido cambiada correctamente!',
      description: 'Ahora puede iniciar sesión con su nueva contraseña.',
    },
    change_email: {
      success: 'Su correo electrónico ha sido modificado correctamente.',
      description:
        'Ahora puede iniciar sesión con su nueva dirección de correo electrónico.',
    },
    verify_account: {
      success: 'Su cuenta ha sido verificada correctamente.',
      description:
        'Ahora puede iniciar sesión y disfrutar plenamente de nuestros servicios.',
    },
    continue_pop_up: {
      title: 'Iniciar sesión o registrarse en unos segundos',
      description:
        '¡Use su correo electrónico u otro servicio para usar nuestra IA de forma gratuita!',
      thanks_register: '¡Gracias por registrarse!',
      verify_email: 'Verifique su dirección de correo electrónico',
      verification_code:
        'Se le ha enviado un código de verificación por correo electrónico.',
      register_description:
        'Regístrese en unos segundos para tener acceso a Wilfind',
    },
    beta: {
      title: 'Acceso anticipado',
      description:
        'Únase a la aventura <0>Wilfind</0> y acceda a la versión <1>beta de Wilbot.</1>',
      submit: 'Solicitar acceso',
      alert:
        'Al proporcionar su dirección de correo electrónico, se le informará sobre el lanzamiento de Wilbot y podrá acceder a la versión beta.',
      presentation: {
        0: 'Descubra <0>Wilbot</0>,',
        1: 'encuentre su producto gracias a la IA.✨',
        2: 'encuentre el producto perfecto para usted.',
        3: 'encuentre el producto perfecto de manera sencilla.',
        4: 'encuentre el producto perfecto rápidamente.',
      },
      success:
        '¡Gracias por su solicitud! Será informado por correo electrónico.',
    },
    subscriptionSuccess: {
      title: '¡Gracias por su suscripción!',
      description:
        'Su suscripción ha sido procesada correctamente, puede disfrutar de todas las funciones de Wilfind.',
    },
    meta: {
      title: {
        home: 'Wilfind - La primera tienda de inteligencia artificial',
        wilbot: 'Wilbot - Asistente de Compras de IA',
        comparator: 'Wilfind - Comparador',
        login: 'Wilfind - Iniciar sesión',
        register: 'Wilfind - Registrarse',
        favorites: 'Wilfind - Favoritos',
        cart: 'Wilfind - Carrito',
        history: 'Wilfind - Historial',
        terms_conditions: 'Wilfind - Términos y Condiciones',
        privacy_policy: 'Wilfind - Política de Privacidad',
        cookies_policy: 'Wilfind - Política de Cookies',
        affiliate: 'Wilfind - Afiliado',
        about_us: 'Wilfind - Sobre nosotros',
        help: 'Wilfind - Ayuda',
        faq: 'Wilfind - Preguntas Frecuentes',
        manage_cookies: 'Wilfind - Gestionar Cookies',
        subscriptions: 'Wilfind - Suscripciones',
        reset_password: 'Wilfind - Restablecer Contraseña',
        change_password: 'Wilfind - Cambiar Contraseña',
        change_email: 'Wilfind - Cambiar Correo Electrónico',
        verify_account: 'Wilfind - Verificar Su Cuenta',
        delete_account: 'Wilfind - Eliminar Su Cuenta',
        personal_data: 'Wilfind - Sus Informaciones',
        security: 'Wilfind - Su Seguridad',
        data_usage: 'Wilfind - Sus Datos',
        two_factor: 'Wilfind - Autenticación de Dos Factores',
        support: 'Wilfind - Soporte',
        manage_subscription: 'Wilfind - Gestionar Su Suscripción',
      },
    },
  },
};

export default es;
