export default function GTranslate() {
    return (
        <svg className="g-translate" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_115_2061)">
                <path d="M15 3.75H8.16L7.5 1.5H3C2.175 1.5 1.5 2.175 1.5 3V12.75C1.5 13.575 2.175 14.25 3 14.25H8.25L9 16.5H15C15.825 16.5 16.5 15.825 16.5 15V5.25C16.5 4.425 15.825 3.75 15 3.75ZM5.3775 10.9425C3.69 10.9425 2.31 9.57 2.31 7.875C2.31 6.18 3.6825 4.8075 5.3775 4.8075C6.1575 4.8075 6.87 5.085 7.4325 5.61L7.485 5.655L6.5625 6.54L6.5175 6.5025C6.3 6.3 5.9325 6.06 5.3775 6.06C4.395 6.06 3.5925 6.8775 3.5925 7.875C3.5925 8.8725 4.395 9.69 5.3775 9.69C6.405 9.69 6.8475 9.0375 6.9675 8.595H5.31V7.4325H8.2725L8.28 7.485C8.31 7.6425 8.3175 7.785 8.3175 7.9425C8.3175 9.705 7.11 10.9425 5.3775 10.9425ZM9.9 9.66C10.1475 10.11 10.455 10.545 10.7925 10.935L10.3875 11.3325L9.9 9.66ZM10.4775 9.09H9.735L9.5025 8.31H12.495C12.495 8.31 12.24 9.2925 11.325 10.365C10.935 9.9 10.6575 9.4425 10.4775 9.09ZM15.75 15C15.75 15.4125 15.4125 15.75 15 15.75H9.75L11.25 14.25L10.6425 12.1725L11.3325 11.4825L13.3425 13.5L13.89 12.9525L11.8575 10.9425C12.5325 10.17 13.0575 9.255 13.2975 8.31H14.25V7.53H11.52V6.75H10.74V7.53H9.27L8.385 4.5H15C15.4125 4.5 15.75 4.8375 15.75 5.25V15Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_115_2061">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}