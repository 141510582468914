import { createSlice } from '@reduxjs/toolkit';

const productSlice = createSlice({
  name: 'products',
  initialState: [],
  reducers: {
    addProducts(state, action) {
      localStorage.setItem('products', JSON.stringify(action.payload));
      return (state = action.payload);
    },
    filterProducts(state, action) {
      // sort state by price asc or desc
      if (action.payload === 'priceAsc') {
        return state.sort(
          (a, b) =>
            (a.fnac_search_price ? a.fnac_search_price : a.darty_search_price) -
            (b.fnac_search_price ? b.fnac_search_price : b.darty_search_price)
        );
      } else if (action.payload === 'priceDesc') {
        return state.sort(
          (a, b) =>
            (b.fnac_search_price ? b.fnac_search_price : b.dart_search_price) -
            (a.fnac_search_price ? a.fnac_search_price : a.dart_search_price)
        );
      } else if (action.payload === 'revelance') {
        return (state = JSON.parse(localStorage.getItem('products')));
      } else if (action.payload === 'news') {
        return state.sort(
          (a, b) => new Date(b['add-date']) - new Date(a['add-date'])
        );
      }
    },
    filterPrice(state, action) {
      // filter state by price range
      const filteredProducts = JSON.parse(localStorage.getItem('products'));
      const filtered = filteredProducts.filter((product) => {
        return (
          (product.fnac_search_price
            ? product.fnac_search_price
            : product.dart_search_price) >= action.payload[0] &&
          (product.fnac_search_price
            ? product.fnac_search_price
            : product.dart_search_price) <= action.payload[1]
        );
      });
      return (state = filtered);
    },
    filterMerchant(state, action) {
      // filter state by merchant
      const filteredProducts = JSON.parse(localStorage.getItem('products'));
      let merchants = action.payload;
      let filtered = [];
      if (merchants.length === 0) {
        return (state = filteredProducts);
      }
      merchants.forEach((merchant) => {
        if (merchant === 'merchant_fnac') {
          filteredProducts.forEach((product) => {
            if (product.fnac_aw_deep_link) {
              filtered.push(product);
            }
          });
        } else if (merchant === 'merchant_darty') {
          filteredProducts.forEach((product) => {
            if (product.darty_aw_deep_link) {
              filtered.push(product);
            }
          });
        } else if (merchant === 'merchant_amazon') {
          filteredProducts.forEach((product) => {
            if (product['Lien Amazon']) {
              filtered.push(product);
            }
          });
        }
      });
      return (state = filtered);
      // const filtered = filteredProducts.filter((product) => {
      //   return action.payload.includes(product.merchant);
      // });
      // return (state = filtered);
    },
    searchFilter(state, action) {
      // filter state with an objects of filters
      const filters = action.payload;
      const filteredProducts = JSON.parse(localStorage.getItem('products'));
      const filtered = filteredProducts.filter((product) => {
        return Object.keys(filters).every((key) => {
          if (!filters[key].length) {
            return true;
          }
          if (key === 'Stockage' || key === "Taille d'écran") {
            return filters[key].some(
              (number) => product.Spec[key] === parseFloat(number)
            );
          }
          if (key === 'Marque' || key === 'Catégorie')
            return filters[key].includes(product[key]);
          else return filters[key].includes(product.Spec[key]);
        });
      });
      return (state = filtered);
    },
  },
});

export const readProducts = (state) => state.products;

export const {
  addProducts,
  filterProducts,
  filterPrice,
  searchFilter,
  filterMerchant,
} = productSlice.actions;

export default productSlice.reducer;
