const en = {
  translation: {
    header: {
      top: {
        news_1: 'New Wilbot AI',
        news_2: 'iPhone 15',
        help: 'Help',
        faq: 'FAQ',
        affiliation: 'Affiliation',
        subscriptions: 'Subscriptions',
      },
      pages: {
        home: 'Home',
        comparator: 'Comparator',
        ai_availability: 'Available AI',
        'product-results': 'Search',
        'product-page': 'Product',
        favorites: 'Favorites',
        cart: 'Cart',
        history: 'History',
        'legal-notice': 'Legal',
        affiliate: 'Affiliation',
        'about-us': 'About Us',
        help: 'Help',
        faq: 'FAQ',
        subscriptions: 'Subscriptions',
        'subscriptions-success': 'Subscriptions',
        'reset-password': 'Password',
        'password-reset': 'Password',
        'change-password': 'Password',
        'change-email': 'Email',
        'verify-account': 'Account',
        'delete-account': 'Account',
        account: 'Account',
        'subscription-success': 'Success',
        'terms-conditions': 'Terms',
        'cookies-policy': 'Cookies',
        'manage-cookies': 'Cookies',
        'privacy-policy': 'Privacy',
      },
    },
    global: {
      products: 'products',
      see_the_products: 'See the products',
      search_plus: 'Search +',
      page_not_found: 'Page not found',
      manage_cookie: 'Manage cookie',
      coming_soon: 'Coming soon...',
      discover: 'Discover',
      currency: '$',
      close: 'Close',
      home: 'Home',
      or: 'Or',
      view: 'View',
      view_more: 'View more',
      view_less: 'View less',
      register: 'Sign up',
      login: 'Log in',
      login_short: 'Login',
      logout: 'Log out',
      my_account: 'My account',
      quit: 'Exit',
      remove: 'Remove',
      add_to_cart: 'Add to cart',
      remove_from_cart: 'Remove from cart',
      add_to_favorite: 'Add to favorites',
      remove_from_favorite: 'Remove from favorites',
      add_to_comparator: 'Add to comparator',
      remove_from_comparator: 'Remove from comparator',
      buy_on_the_site: 'Buy on the site',
      available: 'Available',
      unavailable: 'Unavailable',
      loading: 'Loading...',
      buy: 'Buy',
      confirm: 'Confirm',
      send: 'Send',
      redirect_protected: 'Your redirection is protected',
      alert_buy:
        "The site does not allow purchasing products. Once the product is purchased, you will be redirected to the seller's site.",
      all: 'All',
      products: 'Products',
      total: 'Total',
      off: 'Off',
      modify: 'Edit',
      my_profile: 'My profile',
      information: 'Information',
      security: 'Security',
      reviews: 'reviews',
      save: 'Save',
      delete: 'Delete',
      data_use: 'Data usage',
      confidential: 'Privacy agreement',
      history: 'History',
      authorize_redirection: 'I authorize the redirection',
      forgot_password: 'Forgot password?',
      fill_all_fields: 'You must fill in all fields.',
      default_error: 'An error occurred.',
      email_confirmation: 'You will receive a confirmation email.',
      fill_captcha: 'Please fill in the captcha.',
      email_sent: 'Email sent.',
      password_changed: 'Your password has been successfully changed.',
      link_invalid_or_expired: 'Invalid or expired link.',
      thanks_for_using:
        'Thank you for using our services, we hope to see you again.',
      no_results: 'No results for: ',
      search: 'Search',
      result_search: 'Result for:',
      filter: 'Filter',
      validate: 'Validate',
      cancel: 'Cancel',
      next: 'Next',
      precedent: 'Previous',
      return: 'Back',
      redirect_in_3_seconds: 'Redirecting in 3 seconds...',
      category: {
        title: 'Categories',
        Smartphone: 'Smartphone',
        Tablette: 'Tablet',
        'Ordinateur Portable': 'Laptop',
        'Unité Centrale': 'Central unit',
        'Ordinateur Tout en Un': 'All-in-one computer',
      },
    },
    subscriptions: {
      title: 'Wilfind Subscriptions',
      description: 'Our different offers.',
      use: 'Use',
      features: {
        title: 'Choose the offer that suits you best.',
        compare_offer: 'Compare our different offers.',
        recommended: 'Recommended',
        number_of_research_by_day: 'Number of searches per day',
        access_to_early_access: 'Early Access',
        priority_access: 'Priority access',
        question: 'Question +',
        priority_customer_service: 'Priority customer service',
        expert: 'Expert +',
        on_mesure_offer: 'Custom offer',
      },
      subscription: {
        contact_us: 'Contact us',
        contact: 'Contact',
        try_for_free: 'Try for free',
        get_started: 'Get started',
        billed_monthly: 'Billed monthly',
        resiliation: 'Cancellation at any time',
        description_0: 'For individuals wishing to try Wilbot for free.',
        description_1: 'The Plus plan is perfect for using Wilbot freely.',
        description_2: 'This plan allows you to use Wilbot and other features.',
        description_3:
          'The Ultra plan is the most comprehensive, enjoy Wilfind without limits.',
        description_4:
          'For businesses wishing to use Wilbot for their company.',
        features: {
          '1 product / day': '1 product / day',
          '5 product / day': '5 products / day',
          '10 product / day': '10 products / day',
          '30 product / day': '30 products / day',
          '∞ product / day': '∞ products / day',
          'Early access': 'Early access',
          'Priority access': 'Priority access',
          'Question +': 'Question +',
          'Priority customer service': 'Priority customer service',
          'Expert +': 'Expert +',
          'On mesure offer': 'Custom offer',
        },
      },
    },
    manage_subscriptions: {
      title: 'Manage my subscription',
      manage_my_subscription: 'Manage my subscription',
      see_different_plans: 'See different plans',
      redirect_stripe: 'You will be redirected to Stripe.com',
      actual_subscription:
        'You currently have the <0>{0}</0> subscription until <1></1>.',
    },
    home: {
      title: {
        1: 'Welcome, how can I assist you today ?',
        2: 'Welcome to Wilfind.com, what product are you looking for ?',
        3: 'Welcome to Wilfind.com, what interests you ?',
        4: 'Welcome to Wilfind.com, what are you searching for ?',
        5: 'Searching is good, finding is better.',
        6: 'Are you looking for a smartphone ?',
        7: 'Are you looking for a computer ?',
        8: 'Are you looking for a tablet ?',
        9: "Hello, I'm Wilbot, your search assistant.",
        10: 'Find what you need on Wilfind.com.',
        11: "Hello, I'm your search assistant, Wilbot.",
        12: 'What are you looking for today ?',
      },
      discover: 'Discover the futur of e-commerce with ',
      description: 'Find the ideal product for you.',
      try_wilbot: 'Try Wilbot',
      try_wilbot_details: 'Try Wilbot for free (one / days)',
      support: 'Need help, contact us.',
      support_link: 'Support',
      account: 'Already have an account?',
      account_link: 'Log in',
      new_macbook_pro: 'New Macbook<0></0><1>PRO</1><2>Buy ></2>',
      buy_now: 'buy now',
      text_1: '<0>Wilbot</0> searches among <1>thousands of products.</1>',
      text_2: 'Find the <0>ideal product</0> for you <1>easily.</1>',
      text_3: 'Ad KickStart HERE?',
      partners: 'Our partners',
    },
    search: {
      placeholder: 'Search for a product',
    },
    menu_pop_up: {
      description:
        "With Wilbot, you'll find the product that suits you best. Try the Wilbot experience now!",
      try_wilbot: 'Try Wilbot',
    },
    cart_pop_up: {
      title: 'Cart',
      empty: 'Your cart is empty.',
      empty_description: 'You haven’t added any items yet.',
      view_cart: 'View cart',
    },
    redirection_pop_up: {
      title: 'Allow pop-ups',
      description:
        'To be redirected to the merchant pages, you must allow pop-up windows in your web browser.',
    },
    comparator: {
      title: 'Comparator',
      add: 'Add',
      description: 'Compare products to make the right choice.',
      add_product: 'Add a product',
      by_default: 'By default',
      performance: 'Performance',
      screen: 'Screen Quality',
      photo: 'Photo Quality',
      video: 'Video Quality',
      gaming: 'Gaming',
      work: 'Work',
      school: 'School',
      transport: 'Transport',
      asc_price: 'Ascending price',
      desc_price: 'Descending price',
      error_min: 'You must add at least 2 products.',
      error_max: 'You can add up to 3 products.',
      placeholder: 'Search for a product to add to the comparator',
      empty: 'Your comparator is empty.',
      suggestions: 'Suggestions',
    },
    price_chart: {
      title: 'Price trend',
      week: '1 week',
      month: '1 month',
      six_months: '6 months',
      year: 'One year',
      all: 'All',
    },
    cart: {
      title: 'Your cart',
      description: 'Find your items added to the cart.',
      only_fnac: 'Fnac only',
      only_darty: 'Darty only',
      empty: 'Your cart is empty.',
      empty_description: 'You haven’t added any items yet.',
      error_redirect: 'You must accept the redirection to access the links.',
      redirect_prevention: 'I have checked the selected merchants.',
    },
    favorites: {
      title: 'Your favorites',
      description: 'Find your favorite products.',
      empty: 'You don’t have any favorites yet.',
      empty_description:
        'You haven’t added any products to your favorites yet.',
    },
    product: {
      reparability_title: 'REPARABILITY INDEX',
      merchant_selection: 'Merchant selection:',
      unit: 'Unit',
      compare: 'Compare',
      not_compare: 'Do not compare',
      see_the_product: 'See the product',
      not_found: 'Product not found',
      available: 'Available',
      unavailable: 'Unavailable',
      tags: {
        cheapest: 'Cheapest',
        mostEfficient: 'Most efficient',
        bestReviews: 'Best rated',
        bestSeller: 'Best seller',
        new: 'New',
        best_price: 'Best price',
      },
      photo: {
        title: 'Photo',
        captor: 'Image sensor',
        with_pixel_size: 'with pixels of a size of',
        photo_lens: {
          'Grand Angle': 'Wide Angle',
          'Ultra Grand Angle': 'Ultra Wide Angle',
          Périscope: 'Periscope',
          'Télé-objectif': 'Telephoto',
          Depth: 'Depth',
          Macro: 'Macro',
          Selfie: 'Selfie',
          'Selfie 2': 'Selfie 2',
        },
      },
      features_title: {
        Global: 'General',
        Performance: 'Performance',
        Stockage: 'Storage',
        Écran: 'Screen',
        Photo: 'Photo',
        Vidéo: 'Video',
        Autre: 'Other',
      },
      features: {
        true: 'Yes',
        false: 'No',
        performance: 'Performance',
        photo: 'Photo',
        video: 'Video',
        photo_video: 'Photo & Video',
        screen: 'Screen',
        total: 'Total',
        Os: 'Operating System',
        "Taille d'écran": 'Screen size',
        "Dalle d'écran": 'Screen panel',
        "Densité de pixels sur l'écran": 'Pixel density on the screen',
        "Définition de l'écran": 'Screen resolution',
        "Taux de rafraîchissement de l'écran": 'Screen refresh rate',
        "Taux de rafraîchissement de l'écran adaptatif":
          'Adaptive screen refresh rate',
        "Luminosité maximal de l'écran": 'Maximum screen brightness',
        Processeur: 'Processor',
        'Processeur graphique': 'Graphics processor',
        RAM: 'RAM',
        'Définition vidéo': 'Video resolution',
        'Fréquence maximal vidéo': 'Maximum video frame rate',
        'HDR écran': 'HDR screen',
        'HDR caméra': 'HDR camera',
        'Dolby Vision caméra': 'Dolby Vision camera',
        'ProRES caméra': 'ProRES camera',
        "Temps de réponse de l'écran": 'Screen response time',
        'Always On Display': 'Always On Display',
        Couleur: 'Color',
        'RAW Caméra': 'RAW Camera',
        Stockage: 'Storage',
        Masse: 'Weight',
        Dimensions: 'Dimensions',
        'Indice DAS Tronc': 'SAR Trunk',
        'Indice DAS Tête': 'SAR Head',
        'Indice DAS membre': 'SAR Limb',
        'Note de réparabilité': 'Repairability score',
        'Autres informations': 'Other information',
        Sécurité: 'Security',
        Capteurs: 'Sensors',
        Connectivité: 'Connectivity',
        Connectiques: 'Connectors',
        'Définition photo': 'Photo resolution',
        'Regroupement des pixels': 'Pixel grouping',
        'Taille des pixels': 'Pixel size',
        'Taille focal': 'Focal length',
        'Ouverture du diaphragme': 'Aperture',
        Stabilisation: 'Stabilization',
        'Écran tactile': 'Touchscreen',
        'Carte graphique': 'Graphics card',
        'Type de stockage': 'Storage type',
        Webcam: 'Webcam',
        'Zoom Maximal': 'Maximum zoom',
        'True Tone': 'True Tone',
      },
      colors: {
        Graphite: 'Graphite',
        Bleu: 'Blue',
        Noir: 'Black',
        Crème: 'Cream',
        Argent: 'Silver',
        'Gris sidéral': 'Sidereal Gray',
        Rouge: 'Red',
        'Noir sidéral': 'Sidereal Black',
        'Lumière Stellaire': 'Stellar Light',
        'Violet intense': 'Intense Violet',
        Or: 'Gold',
        Gris: 'Gray',
        Lavande: 'Lavender',
        Ivoire: 'Ivory',
        'Lumière stellaire': 'Stellar Light',
        Mauve: 'Mauve',
        Minuit: 'Midnight',
        Violet: 'Violet',
        Vert: 'Green',
        'Noir Volcanique': 'Volcanic Black',
        'Vert Citron': 'Lime Green',
        'Vert Sauge': 'Sage Green',
        'Blanc Tempête': 'Storm White',
        Menthe: 'Mint',
        'Blanc Neige': 'Snow White',
        Neige: 'Snow',
        'Noir Fantôme': 'Phantom Black',
        'Rouge Coucher de Soleil': 'Sunset Red',
        'Blanc Clair de Lune': 'Moonlight White',
        Beige: 'Beige',
        Blanc: 'White',
        'Noir Minuit': 'Midnight Black',
        'Gris Sidéral': 'Sidereal Gray',
        'Noir Glacé': 'Iced Black',
        'Or rose': 'Rose Gold',
        'Blanc Givré': 'Frosted White',
        'Arc-en-Ciel': 'Rainbow',
        'Bleu étoilé': 'Starry Blue',
        'Vert Glacé': 'Iced Green',
        'Gris ardoise': 'Slate Gray',
        'Bleu nuit': 'Navy Blue',
        'Argent naturel': 'Natural Silver',
        'Bleu espace': 'Space Blue',
        Platine: 'Platinum',
        'Blanc lunaire': 'Moon White',
        Anthracite: 'Anthracite',
        'Bleu Glacier': 'Glacier Blue',
        'Gris fer': 'Iron Gray',
        Sable: 'Sand',
        'Vert Sauge ': 'Sage Green',
        'Argent platine': 'Platinum Silver',
        'Gris arctique': 'Arctic Gray',
        'Gris graphite': 'Graphite Gray',
        'Gris onyx': 'Onyx Gray',
        Startblue: 'Startblue',
        'Gris platine': 'Platinum Gray',
        'Gris lunaire': 'Lunar Gray',
        'Gris acier': 'Steel Gray',
        Jaune: 'Yellow',
        'Noir et argent': 'Black and Silver',
        'Blanc et noir': 'White and Black',
        'Noir avec insert Or': 'Black with Gold Insert',
        'Gris et blanc': 'Gray and White',
        'Argent métallique': 'Metallic Silver',
        Rose: 'Pink',
        Orange: 'Orange',
        'Noir étoile': 'Star Black',
        'Noir et bleu': 'Black and Blue',
        'Gris anthracite': 'Anthracite Gray',
        'Bleu caméléon': 'Chameleon Blue',
        'Gris minéral': 'Mineral Gray',
        'Noir corbeau': 'Raven Black',
        'Métal argenté mica': 'Mica Silver Metal',
        'Bronze Mystique': 'Mystic Bronze',
        'Gris sidéra': 'Sidereal Gray',
        'Natural Titanium': 'Natural Titanium',
        Pêche: 'Peach',
        Argenté: 'Silver',
        Porcelaine: 'Porcelain',
      },
    },
    most_viewed: {
      title: 'Most Viewed',
    },
    profile_pop_up: {
      title: 'Hello, welcome to Wilfind',
      description:
        'At Wilfind, we are committed to providing an unparalleled online shopping experience for high-tech products. Our platform offers a wide selection of quality items from the most reputable brands on the market.',
      support_contact: 'For any login or registration issues, contact support.',
      support_link: 'Support',
    },
    login: {
      title: 'Login',
      description:
        'Log in to access your personal account and fully enjoy the Wilbot experience.',
      no_account: "Don't have an account yet?",
      register: 'Sign up',
      two_factor_title: 'Two-factor Authentication',
      two_factor_description:
        'Please enter your two-factor authentication code to log in.',
      with_google: 'Continue with Google',
      success: 'Successful login, you will be redirected...',
      alert:
        'By signing in, you acknowledge our <0>Terms & Conditions</0> and our <1>Privacy Policy.</1>',
    },
    register: {
      title: 'Registration',
      description:
        "Sign up to access Wilbot for free as well as all of Wilfind's features.",
      already_account: 'Already have an account?',
      login: 'Log in',
      with_google: 'Continue with Google',
      validation_title: 'Validate your account by email',
      validation_description:
        'We sent you an email with a validation code. Please enter it below.',
      register_success: 'Successful registration, you will be redirected...',
      continue_register: 'Continue registration',
      alert:
        'By registering, you acknowledge our <0>Terms & Conditions</0> and our <1>Privacy Policy.</1>',
    },
    input: {
      name: 'Name',
      name_placeholder: 'Enter your name',
      firstname: 'First Name',
      firstname_placeholder: 'Enter your first name',
      email: 'Email',
      email_placeholder: 'Enter your email',
      actual_password: 'Current Password',
      actual_password_placeholder: 'Enter your current password',
      password: 'Password',
      password_placeholder: 'Enter your password',
      verify_password: 'Verify Password',
      verify_password_placeholder: 'Confirm your password',
      two_factor: 'Two-factor Authentication Code',
      two_factor_placeholder: 'Enter your authentication code',
      verification_code: 'Verification Code',
      verification_code_placeholder: 'Enter your verification code',
      new_password: 'New Password',
      new_password_placeholder: 'Enter your new password',
      new_email: 'New Email',
      new_email_placeholder: 'Enter your new email',
      type_of_problem: 'Type of Problem',
      your_problem: 'Your Problem',
      your_problem_placeholder: 'Enter your problem',
      search_placeholder: 'Search',
    },
    pass_checklist: {
      min_length: 'At least 8 characters',
      special_char: 'At least one special character',
      number: 'At least one number',
      capital: 'At least one uppercase letter',
      match: 'Passwords must match',
    },
    reset_password: {
      title: 'Password Reset',
      description:
        'Please enter the email address associated with your account to reset your password.<0 /><1/> A reset link will be sent to you by email.<2 /><3/> Make sure to check your inbox and your spam folder.<4/><5/> Thank you for your trust, and we are here to help you throughout the process. Your security is our top priority.',
      success: 'A reset email has been sent to you if your account exists.',
    },
    password_reset: {
      title: 'Password Reset',
      description:
        "Please enter your new password below. Make sure to choose a secure password. Once you have confirmed your new password, you can securely access your account. If you need help, don't hesitate to contact us. We are here to help you protect your account.",
      success:
        'Your password has been successfully changed, you will be redirected to the login page.',
    },
    brand_footer: {
      title: 'Brands',
      description_1:
        'Wilfind, the first online shopping platform integrating innovative artificial intelligence (AI), reinvents the search for the perfect product by combining technology expertise and the power of AI. Enjoy a personalized experience, an experience that guides you to the most relevant choices based on your needs and preferences.',
      description_2:
        "Make your search efficient and enjoyable with our advanced AI that presents you with personalized recommendations in an instant. Dive into this new era of online shopping today. Trust Wilfind, and you'll find what you're looking for.",
    },
    footer: {
      description_1:
        'Wilfind offers a varied selection of quality products from the most reputable brands on the market (Apple, Asus, Samsung, Sony...). These products are sold by our partners such as Amazon, Fnac, and Darty, helping us provide a reliable and comprehensive online shopping experience.',
      description_2:
        'Use the product comparator to facilitate your decision-making process. Compare specifications, rates, and reviews from different merchants available on our platform. This way, you can select the option that best meets your expectations and budget.',
      description_3:
        'For an even more personalized shopping experience, take advantage of our AI (WilBot) designed to analyze your preferences and needs. It presents you with tailor-made suggestions to guide you to the ideal product, saving you precious time and offering a unique shopping experience.',
      description_4:
        'Wilfind is affiliated with trusted partners such as Amazon, Fnac, and Darty, contributing to our profitability. This means that when you buy a product from one of these partners via our platform, we receive a commission without affecting the price you pay.',
      who_are_we: 'Who are we?',
      about: 'About Wilfind',
      legal_notice: 'Legal Notice',
      privacy_policy: 'Privacy Policy',
      CGS: 'Terms and Conditions',
      account: 'Account',
      favorites: 'Favorites',
      cart: 'Cart',
      my_account: 'My Account',
      security: 'Security',
      data: 'Data',
      services: 'Services',
      support: 'Support & Help',
      FAQ: 'FAQ',
      affiliation: 'Affiliation',
      cookies_policy: 'Cookies Policy',
      terms_conditions: 'Terms & Conditions',
    },
    bottom_footer: {
      all_rights_reserved: 'All rights reserved',
      legal_notice: 'Legal Notice',
      privacy_policy: 'Privacy Policy',
    },
    account_menu: {
      informations: 'Information',
      subscription: 'Subscription',
      security: 'Security',
      history: 'History',
      agreements: 'Agreements',
      data: 'Data',
      support: 'Support',
    },
    personal_data: {
      title: 'Your Information',
      success: 'Your information has been updated.',
    },
    security: {
      title: 'Your Security',
      change_password: 'Change Password',
      change_email: 'Change Email Address',
    },
    data_usage: {
      title: 'Your Data',
      delete_account: 'Delete My Account',
      delete_account_description:
        'You will receive an email to validate the deletion.',
    },
    two_factor: {
      title: 'Two-factor Authentication (2FA)',
      enable: 'Enable 2FA',
      enable_description:
        'You will receive an email to validate the activation.',
      success: '2FA enabled.',
      scan_qr_code: 'Scan this QR Code with your authentication app:',
      code: 'Code',
      modal_title: 'Two-factor Authentication (2FA) Code',
      manually: 'Or enter this code manually:',
      email_confirmation: 'Email confirmation code',
    },
    legal_notice: {
      title: 'Legal Notice',
      privacy_policy: 'Privacy Policy',
      CGS: 'Terms and Conditions',
    },
    filter_menu: {
      unit: {
        gb: 'Gb',
        inch: '"',
      },
      result: 'Results for',
      filters: {
        Marque: 'Brand',
        Catégorie: 'Category',
        Couleur: 'Color',
        Stockage: 'Storage',
        "Taille d'écran": 'Screen Size',
      },
      price: {
        title: 'Price',
        minus_150: 'Less than €150',
        from_150_to_300: 'From €150 to €300',
        from_300_to_500: 'From €300 to €500',
        from_500_to_800: 'From €500 to €800',
        from_800_to_1000: 'From €800 to €1000',
        more_1000: 'More than €1000',
        to: 'to',
      },
      merchants: 'Merchants',
    },
    search_filter: {
      by_default: 'By default',
      price_decreasing: 'Descending price',
      price_increasing: 'Ascending price',
      news: 'New Arrivals',
    },
    pagination: {
      go_to_page: 'Go to page',
      results_on: 'results on',
    },
    product_page: {
      similar_products: 'Similar Products',
      description: 'Description',
      features: 'Features',
      about: 'About',
      description_product: 'Product Description',
      features_product: 'Product Features',
      trust_arguments: {
        secure_payment: 'Secure payment',
        rgpd: 'GDPR compliant',
        secure_redirection: 'Secure redirection',
      },
    },
    reviews: {
      title: 'Reviews',
    },
    support: {
      title: 'Support',
      contact_form_title: 'Contact Form',
      contact_form_description: 'Fill out the form below to contact us.',
      other_contact_methods_title: 'Other Contact Methods',
      other_contact_methods_description:
        'You can also contact us through our social networks or by email.',
      contact_reason_1: 'Login Issue',
      contact_reason_2: 'Issue with Wilbot',
      contact_reason_3: 'Website Issue',
      contact_reason_4: 'Other',
      alert:
        'You agree to communicate your email address, first and last name for the purpose of your request',
    },
    support_user: {
      title: 'Your support requests',
      new_request: 'New request',
      status: {
        pending: 'Pending',
        in_progress: 'In progress',
        closed: 'Solved',
      },
      type_of_demand: 'Type of request',
      description: 'Description',
      request: 'Request',
      write_your_message: 'Write your message...',
    },
    about: {
      title: 'About Wilfind',
      who_are_we: 'Who are we?',
    },
    faq: {
      title: 'FAQ',
      what_is_wilfind: 'What is Wilfind?',
      what_is_wilfind_description:
        "Wilfind, the first online shopping platform integrating innovative artificial intelligence (AI), reinvents the search for the perfect product by combining technology expertise and the power of AI. Enjoy a personalized, smooth, and pleasant experience that guides you to the most relevant choices based on your needs and preferences. Opt for an efficient and enjoyable search with our advanced AI that presents you with tailor-made recommendations instantly. Dive into this new era of online shopping today. Trust Wilfind, and you'll find what you're looking for.",
      what_is_wilbot: 'What is Wilbot?',
      what_is_wilbot_description:
        'Wilbot is our Chatbot with artificial intelligence (AI) that aims to help you find the ideal product. Just ask him a question, and he will continue the conversation with you to help you find the perfect product. As the conversation progresses, Wilbot will suggest products that match your needs before presenting the final products.',
      what_is_wilbot_description_2:
        'Wilbot combines artificial intelligence (AI) and technological expertise to find the ideal product; this technology continues to evolve over time.',
      how_to_access: 'How to access Wilbot?',
      how_to_access_description:
        'To access Wilbot, you just need to sign up or log in to Wilfind, then go to the Wilbot page. We limit the use to users with an account to avoid overloading our servers and thus be able to offer a quality service.',
      limitations: 'Limitations',
    },
    affiliation: {
      title: 'Affiliation',
      what_is_affiliation: 'What is affiliation?',
      what_is_affiliation_description:
        "It's a business partnership in which we associate with other websites that offer products complementary or similar to ours.",
      what_is_affiliation_description_2:
        'When you buy a product through our website, we may receive a commission for this sale. However, rest assured that this does not result in any additional cost to you.',
      what_is_affiliation_description_3:
        'Affiliation is simply a way for us to offer you a wider variety of quality products and continue to provide exceptional service.',
      how_affiliation_works: 'How does affiliation work?',
      how_affiliation_works_description:
        'When you click on an affiliated product on our website, you will be redirected to the partner site where you can make your purchase securely.',
      how_affiliation_works_description_2:
        'Partner sites are carefully selected for their reliability, service quality, and relevance of their products. When you make a purchase, the partner site informs us of this transaction and grants us a commission on the sale.',
      how_affiliation_works_description_3:
        "It's thanks to this commission that we can continue to offer you an exceptional shopping experience, constantly expanding our range of products.",
      how_affiliation_works_description_4:
        "We understand that trust is paramount in the online shopping process. That's why we commit to only collaborating with reputable and reliable partner sites, ensuring your shopping experience remains transparent, secure, and enjoyable.",
      why_affiliation: 'Why affiliation?',
      why_affiliation_description:
        'At Wilfind, we believe in the power of collaboration to offer our customers an exceptional shopping experience.',
      why_affiliation_description_2:
        "That's why we have set up an affiliation program that allows you to benefit from an even wider selection of exceptional products from trusted partner sites.",
      why_affiliation_description_3:
        'We invite you to explore our product catalog and discover the opportunities offered by our affiliation program. You will find a variety of quality products from different partner sites, carefully selected to meet your needs and tastes.',
      why_affiliation_description_4:
        "Please don't hesitate to contact us if you have any questions or concerns; our team will be happy to assist you.",
    },
    history: {
      title: 'History',
      empty: 'Your history is empty.',
      empty_description: 'You have not yet made a search.',
      product: 'Product',
      search: 'Search',
      wilbot: 'Wilbot',
      elements_number: 'Number of elements',
      i_want_to_delete: 'I want to delete:',
    },
    wilbot: {
      category: {
        title: 'Category',
        smartphone: 'Smartphone',
        laptop: 'Laptop',
        tablet: 'Tablet',
        desktop: 'Desktop',
        'all-in-one': 'All-in-one Computer',
      },
      suggested_response: {
        smartphone: 'Smartphone',
        laptop: 'Laptop',
        tablet: 'Tablet',
        desktop: 'Desktop',
      },
      loading_chat_response: {
        0: 'Searching for the best products ',
        1: 'Searching for the best products .',
        2: 'Searching for the best products ..',
        3: 'Searching for the best products ...',
      },
      loading_product_chat_response: {
        0: 'Searching for information ',
        1: 'Searching for information .',
        2: 'Searching for information ..',
        3: 'Searching for information ...',
      },
      reset_date: 'This limit will be reset on: ',
      subscription_required:
        'You have reached your <0>limit</0> of daily questions, to increase this limit you can see the different <1>offers here.</1>',
      product_subscription_required:
        'To use the <0>Question +</0> feature, you need to be a subscriber, you can see the different <1>offers here.</1>',
      default_response:
        '<0>Welcome to Wilbot,</0> you can start by giving me <1>the product category</1> you want to search for.',
      // default_response: {
      //   smartphone:
      //     '<0>Very well,</0> you can start for example by giving me <1>the uses</1> you want to do with your <2>smartphone.</2>',
      //   tablet:
      //     '<0>Very well,</0> you can start for example by giving me <1>the uses</1> you want to do with your <2>tablet.</2>',
      //   laptop:
      //     '<0>Very well,</0> you can start for example by giving me <1>the uses</1> you want to do with your <2>laptop.</2>',
      //   desktop:
      //     '<0>Very well,</0> you can start for example by giving me <1>the uses</1> you want to do with your <2>desktop.</2>',
      //   'all-in-one':
      //     '<0>Very well,</0> you can start for example by giving me <1>the uses</1> you want to do with your <2>all-in-one computer.</2>',
      // },
      on: 'on',
      best_product_for_you: 'Best product for you!',
      chat_with_expert: 'Chat with an expert',
      new_discussion: 'New chat',
      product_question:
        'When a product is selected, you can ask questions about this product; then, the conversation thread will return to normal.',
      welcome: 'Hello, welcome to Wilfind. What are you looking for?',
      guide: 'Guide',
      no_product_found:
        "Sorry, I couldn't find a product that matches your needs. Try modifying your search criteria.",
      no_category_found: "Sorry, I couldn't find a category, try again.",
      message_too_long: 'Your message is too long.',
      chat_error: 'An error occurred, please try again.',
      dislike_message_success: 'Thank you for your feedback.',
      like_message_success: 'Thank you for your feedback.',
      dislike_message_error: 'An error occurred.',
      dislike_placeholder: 'Why is this answer not suitable for you?',
      dislike_title: 'Give us your feedback.',
      like_title: 'Give us your feedback.',
      like_placeholder: 'Would you like to specify something?',
      write_your_message: 'Write your message...',
      data_secure: 'Your personal data is protected.',
      suggestions: 'Suggestions',
      product_find: 'Product found',
      product_right_title: 'Result based on your answers:',
      product_right_subtitle: 'Find the perfect product for you.',
      product_right_not_found: 'No results',
      redirect_message:
        ', once the product is clicked, you will be redirected.',
      view_on_wilfind: 'View on Wilfind',
      final_product:
        "I recommend this product; if you have any other questions, don't hesitate!",
      final_products:
        "I recommend these products; if you have any other questions, don't hesitate!",
      warning_message:
        'Wilbot is under development; it may display inaccurate or false information.',
      missing_filters: {
        title:
          "Unfortunately, I couldn't find a product that exactly matches your search, but I found these products:",
        smaller_screen: 'Smaller screen',
        bigger_screen: 'Bigger screen',
        smaller_storage: 'Less storage',
        bigger_storage: 'More storage',
        smaller_price: 'Cheaper',
        bigger_price: 'More expensive',
        different_color: 'Different color',
        foldable_screen: 'Foldable screen',
        not_foldable_screen: 'Non-foldable screen',
      },
      not_same_filter: {
        screen_size_plus: 'Bigger screen',
        screen_size_minus: 'Smaller screen',
        screen_size: 'Not the same screen size',
        storage_plus: 'More storage',
        storage_minus: 'Less storage',
        storage: 'Not the same storage',
        price_plus: 'More expensive',
        price_minus: 'Cheaper',
        price: 'Not the same price',
        color: 'Not the same color',
        brand: 'Not the same brand',
        extra_filter: 'Missing some additional information',
      },
      exemples: {
        0: "<0>I'm looking for a new smartphone</0> for gaming.",
        1: '<0>I want to buy a phone</0> for taking photos and making videos.',
        2: '<0>Phone</0> with which I can make music.',
        3: '<0>Find me a smartphone</0> with a budget of €500.',
      },
      history: {
        history: 'History',
        title: 'Conversation history:',
        description: 'Find you old conversations.',
        filters: {
          dateAsc: 'Most recent',
          dateDesc: 'Oldest',
        },
        chats: 'Chats',
        liked_chats: 'Liked chats',
        empty: 'Your history is empty.',
      },
    },
    manage_cookies: {
      title: 'Manage Cookies',
    },
    cookies: {
      continue_without_accepting: 'Continue without accepting',
      policy: 'Cookie Policy',
      description:
        '<0>Wilfind</0> uses cookies on this site for the proper functioning of the site, security, audience measurement, analysis, improvement of your user experience, and advertising purposes.<1></1><2></2>Your consent to the installation of non-essential cookies is voluntary and can be changed at any time.<3></3><3></3>You can give or withdraw your consent globally or set your preferences by cookie purpose.',
      manage_description:
        'You can give or withdraw your consent either globally or by cookie purpose.<0></0>You must accept or reject cookies in each category, then save your detailed choices.<1></1><2></2>If your browser is set to reject all cookies, you will not be able to use essential functions of our site, such as saving your login preferences for sessions requiring identification.',
      essential:
        '<0>Essential:</0> Cookies necessary for personalization and the operation of the website: they allow you to use the main features of the site, including saving information between two site visits on the same device, saving session login credentials, or interface customization elements (language choice or presentation). They do not require your prior consent. These cookies are essential for the proper functioning of the site. If you refuse to store these cookies on your device or browser, or if you delete those already stored, you will be informed that your navigation and experience on the site may be limited.',
      analytics:
        '<0>Analytics:</0> Analytical cookies, statistics, or audience measurement cookies for the website that allow us to know the usage and audience performance of the site and improve its operation for our visitors, for example, by establishing statistics and volumes of attendance and use of various elements composing the site (visited sections and content, paths), to improve the interest and ergonomics of the site.',
      ads: '<0>Advertising:</0> Advertising cookies that allow us to choose in real-time which ads to display on third-party sites. These cookies are only deposited when you have expressly consented through the banner presented during your first connection.',
      consent:
        'By checking the box below, you consent to the collection, processing, and storage of your personal data in accordance with our privacy policy.',
      privacy_policy: 'Privacy Policy',
      manage: 'Preferences',
      accept: 'Accept All',
      decline: 'Decline All',
      save: 'Save',
      consult_policy: 'View our cookies and data protection policy',
    },
    error: {
      subscription_required: "You don't have the required subscription.",
      missing_fields: 'Please fill in all the fields.',
      google_user: 'Impossible because you are logged in with Google.',
      missing_message: 'Please write a message.',
      wilbot_limit_message: 'You have reached the daily message limit.',
      missing_category: 'Please choose a category.',
      fill_all_fields: 'Please fill in all the fields.',
      password_not_secure: 'Your password is not secure enough.',
      password_not_valid: 'Your password is invalid.',
      name_length: 'Your name must be between 2 and 20 characters.',
      firstname_length: 'Your first name must be between 2 and 20 characters.',
      invalid_code: 'Invalid code.',
      product_not_found: 'Product not found.',
      captcha_error: 'Invalid captcha.',
      deleted_account: 'Your account has been deleted.',
      invalid_credentials: 'Incorrect email/password.',
      too_many_attempts: 'Too many attempts, try again in an hour.',
      email_not_valid: 'Your email is invalid.',
      email_already_used: 'Your email is already used.',
      code_invalid_or_expired: 'Invalid or expired code',
      link_invalid_or_expired: 'Invalid or expired link.',
      email_not_sent: 'Email not sent.',
      chat_not_found: 'Conversation not found.',
      chat_error: 'An error occurred in the conversation.',
      default_error: 'An error occurred.',
      global: {
        server_error: 'An error occurred.',
        email_error: 'Email sending problem.',
        link_invalid_or_expired: 'Invalid or expired link.',
        captcha_error: 'Invalid captcha.',
      },
    },
    loading: {
      loading_0: 'Loading',
      loading_1: 'Loading.',
      loading_2: 'Loading..',
      loading_3: 'Loading...',
    },
    delete_account: {
      success: 'Your account has been successfully deleted.',
    },
    change_password: {
      success: 'Your password has been successfully changed!',
      description: 'You can now log in with your new password.',
    },
    change_email: {
      success: 'Your email has been successfully changed.',
      description: 'You can now log in with your new email address.',
    },
    verify_account: {
      success: 'Your account has been successfully verified.',
      description: 'You can now log in and fully enjoy our services.',
    },
    continue_pop_up: {
      title: 'Log in or sign up in a few seconds',
      description: 'Use your email or another service to use our AI for free!',
      thanks_register: 'Thank you for registering!',
      verify_email: 'Verify your email address',
      verification_code: 'A verification code has been sent to you by email.',
      register_description: 'Sign up in a few seconds to access Wilfind',
    },
    beta: {
      title: 'Early Access',
      description:
        'Join the <0>Wilfind</0> adventure and access the <1>beta version of Wilbot.</1>',
      submit: 'Request access',
      alert:
        'By entering your email address, you will be informed of the release of Wilbot and you will be able to access the beta version.',
      presentation: {
        0: 'Discover <0>Wilbot</0>,',
        1: 'find your product thanks to AI.✨',
        2: 'find the perfect product for you.',
        3: 'find the perfect product simply.',
        4: 'find the perfect product quickly.',
      },
      success: 'Thank you for your request! You will be informed by email.',
    },
    subscriptionSuccess: {
      title: 'Thank you for your subscription!',
      description:
        'You can now enjoy the benefits of your subscription. You will receive an email confirming your subscription.',
    },
    meta: {
      title: {
        home: 'Wilfind - The first AI Shop',
        wilbot: 'Wilbot - AI Shopping Assistant',
        comparator: 'Wilfind - Comparator',
        login: 'Wilfind - Login',
        register: 'Wilfind - Register',
        favorites: 'Wilfind - Favorites',
        cart: 'Wilfind - Cart',
        history: 'Wilfind - History',
        terms_conditions: 'Wilfind - Terms & Conditions',
        privacy_policy: 'Wilfind - Privacy Policy',
        cookies_policy: 'Wilfind - Cookies Policy',
        affiliate: 'Wilfind - Affiliate',
        about_us: 'Wilfind - About Us',
        help: 'Wilfind - Help',
        faq: 'Wilfind - FAQ',
        manage_cookies: 'Wilfind - Manage Cookies',
        subscriptions: 'Wilfind - Subscriptions',
        reset_password: 'Wilfind - Reset Password',
        change_password: 'Wilfind - Change Password',
        change_email: 'Wilfind - Change Email',
        verify_account: 'Wilfind - Verify Your Account',
        delete_account: 'Wilfind - Delete Your Account',
        personal_data: 'Wilfind - Your Information',
        security: 'Wilfind - Your Security',
        data_usage: 'Wilfind - Your Data',
        two_factor: 'Wilfind - Two-Factor Authentication',
        support: 'Wilfind - Support',
        manage_subscription: 'Wilfind - Manage Your Subscription',
      },
    },
  },
};

export default en;
