import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './app/store';
// import { MatomoProvider, createInstance } from '@jonkoops/matomo-tracker-react';
import './i18n';
import '@fontsource/inter/100.css';
import '@fontsource/inter/200.css';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/800.css';
import '@fontsource/inter/900.css';

// const instance = createInstance({
//   urlBase: 'https://stats.wilfind.fr',
//   trackerUrl: 'https://stats.wilfind.fr/matomo.php',
//   srcUrl: 'https://stats.wilfind.fr/matomo.js',
//   siteId: 3,
// });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <MatomoProvider value={instance}>
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<App />} />
      </Routes>
    </BrowserRouter>
  </Provider>
  // </MatomoProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
