import '../../styles/Header/top-header.scss';
import Campaign from '../../Icons/Campaign';
import ThinRightArrow from '../../Icons/ThinRightArrow';
import GTranslate from '../../Icons/GTranslate';
import DownArrow from '../../Icons/DownArrow';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  readTopMessage,
  clearTopMessage,
} from '../../features/topMessage/topMessageSlice';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';

const items = [
  {
    label: 'EN',
    key: 'en',
  },
  {
    label: 'FR',
    key: 'fr',
  },
  {
    label: 'ES',
    key: 'es',
  },
];

export default function TopHeader() {
  const dispatch = useDispatch();
  const topMessage = useSelector(readTopMessage);
  const { t, i18n } = useTranslation();

  const topNews = [
    {
      title: t('header.top.news_1'),
      url: '/wilbot',
    },
    {
      title: t('header.top.news_2'),
      url: '/product-results?q=iphone 15',
    },
  ];

  const onClick = ({ key }) => {
    i18n.changeLanguage(key);
  };

  const handleCancel = () => {
    dispatch(clearTopMessage());
  };

  return (
    <div className={`top-header top-header-${topMessage.type}`}>
      <div className="compare-limit-pop-up">
        <p>{t('comparator.error_max')}</p>
        <p className="cancel" onClick={handleCancel}>
          {t('global.cancel')}
        </p>
      </div>
      <div className="autorize-pop-up">
        <DownArrow />
        <p>{t('redirection_pop_up.description')}</p>
      </div>
      <p className="error-msg">{topMessage.value}</p>
      <div className="wrapper">
        <div className="news">
          <Campaign />
          <Swiper
            simulateTouch={false}
            slidesPerView={1}
            style={{ width: '180px' }}
            loop={true}
            autoplay={{
              delay: 10000,
            }}
            modules={[Autoplay]}
          >
            <SwiperSlide className="news-wrapper">
              <p>{topNews[0].title}</p>
              <div className="see-more">
                <a href={topNews[0].url}>{t('global.view')}</a>
                <ThinRightArrow />
              </div>
            </SwiperSlide>
            <SwiperSlide className="news-wrapper">
              <p>KickStarter</p>
              <div className="see-more">
                <a
                  href={
                    'https://www.kickstarter.com/projects/wilfind/the-world-first-ai-shop-wilfind'
                  }
                >
                  {t('global.view')}
                </a>
                <ThinRightArrow />
              </div>
            </SwiperSlide>
            <SwiperSlide className="news-wrapper">
              <p>{topNews[1].title}</p>
              <div className="see-more">
                <a href={topNews[1].url}>{t('global.view')}</a>
                <ThinRightArrow />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="help">
          <a href="https://www.kickstarter.com/projects/wilfind/the-world-first-ai-shop-wilfind">
            Kickstarter
          </a>
          <a href="/subscriptions">{t('header.top.subscriptions')}</a>
          <a href="/help">{t('header.top.help')}</a>
          <a href="/faq">{t('header.top.faq')}</a>
          <a href="/affiliate">{t('header.top.affiliation')}</a>
          <div className="language-choice">
            <GTranslate />
            <Dropdown
              menu={{
                items,
                selectable: true,
                defaultSelectedKeys: i18n.language,
                onClick,
              }}
              trigger={['click']}
              overlayClassName="language-choice-dropdown"
            >
              <div className="language-choice-wrapper">
                <p>{i18n.language}</p>
                <DownArrow />
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
}
