import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

const initialState = localStorage.getItem('history')
  ? JSON.parse(localStorage.getItem('history'))
  : [];

const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    addProductHistory: (state, action) => {
      const { productName, date, productId } = action.payload;
      state.push({
        type: 'productHistory',
        productName,
        date,
        productId,
        id: uuidv4(),
      });
      localStorage.setItem('history', JSON.stringify(state));
    },
    addHistory: (state, action) => {
      state.push(action.payload);
      localStorage.setItem('history', JSON.stringify(state));
    },
    addSearchHistory: (state, action) => {
      const { searchName, date } = action.payload;
      state.push({ type: 'searchHistory', searchName, date, id: uuidv4() });
      localStorage.setItem('history', JSON.stringify(state));
    },
    deleteById: (state, action) => {
      console.log(action.payload);
      const { id } = action.payload;
      const index = state.findIndex((item) => item.id === id);
      state.splice(index, 1);
      localStorage.setItem('history', JSON.stringify(state));
    },
    deleteByIds: (state, action) => {
      const items = action.payload;
      items.forEach((item) => {
        if (item.type === 'searchHistory' || item.type === 'productHistory') {
          const index = state.findIndex((i) => i.id === item.id);
          state.splice(index, 1);
        }
      });
      localStorage.setItem('history', JSON.stringify(state));
    },
    setHistory: (state, action) => {
      state = action.payload;
      localStorage.setItem('history', JSON.stringify(state));
    },
  },
});

export const readHistory = (state) => state.history;

export const {
  addProductHistory,
  addSearchHistory,
  deleteByIds,
  addHistory,
  setHistory,
} = historySlice.actions;

export default historySlice.reducer;
