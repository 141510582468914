import axios from 'axios';
const BASE_URL = process.env.REACT_APP_API_URL;

export default axios.create({
  baseURL: BASE_URL,
  headers: { language: localStorage.getItem('i18nextLng') },
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    language: localStorage.getItem('i18nextLng'),
  },
  withCredentials: true,
});
