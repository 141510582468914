import '../../styles/Header/header.scss';
import 'swiper/css';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import TopHeader from './TopHeader';
import MenuIcon from '../../Icons/MenuIcon';
import SearchIcon from '../../Icons/SearchIcon';
import CartIcon from '../../Icons/CartIcon';
import FavoritesIcon from '../../Icons/FavoritesIcon';
import ProfileIcon from '../../Icons/ProfileIcon';
import NavHeader from './NavHeader';
import { Link } from 'react-router-dom';
import { useTransition, animated } from 'react-spring';
import { useLocation } from 'react-router-dom';
import { Badge, Dropdown } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import LeftArrowIcon from '../../Icons/LeftArrowIcon';
import { readCart } from '../../features/cart/cartSlice';
import { readFavorites } from '../../features/favorites/favoritesSlice';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMenuCategory } from '../../features/menuCategory/menuCategorySlice';
import {
  toggleCart,
  toggleMenu,
  toggleProfile,
  readPopUp,
} from '../../features/popUp/popUpSlice';
import GTranslate from '../../Icons/GTranslate';
import DownArrow from '../../Icons/DownArrow';
import { useTranslation } from 'react-i18next';
import { selectCurrentPlanType } from '../../features/auth/authSlice';
import RightArrowIcon from '../../Icons/RightArrowIcon';
import {
  getImage,
  getLowestOldPrice,
  getLowestPrice,
  getName,
  getOffPercentage,
  showProductPrice,
} from '../../utils/product';
import { readCompare } from '../../features/compare/compareSlice';
import {
  clearTopMessage,
  readTopMessage,
  setTopMessage,
} from '../../features/topMessage/topMessageSlice';
import { useRemoveCompare } from '../../hooks/compare';
import axios from '../../api/axios';

const items = [
  {
    label: 'EN',
    key: 'en',
  },
  {
    label: 'FR',
    key: 'fr',
  },
  {
    label: 'ES',
    key: 'es',
  },
];

const MobileNav = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const popUp = useSelector(readPopUp);
  const planType = useSelector(selectCurrentPlanType);
  // REF
  const homeRef = useRef(null);
  const compareRef = useRef(null);
  const wilbotRef = useRef(null);
  useEffect(() => {
    if (location.pathname === '/comparator') {
      compareRef.current?.classList.add('active');
      homeRef.current?.classList.remove('active');
      wilbotRef.current?.classList.remove('active');
    } else if (location.pathname === '/wilbot') {
      wilbotRef.current?.classList.add('active');
      homeRef.current?.classList.remove('active');
      compareRef.current?.classList.remove('active');
    } else if (location.pathname === '/') {
      homeRef.current?.classList.add('active');
      compareRef.current?.classList.remove('active');
      wilbotRef.current?.classList.remove('active');
    } else {
      homeRef.current?.classList.remove('active');
      compareRef.current?.classList.remove('active');
      wilbotRef.current?.classList.remove('active');
    }
  }, [location]);

  const onClick = ({ key }) => {
    i18n.changeLanguage(key);
  };

  return (
    <div
      className={`mobile-nav-wrapper ${
        popUp.productsFilterPopUp ? 'mobile-nav-wrapper-hidden' : ''
      }`}
    >
      <div className="pages">
        <Link ref={homeRef} to="/" className="active">
          {t('header.pages.home')}
        </Link>
        <Link ref={compareRef} to="/comparator">
          {t('header.pages.comparator')}
        </Link>
        <Link ref={wilbotRef} className="to-wilbot" to="/wilbot">
          WilBot
          {planType ? (
            <span className={planType}>{planType}</span>
          ) : (
            <span className="free">Try Now</span>
          )}
        </Link>
      </div>
      <div className="language-choice">
        <GTranslate />
        <Dropdown
          menu={{
            items,
            selectable: true,
            defaultSelectedKeys: i18n.language,
            onClick,
          }}
          trigger={['click']}
        >
          <div className="language-choice-wrapper">
            <p>{i18n.language}</p>
            <DownArrow />
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

const MobileSearch = ({
  query,
  setQuery,
  handleSearch,
  handleCloseAutoComplete,
}) => {
  const { t } = useTranslation();
  // get the location to check if the user is in the home page
  const location = useLocation();
  if (location.pathname === '/') return null;
  return (
    <div className="mobile-search-wrapper">
      <form onSubmit={handleSearch} className="search-bar-wrapper">
        <SearchIcon />
        <input
          onChange={(e) => setQuery(e.target.value)}
          placeholder={t('input.search_placeholder')}
          defaultValue={query}
          onBlur={handleCloseAutoComplete}
        />
      </form>
    </div>
  );
};

const PageMenu = () => {
  // TRANSLATION
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const popUp = useSelector(readPopUp);
  const transition = useTransition(popUp.menu, {});

  const changeCategory = (e) => {
    dispatch(setMenuCategory(e.target.id));
    // add active class to the clicked element
    const options = document.querySelectorAll('.pages-menu-options');
    options.forEach((option) => option.classList.remove('active'));
    e.target.classList.add('active');
  };

  return transition((style, item) =>
    item ? (
      <animated.div style={style} className="pages pages-menu">
        <p
          id="Smartphone"
          className="active pages-menu-options"
          onClick={changeCategory}
        >
          {t('global.category.Smartphone')}
        </p>
        <p
          id="Ordinateur Portable"
          className="pages-menu-options"
          onClick={changeCategory}
        >
          {t('global.category.Ordinateur Portable')}
        </p>
        <p
          className="pages-menu-options"
          id="Unité Centrale"
          onClick={changeCategory}
        >
          {t('global.category.Unité Centrale')}
        </p>
        <p
          className="pages-menu-options"
          id="Tablette"
          onClick={changeCategory}
        >
          {t('global.category.Tablette')}
        </p>
        <p
          className="pages-menu-options"
          id="Ordinateur Tout en Un"
          onClick={changeCategory}
        >
          {t('global.category.Ordinateur Tout en Un')}
        </p>
      </animated.div>
    ) : (
      ''
    )
  );
};

const Product = ({ product }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleRemoveCompare = useRemoveCompare(product);
  const price = getLowestPrice(product);
  // take the old price the highest one and if the old price is the same as the price then don't show it
  const oldPrice = getLowestOldPrice(product);
  const offPercentage = getOffPercentage(product);

  const removeCompare = () => {
    dispatch(clearTopMessage());
    handleRemoveCompare();
  };

  return (
    <div className="product">
      <a href={`/product-page/${product._id}`} className="img-wrapper">
        <img src={getImage(product)} alt={product?.Modèle} />
      </a>
      <div className="wrapper">
        <a href={`/product-page/${product._id}`} className="info-wrapper">
          <p className="brand">
            {t(`global.category.${product.Catégorie}`)} ({product.Marque})
          </p>
          <p className="title">{getName(product, t)}</p>
          <div className="price-wrapper">
            <div className="price">
              <p>{showProductPrice(price, t('global.currency'))}</p>
              {oldPrice === price || !oldPrice ? null : (
                <p className="old-price">
                  {oldPrice} {t('global.currency')}
                </p>
              )}
            </div>
            <p className="off-percentage">
              {oldPrice === price || !oldPrice ? null : (
                <span>{offPercentage}% OFF</span>
              )}
            </p>
          </div>
          <div className="divider" />
        </a>
        <div className="actions-wrapper">
          <p className="delete" onClick={removeCompare}>
            {t('global.delete')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default function Header({ mobileMenuCategory, setMobileMenuCategory }) {
  // TRANSLATION
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const popUp = useSelector(readPopUp);
  // REF
  const homeRef = useRef(null);
  const compareRef = useRef(null);
  const wilbotRef = useRef(null);
  const otherRef = useRef(null);
  const sliderRef = useRef(null);
  const pagesRef = useRef(null);
  const compareLimitRef = useRef(null);
  const [actualPage, setActualPage] = useState();
  const pagesList = ['home', 'comparer', 'wilbot'];
  const language = i18n.language;
  const queryParams = new URLSearchParams(window.location.search);
  const q = queryParams.get('q');

  const [query, setQuery] = useState('');
  const location = useLocation();
  const [scrolled, setScrolled] = useState(false);

  const planType = useSelector(selectCurrentPlanType);

  const compareProducts = useSelector(readCompare);
  const topMessage = useSelector(readTopMessage);

  const [autoComplete, setAutoComplete] = useState([]);

  useEffect(() => {
    // Add event listener to detect clicks outside of the element
    const handleClickOutside = (event) => {
      if (
        compareLimitRef.current &&
        !compareLimitRef.current.contains(event.target)
      ) {
        dispatch(setTopMessage({}));
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [compareLimitRef]);

  const resizeSlider = () => {
    if (!popUp.menu) {
      let pagesLeft = pagesRef.current.getBoundingClientRect().left;
      let slider = sliderRef.current;
      if (location.pathname === '/comparator') {
        let compareLeft = compareRef.current.getBoundingClientRect().left;
        let width = compareRef.current?.offsetWidth;
        slider.style.width = `${width + 10}px`;
        slider.style.insetInlineStart = `${compareLeft - pagesLeft - 5}px`;
      } else if (location.pathname === '/wilbot') {
        let wilbotLeft = wilbotRef.current.getBoundingClientRect().left;
        let width = wilbotRef.current?.offsetWidth;
        slider.style.width = `${width + 10}px`;
        slider.style.insetInlineStart = `${wilbotLeft - pagesLeft - 5}px`;
      } else if (location.pathname === '/') {
        let width = homeRef.current?.offsetWidth;
        let slider = sliderRef.current;
        slider.style.width = `${width + 10}px`;
        slider.style.insetInlineStart = '-5px';
      } else {
        let otherLeft = otherRef.current.getBoundingClientRect().left;
        let width = otherRef.current?.offsetWidth;
        slider.style.width = `${width + 10}px`;
        slider.style.insetInlineStart = `${otherLeft - pagesLeft - 5}px`;
      }
    }
  };

  useEffect(() => {
    resizeSlider();
  }, [
    planType,
    popUp.menu,
    location.pathname,
    actualPage,
    language,
    compareProducts,
  ]);

  // check if the user is in comparer or wilbot or / page
  useEffect(() => {
    if (!popUp.menu) {
      if (location.pathname === '/comparator') {
        compareRef.current?.classList.add('active');
        homeRef.current?.classList.remove('active');
        wilbotRef.current?.classList.remove('active');
        otherRef.current?.classList.remove('active');
        setActualPage('comparer');
      } else if (location.pathname === '/wilbot') {
        wilbotRef.current?.classList.add('active');
        homeRef.current?.classList.remove('active');
        compareRef.current?.classList.remove('active');
        otherRef.current?.classList.remove('active');
        setActualPage('wilbot');
      } else if (location.pathname === '/') {
        homeRef.current?.classList.add('active');
        compareRef.current?.classList.remove('active');
        wilbotRef.current?.classList.remove('active');
        otherRef.current?.classList.remove('active');
        setActualPage('home');
      } else {
        otherRef.current?.classList.add('active');
        homeRef.current?.classList.remove('active');
        compareRef.current?.classList.remove('active');
        wilbotRef.current?.classList.remove('active');
        if (location.pathname.split('/')[1] === 'gd') setActualPage('404');
        else setActualPage(location.pathname.split('/')[1]);
      }
    }
  }, [location.pathname, popUp.menu]);

  const handleSearch = (e) => {
    e.preventDefault();
    window.location.href = `/product-results?q=${query}`;
  };
  // get the cart to check if it's empty
  const cart = useSelector(readCart);
  // get the favorite to check if it's empty
  const favorite = useSelector(readFavorites);
  const items = [
    {
      key: '0',
      label: <Link to="/">{t('header.pages.home')}</Link>,
    },
    {
      key: '2',
      label: <Link to="/wilbot">Wilbot</Link>,
    },
    {
      key: '3',
      label: <Link to="/comparator">{t('header.pages.comparator')}</Link>,
    },
  ];

  const handleToggleMenu = () => {
    dispatch(toggleMenu());
    dispatch(setMenuCategory('Smartphone'));
    setMobileMenuCategory('');
  };

  const handleQuitMobileMenu = () => {
    setMobileMenuCategory('');
    dispatch(setMenuCategory('Smartphone'));
  };

  const handleAutoComplete = async () => {
    try {
      const response = await axios.get(`/api/autocomplete?name=${query}`);
      // remove duplicates names from the array
      setAutoComplete(response.data);
    } catch (err) {
      setAutoComplete([]);
    }
  };

  const handleCloseAutoComplete = () => {
    setTimeout(() => {
      setAutoComplete([]);
    }, 100);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else setScrolled(false);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (query.length > 2) handleAutoComplete();
    if (query.length < 3) setAutoComplete([]);
  }, [query]);

  return (
    <header
      className={
        location.pathname === '/wilbot'
          ? 'wilbot-page'
          : scrolled
          ? 'header-scrolled'
          : ''
      }
    >
      <TopHeader />
      {topMessage.type === 'compare_limit' ? (
        <div
          ref={compareLimitRef}
          className="main-header main-header-comparator"
        >
          <div className="prev-button product-slider-left-navigation">
            <LeftArrowIcon />
          </div>
          <Swiper
            className="product-slider"
            slidesPerView={1}
            spaceBetween={15}
            module={[Navigation]}
            modules={[Navigation]}
            navigation={{
              prevEl: '.product-slider-left-navigation',
              nextEl: '.product-slider-right-navigation',
            }}
            breakpoints={{
              568: {
                slidesPerView: 2,
              },
              918: {
                slidesPerView: 3,
              },
            }}
          >
            {compareProducts.map((product, index) => {
              return (
                <SwiperSlide key={index}>
                  <Product product={product} />
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div className="next-button product-slider-right-navigation">
            <RightArrowIcon />
          </div>
        </div>
      ) : (
        <div
          className={`main-header ${
            popUp.menu || popUp.cart ? 'mobile-menu-main-header' : ''
          } ${
            popUp.menu || popUp.cart || popUp.profile ? 'opened-pop-up' : ''
          }`}
        >
          <Dropdown
            className="mobile-menu-dropdown"
            menu={{ items }}
            trigger={['click']}
          >
            <div>
              <p>
                {location.pathname === '/comparator'
                  ? t('header.pages.comparator')
                  : location.pathname === '/wilbot'
                  ? 'Wilbot'
                  : t('header.pages.home')}
              </p>
              <img alt="Dropdown" src="/img/mobile-menu-dropdown.png" />
            </div>
          </Dropdown>
          {mobileMenuCategory && (
            <div onClick={handleQuitMobileMenu} className="mobile-menu-return">
              <LeftArrowIcon />
            </div>
          )}
          <div className="left">
            {!mobileMenuCategory && (
              <div
                onClick={handleToggleMenu}
                className={
                  popUp.menu ? 'menu-icon-wrapper-open' : 'menu-icon-wrapper'
                }
              >
                <MenuIcon />
              </div>
            )}
            <div className="logo">
              <Link to="/">
                <img
                  src="/img/wilfind.png"
                  alt="Wilfind"
                  height="20"
                  width="75"
                />
              </Link>
            </div>
            {popUp.menu ? (
              <PageMenu />
            ) : (
              <ul className="pages" ref={pagesRef}>
                <li>
                  <Link ref={homeRef} to="/" className="active">
                    {t('header.pages.home')}
                  </Link>
                </li>
                <li>
                  <Link
                    ref={compareRef}
                    to="/comparator"
                    className="to-comparator"
                  >
                    {t('header.pages.comparator')}
                    {compareProducts.length > 0 && (
                      <Badge
                        count={compareProducts.length}
                        color="#000"
                        size="small"
                      />
                    )}
                  </Link>
                </li>
                <li>
                  <Link ref={wilbotRef} className="to-wilbot" to="/wilbot">
                    WilBot
                    {planType ? (
                      <span className={planType}>{planType}</span>
                    ) : (
                      <span className="free">Try Now</span>
                    )}
                  </Link>
                </li>
                <li>
                  <Link
                    ref={otherRef}
                    className={`other-page ${
                      pagesList.includes(actualPage) ? '' : 'active'
                    }`}
                  >
                    {t(`header.pages.${actualPage}`)}
                  </Link>
                </li>
                <li ref={sliderRef} className="slider"></li>
              </ul>
            )}
          </div>
          <div className="right">
            {location.pathname === '/' ? (
              <></>
            ) : (
              <div className="autocomplete-wrapper">
                <form onSubmit={handleSearch} className="search-bar-wrapper">
                  <SearchIcon />
                  <input
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder={t('input.search_placeholder')}
                    defaultValue={q}
                    onBlur={handleCloseAutoComplete}
                  />
                </form>
                {autoComplete.length > 0 && (
                  <div className="search-autocomplete">
                    {autoComplete?.map((result, index) => (
                      <a
                        href={`/product-results?q=${result.Modèle}`}
                        className="result"
                        key={index}
                        style={{
                          animation: `show-autocomplete-text 0.5s ease-in-out ${
                            index * 0.1
                          }s forwards`,
                        }}
                      >
                        <SearchOutlined />
                        <p>
                          <span>{result.Modèle}</span>
                        </p>
                      </a>
                    ))}
                  </div>
                )}
              </div>
            )}
            <div
              className={
                popUp.cart
                  ? 'cart-icon-wrapper icon-wrapper-open'
                  : 'cart-icon-wrapper'
              }
              onClick={() => dispatch(toggleCart())}
            >
              <Badge count={cart.length} color="#000" size="small">
                <CartIcon />
              </Badge>
            </div>
            <a
              className="favorites-icon-wrapper"
              href="/favorites"
              aria-label="Favorites"
            >
              <Badge count={favorite.length} size="small">
                <FavoritesIcon />
              </Badge>
            </a>
            <div
              className={
                popUp.profile
                  ? 'profile-icon-wrapper icon-wrapper-open'
                  : 'profile-icon-wrapper'
              }
              onClick={() => dispatch(toggleProfile())}
            >
              <ProfileIcon />
            </div>
            <div
              onClick={handleToggleMenu}
              className={
                popUp.menu
                  ? 'menu-icon-wrapper-open menu-icon-wrapper-responsive'
                  : 'menu-icon-wrapper menu-icon-wrapper-responsive'
              }
            >
              <MenuIcon />
            </div>
            {popUp.cart ? (
              <div
                onClick={() => dispatch(toggleCart())}
                className="close-icon-wrapper"
              >
                <img src="/img/close.png" alt="close" />
              </div>
            ) : (
              <div
                onClick={() => dispatch(toggleMenu())}
                className="close-icon-wrapper"
              >
                <img src="/img/close.png" alt="close" />
              </div>
            )}
          </div>
        </div>
      )}
      {topMessage.type === 'compare_limit' ? (
        <></>
      ) : (
        <MobileSearch
          query={q}
          setQuery={setQuery}
          handleSearch={handleSearch}
          handleCloseAutoComplete={handleCloseAutoComplete}
        />
      )}
      {topMessage.type === 'compare_limit' ? <></> : <MobileNav />}
      {topMessage.type === 'compare_limit' ? <></> : <NavHeader />}
      {autoComplete.length > 0 && (
        <div className="mobile-search-results">
          {autoComplete.map((result, index) => (
            <a
              href={`/product-results?q=${result.Modèle}`}
              className="result"
              key={index}
              style={{
                animation: `show-autocomplete-text 0.5s ease-in-out ${
                  index * 0.1
                }s forwards`,
              }}
            >
              <SearchOutlined />
              <p>
                <span>{result.Modèle}</span>
              </p>
            </a>
          ))}
        </div>
      )}
    </header>
  );
}
