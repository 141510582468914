import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  email: null,
  name: null,
  surname: null,
  phone: null,
  username: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo(state, action) {
      return (state = action.payload);
    },
  },
});

export const readUser = (state) => state.user;

export const { setUserInfo } = userSlice.actions;

export default userSlice.reducer;
