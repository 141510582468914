import { createSlice } from '@reduxjs/toolkit';

const initialState = { value: false };

const leftMenuSlice = createSlice({
  name: 'leftMenu',
  initialState,
  reducers: {
    updateLeftMenu(state, action) {
      state.value ? (state.value = false) : (state.value = true);
    },
  },
});

export const readLeftMenu = (state) => state.leftMenu.value;

export const { updateLeftMenu } = leftMenuSlice.actions;

export default leftMenuSlice.reducer;
