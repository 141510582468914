const fr = {
  translation: {
    header: {
      top: {
        news_1: 'Nouveau Wilbot AI',
        news_2: 'Iphone 15',
        help: 'Aide',
        faq: 'FAQ',
        affiliation: 'Affiliation',
        subscriptions: 'Abonnements',
      },
      pages: {
        home: 'Accueil',
        comparator: 'Comparateur',
        ai_availability: 'AI Disponible',
        'product-results': 'Recherche',
        'product-page': 'Produit',
        favorites: 'Favoris',
        cart: 'Panier',
        history: 'Historique',
        'legal-notice': 'Légales',
        affiliate: 'Affiliation',
        'about-us': 'À propos',
        help: 'Aide',
        faq: 'FAQ',
        subscriptions: 'Abonnements',
        'subscriptions-success': 'Abonnements',
        'reset-password': 'Mot de passe',
        'password-reset': 'Mot de passe',
        'change-password': 'Mot de passe',
        'change-email': 'Email',
        'verify-account': 'Compte',
        'delete-account': 'Compte',
        account: 'Compte',
        'manage-cookies': 'Cookies',
        'subscription-success': 'Succès',
        'terms-conditions': 'Conditions',
        'cookies-policy': 'Cookies',
        'privacy-policy': 'Confidentialité',
      },
    },
    global: {
      products: 'produits',
      see_the_products: 'Voir les produits',
      search_plus: 'Recherche +',
      page_not_found: 'Page non trouvée',
      manage_cookie: 'Gérer les cookies',
      coming_soon: 'À venir...',
      discover: 'Découvrir',
      currency: '€',
      close: 'Fermer',
      home: 'Accueil',
      or: 'Ou',
      view: 'Voir',
      view_more: 'Voir plus',
      view_less: 'Voir moins',
      register: "S'inscrire",
      login: 'Se connecter',
      login_short: 'Connexion',
      logout: 'Se déconnecter',
      my_account: 'Mon compte',
      quit: 'Quitter',
      remove: 'Supprimer',
      add_to_cart: 'Ajouter au panier',
      remove_from_cart: 'Retirer du panier',
      add_to_favorite: 'Ajouter aux favoris',
      remove_from_favorite: 'Retirer des favoris',
      add_to_comparator: 'Ajouter au comparateur',
      remove_from_comparator: 'Retirer du comparateur',
      buy_on_the_site: 'Acheter sur le site',
      available: 'Disponible',
      unavailable: 'Indisponible',
      loading: 'Chargement...',
      buy: 'Acheter',
      confirm: 'Confirmer',
      send: 'Envoyer',
      redirect_protected: 'Votre redirection est protégée',
      alert_buy:
        "Le site ne permet pas d'acheter les produits. Une fois le produit acheté, vous serez redirigé vers le site du vendeur.",
      all: 'Tous',
      products: 'Produits',
      total: 'Total',
      off: 'Off',
      modify: 'Modifier',
      my_profile: 'Mon profil',
      information: 'Informations',
      security: 'Sécurité',
      reviews: 'avis',
      save: 'Sauvegarder',
      delete: 'Supprimer',
      data_use: 'Utilisation des données',
      confidential: 'Accord de confidentialité',
      history: 'Historique',
      authorize_redirection: 'J’autorise la redirection',
      forgot_password: 'Mot de passe oublié ?',
      fill_all_fields: 'Vous devez remplir tout les champs.',
      default_error: 'Une erreur est survenue.',
      email_confirmation: 'Vous allez recevoir un email de confirmation.',
      fill_captcha: 'Veuillez remplir le captcha.',
      email_sent: 'Email envoyé.',
      password_changed: 'Votre mot de passe a bien été modifié.',
      link_invalid_or_expired: 'Lien invalide ou expiré.',
      thanks_for_using:
        "Merci d'avoir utiliser nos services, nous espérons vous revoir.",
      no_results: 'Aucun résultat pour : ',
      search: 'Rechercher',
      result_search: 'Résultat pour :',
      filter: 'Filtrer',
      validate: 'Valider',
      cancel: 'Annuler',
      next: 'Suivant',
      precedent: 'Précédent',
      return: 'Retour',
      redirect_in_3_seconds: 'Redirection dans 3 secondes...',
      category: {
        title: 'Catégories',
        Smartphone: 'Smartphone',
        Tablette: 'Tablette',
        'Ordinateur Portable': 'Ordinateur portable',
        'Unité Centrale': 'Unité centrale',
        'Ordinateur Tout en Un': 'Ordinateur tout-en-un',
      },
    },
    subscriptions: {
      title: 'Abonnements Wilfind',
      description: 'Nos différentes offres.',
      use: 'Utiliser',
      features: {
        title: "Choissisez l'offre qui vous convient le mieux.",
        compare_offer: 'Comparer nos différentes offres.',
        recommended: 'Recommandé',
        number_of_research_by_day: 'Nombre de recherche par jour',
        access_to_early_access: 'Accès à l’Early Access',
        priority_access: 'Accès prioritaire',
        question: 'Question +',
        priority_customer_service: 'Service client prioritaire',
        expert: 'Expert +',
        on_mesure_offer: 'Offre sur mesure',
      },
      subscription: {
        contact_us: 'Contactez-nous',
        contact: 'Contact',
        try_for_free: 'Essayer gratuitement',
        get_started: 'Commencer',
        billed_monthly: 'Facturé mensuellement',
        resiliation: 'Résiliation à tout moment',
        description_0:
          'Pour les particuliers souhaitant essayer Wilbot gratuitement.',
        description_1:
          'Le plan Plus est parfait pour pouvoir utiliser Wilbot librement.',
        description_2:
          'Ce plan vous permets de vous servir de Wilbot et des autres fonctionnalités.',
        description_3:
          'Le plan Ultra est le plan le plus complet, profiter de Wilfind sans limites.',
        description_4:
          'Pour les entreprises souhaitant utiliser Wilbot pour leur entreprise.',
        features: {
          '1 product / day': '1 produit / jour',
          '5 product / day': '5 produits / jour',
          '10 product / day': '10 produits / jour',
          '30 product / day': '30 produits / jour',
          '∞ product / day': '∞ produits / jour',
          'Early access': 'Accès anticipé',
          'Priority access': 'Accès prioritaire',
          'Question +': 'Question +',
          'Priority customer service': 'Service client prioritaire',
          'Expert +': 'Expert +',
          'On mesure offer': 'Offre sur mesure',
        },
      },
    },
    manage_subscriptions: {
      title: 'Gérer mon abonnement',
      manage_my_subscription: 'Gérer mon abonnement',
      see_different_plans: 'Voir les différents plans',
      redirect_stripe: 'Vous allez être redirigé vers Stripe.com',
      actual_subscription:
        "Vous avez actuellement l'abonnement <0>{0}</0> jusqu'au <1></1>.",
    },
    home: {
      // title: 'Bienvenue sur Wilfind.com, que recherchez-vous ?',
      title: {
        1: "Bienvenue, comment puis-je vous aider aujourd'hui ?",
        2: 'Bienvenue sur Wilfind.com, quel produit recherchez-vous ?',
        3: "Bienvenue sur Wilfind.com, qu'est-ce qui vous intéresse ?",
        4: 'Bienvenue sur Wilfind.com, que recherchez-vous ?',
        5: "Chercher, c'est bien, trouver, c'est mieux.",
        6: 'Vous cherchez un smartphone ?',
        7: 'Vous cherchez un ordinateur ?',
        8: 'Vous cherchez une tablette ?',
        9: "Que recherchez-vous aujourd'hui ?",
        10: 'Bonjour, je suis Wilbot, votre assistant de recherche.',
        11: 'Bonjour, je suis votre assistant de recherche, Wilbot.',
        12: 'Trouvez ce dont vous avez besoin sur Wilfind.com.',
      },
      discover: 'Découvrez le futur du e-commerce avec ',
      description: 'Trouver le produit idéal pour vous.',
      try_wilbot: 'Essayez Wilbot',
      try_wilbot_details: 'Essayer gratuitement (1 / jours)',
      support: 'Besoin d’aide, contactez-nous.',
      support_link: 'Support',
      account: 'Déjà client, identifiez-vous.',
      account_link: 'Se connecter',
      new_macbook_pro: 'Nouveau Macbook<0></0><1>PRO</1><2>Acheter ></2>',
      buy_now: 'acheter',
      text_1: '<0>Wilbot</0>, recherche parmi <1>des milliers de produits.</1>',
      text_2: 'Trouvez le <0>produit idéal</0> pour vous <1>facilement.</1>',
      text_3: 'Pub KickStart ICI ?',
      partners: 'Nos partenaires',
    },
    search: {
      placeholder: 'Rechercher un produit',
    },
    menu_pop_up: {
      description:
        "Grâce à Wilbot, vous trouverez le produit qui vous convenez le mieux. Essayez dès maintenant l'expérience Wilbot !",
      try_wilbot: 'Essayez Wilbot',
    },
    cart_pop_up: {
      title: 'Panier',
      empty: 'Votre panier est vide.',
      empty_description: 'Vous n’avez pas encore ajouté d’article.',
      view_cart: 'Voir le panier',
    },
    redirection_pop_up: {
      title: 'Autoriser les pop-ups',
      description:
        'Pour être redirigé vers les pages du marchand, vous devez autoriser les fenêtres pop-up dans votre navigateur web.',
    },
    comparator: {
      title: 'Comparateur',
      add: 'Ajouter',
      description: 'Comparez les produits pour faire le bon choix.',
      add_product: 'Ajouter un produit',
      by_default: 'Par défaut',
      performance: 'Performance',
      screen: 'Qualité d’écran',
      photo: 'Qualité photo',
      video: 'Qualité vidéo',
      gaming: 'Gaming',
      work: 'Travail',
      school: 'Études',
      transport: 'Transport',
      asc_price: 'Prix croissant',
      desc_price: 'Prix décroissant',
      error_min: 'Vous devez ajouter au moins 2 produits.',
      error_max: 'Vous pouvez ajouter au maximum 3 produits.',
      placeholder: 'Rechercher un produit à ajouter au comparateur',
      empty: 'Votre comparateur est vide.',
      suggestions: 'Suggestions',
    },
    price_chart: {
      title: 'Évolution du prix',
      week: 'Une semaine',
      month: 'Un mois',
      six_months: '6 mois',
      year: 'Un an',
      all: 'Depuis toujours',
    },
    cart: {
      title: 'Votre panier',
      description: 'Retrouvez vos articles ajoutés au panier.',
      only_fnac: 'Uniquement Fnac',
      only_darty: 'Uniquement Darty',
      empty: 'Votre panier est vide.',
      empty_description: 'Vous n’avez pas encore ajouté d’article.',
      error_redirect:
        'Vous devez accepter la redirection pour accéder aux liens.',
      redirect_prevention: "J'ai vérifié les marchands sélectionnés.",
    },
    favorites: {
      title: 'Vos favoris',
      description: 'Retrouvez vos produits favoris.',
      empty: "Vous n'avez pas encore de favoris.",
      empty_description:
        'Vous n’avez pas encore ajouté de produit à vos favoris.',
    },
    product: {
      reparability_title: 'INDICE DE RÉPARABILITÉ',
      merchant_selection: 'Sélection du marchand :',
      unit: 'Unité',
      compare: 'Comparer',
      not_compare: 'Ne pas comparer',
      see_the_product: 'Voir le produit',
      not_found: 'Produit non trouvé',
      available: 'Disponible',
      unavailable: 'Indisponible',
      tags: {
        cheapest: 'Le moins cher',
        mostEfficient: 'Le plus performant',
        bestReviews: 'Le mieux noté',
        bestSeller: 'Le plus vendu',
        new: 'Nouveauté',
        best_price: 'Meilleur prix',
      },
      photo: {
        title: 'Photo',
        captor: 'Capteur de',
        with_pixel_size: "avec des pixels d'une taille de",
        photo_lens: {
          'Grand Angle': 'Grand Angle',
          'Ultra Grand Angle': 'Ultra Grand Angle',
          Périscope: 'Périscope',
          'Télé-objectif': 'Télé-objectif',
          Depth: 'Depth',
          Macro: 'Macro',
          Selfie: 'Selfie',
          'Selfie 2': 'Selfie 2',
        },
      },
      features_title: {
        Global: 'Général',
        Performance: 'Performance',
        Stockage: 'Stockage',
        Écran: 'Écran',
        Photo: 'Photo',
        Vidéo: 'Vidéo',
        Autre: 'Autre',
      },
      features: {
        true: 'Oui',
        false: 'Non',
        Os: 'Os',
        performance: 'Performance',
        photo: 'Photo',
        video: 'Vidéo',
        photo_video: 'Photo & Vidéo',
        screen: 'Écran',
        total: 'Total',
        "Taille d'écran": "Taille d'écran",
        "Dalle d'écran": "Dalle d'écran",
        "Densité de pixels sur l'écran": "Densité de pixels sur l'écran",
        "Définition de l'écran": "Définition de l'écran",
        "Taux de rafraîchissement de l'écran":
          "Taux de rafraîchissement de l'écran",
        "Taux de rafraîchissement de l'écran adaptatif":
          "Taux de rafraîchissement de l'écran adaptatif",
        "Luminosité maximal de l'écran": "Luminosité maximal de l'écran",
        Processeur: 'Processeur',
        'Processeur graphique': 'Processeur graphique',
        RAM: 'RAM',
        'Définition vidéo': 'Définition vidéo',
        'Fréquence maximal vidéo': 'Fréquence maximal vidéo',
        'HDR écran': 'HDR écran',
        'HDR caméra': 'HDR caméra',
        'Dolby Vision caméra': 'Dolby Vision caméra',
        'ProRES caméra': 'ProRES caméra',
        "Temps de réponse de l'écran": "Temps de réponse de l'écran",
        'Always On Display': 'Always On Display',
        Couleur: 'Couleur',
        'RAW Caméra': 'RAW Caméra',
        Stockage: 'Stockage',
        Masse: 'Masse',
        Dimensions: 'Dimensions',
        'Indice DAS Tronc': 'Indice DAS Tronc',
        'Indice DAS Tête': 'Indice DAS Tête',
        'Indice DAS membre': 'Indice DAS membre',
        'Note de réparabilité': 'Note de réparabilité',
        'Autres informations': 'Autres informations',
        Sécurité: 'Sécurité',
        Capteurs: 'Capteurs',
        Connectivité: 'Connectivité',
        Connectiques: 'Connectiques',
        'Définition photo': 'Définition photo',
        'Regroupement des pixels': 'Regroupement des pixels',
        'Taille des pixels': 'Taille des pixels',
        'Taille focal': 'Taille focal',
        'Ouverture du diaphragme': 'Ouverture du diaphragme',
        Stabilisation: 'Stabilisation',
        'Écran tactile': 'Écran tactile',
        'Carte graphique': 'Carte graphique',
        'Type de stockage': 'Type de stockage',
        Webcam: 'Webcam',
        'Zoom Maximal': 'Zoom Maximal',
        'True Tone': 'True Tone',
      },
      colors: {
        Graphite: 'Graphite',
        Bleu: 'Bleu',
        Noir: 'Noir',
        Crème: 'Crème',
        Argent: 'Argent',
        'Gris sidéral': 'Gris sidéral',
        Rouge: 'Rouge',
        'Noir sidéral': 'Noir sidéral',
        'Lumière Stellaire': 'Lumière Stellaire',
        'Violet intense': 'Violet intense',
        Or: 'Or',
        Gris: 'Gris',
        Lavande: 'Lavande',
        Ivoire: 'Ivoire',
        'Lumière stellaire': 'Lumière stellaire',
        Mauve: 'Mauve',
        Minuit: 'Minuit',
        Violet: 'Violet',
        Vert: 'Vert',
        'Noir Volcanique': 'Noir Volcanique',
        'Vert Citron': 'Vert Citron',
        'Vert Sauge': 'Vert Sauge',
        'Blanc Tempête': 'Blanc Tempête',
        Menthe: 'Menthe',
        'Blanc Neige': 'Blanc Neige',
        Neige: 'Neige',
        'Noir Fantôme': 'Noir Fantôme',
        'Rouge Coucher de Soleil': 'Rouge Coucher de Soleil',
        'Blanc Clair de Lune': 'Blanc Clair de Lune',
        Beige: 'Beige',
        Blanc: 'Blanc',
        'Noir Minuit': 'Noir Minuit',
        'Gris Sidéral': 'Gris Sidéral',
        'Noir Glacé': 'Noir Glacé',
        'Or rose': 'Or rose',
        'Blanc Givré': 'Blanc Givré',
        'Arc-en-Ciel': 'Arc-en-Ciel',
        'Bleu étoilé': 'Bleu étoilé',
        'Vert Glacé': 'Vert Glacé',
        'Gris ardoise': 'Gris ardoise',
        'Bleu nuit': 'Bleu nuit',
        'Argent naturel': 'Argent naturel',
        'Bleu espace': 'Bleu espace',
        Platine: 'Platine',
        'Blanc lunaire': 'Blanc lunaire',
        Anthracite: 'Anthracite',
        'Bleu Glacier': 'Bleu Glacier',
        'Gris fer': 'Gris fer',
        Sable: 'Sable',
        'Vert Sauge ': 'Vert Sauge ',
        'Argent platine': 'Argent platine',
        'Gris arctique': 'Gris arctique',
        'Gris graphite': 'Gris graphite',
        'Gris onyx': 'Gris onyx',
        Startblue: 'Startblue',
        'Gris platine': 'Gris platine',
        'Gris lunaire': 'Gris lunaire',
        'Gris acier': 'Gris acier',
        Jaune: 'Jaune',
        'Noir et argent': 'Noir et argent',
        'Blanc et noir': 'Blanc et noir',
        'Noir avec insert Or': 'Noir avec insert Or',
        'Gris et blanc': 'Gris et blanc',
        'Argent métallique': 'Argent métallique',
        Rose: 'Rose',
        Orange: 'Orange',
        'Noir étoile': 'Noir étoile',
        'Noir et bleu': 'Noir et bleu',
        'Gris anthracite': 'Gris anthracite',
        'Bleu caméléon': 'Bleu caméléon',
        'Gris minéral': 'Gris minéral',
        'Noir corbeau': 'Noir corbeau',
        'Métal argenté mica': 'Métal argenté mica',
        'Bronze Mystique': 'Bronze Mystique',
        'Gris sidéra': 'Gris sidéra',
        'Natural Titanium': 'Natural Titanium',
        Pêche: 'Pêche',
        Argenté: 'Argenté',
        Porcelaine: 'Porcelaine',
      },
    },
    most_viewed: {
      title: 'Les plus regardés',
    },
    profile_pop_up: {
      title: 'Bonjour, bienvenue sur Wilfind',
      description:
        "Chez Wilfind, nous nous engageons à fournir une expérience d'achat en ligne inégalée pour les produits de haute technologie. Notre plateforme offre une large sélection d'articles de qualité provenant des marques les plus réputées du marché.",
      support_contact:
        "Pour tout problème de connexion ou d'inscription, contactez le support.",
      support_link: 'Support',
    },
    login: {
      title: 'Connexion',
      description:
        "Connectez-vous pour accéder à votre compte personnel et profiter pleinement de l'expérience Wilbot.",
      no_account: 'Pas encore de compte ?',
      register: 'S’inscrire',
      two_factor_title: 'Authentification à deux facteurs',
      two_factor_description:
        "Veuillez saisir votre code d'authentification à deux facteurs pour vous connecter.",
      with_google: 'Continuer avec Google',
      success: 'Connexion réussie, vous allez être redirigé...',
      alert:
        'En vous connectant, vous acceptez nos <0>conditions générales</0> et notre <1>politique de confidentialité.</1>',
    },
    register: {
      title: 'Inscription',
      description:
        "Inscrivez-vous pour pouvoir accéder à Wilbot gratuitement ainsi qu'à toutes les fonctionnalités de Wilfind.",
      already_account: 'Déjà un compte ?',
      login: 'Se connecter',
      with_google: 'Continuer avec Google',
      validation_title: 'Valider votre compte par email',
      validation_description:
        'Nous vous avons envoyé un email avec code de validation. Veuillez le saisir ci-dessous.',
      register_success: 'Inscription réussie, vous allez être redirigé...',
      continue_register: 'Continuer l’inscription',
      alert:
        'En vous inscrivant, vous acceptez nos <0>conditions générales</0> et notre <1>politique de confidentialité.</1>',
    },
    input: {
      name: 'Nom',
      name_placeholder: 'Entrez votre nom',
      firstname: 'Prénom',
      firstname_placeholder: 'Entrez votre prénom',
      email: 'Email',
      email_placeholder: 'Entrez votre email',
      actual_password: 'Mot de passe actuel',
      actual_password_placeholder: 'Entrez votre mot de passe actuel',
      password: 'Mot de passe',
      password_placeholder: 'Entrez votre mot de passe',
      verify_password: 'Vérification mot de passe',
      verify_password_placeholder: 'Confirmez votre mot de passe',
      two_factor: 'Code d’authentification à deux facteurs',
      two_factor_placeholder: 'Entrez votre code d’authentification',
      verification_code: 'Code de vérification',
      verification_code_placeholder: 'Entrez votre code de vérification',
      new_password: 'Nouveau mot de passe',
      new_password_placeholder: 'Entrez votre nouveau mot de passe',
      new_email: 'Nouvel email',
      new_email_placeholder: 'Entrez votre nouvel email',
      type_of_problem: 'Type de problème',
      your_problem: 'Votre problème',
      your_problem_placeholder: 'Entrez votre problème',
      search_placeholder: 'Rechercher',
    },
    pass_checklist: {
      min_length: 'Au moins 8 caractères',
      special_char: 'Au moins un caractère spécial',
      number: 'Au moins un chiffre',
      capital: 'Au moins une majuscule',
      match: 'Les mots de passe doivent correspondre',
    },
    reset_password: {
      title: 'Réinitialisation du mot de passe',
      description:
        "Veuillez saisir l'adresse email associée à votre compte pour réinitialiser votre mot de passe.<0 /><1/> Un lien de réinitialisation vous sera envoyé par courriel.<2 /><3/> Veillez à vérifier votre boîte de réception et votre dossier de courrier indésirable.<4/><5/> Nous vous remercions de votre confiance et sommes là pour vous aider tout au long du processus. Votre sécurité est notre priorité absolue.",
      success:
        'Un email de réinitialisation vous a été envoyé si votre compte existe.',
    },
    password_reset: {
      title: 'Réinitialisation du mot de passe',
      description:
        "Veuillez saisir votre nouveau mot de passe ci-dessous. Veillez à choisir un mot de passe sécurisé. Une fois que vous aurez confirmé votre nouveau mot de passe, vous pourrez accéder à votre compte en toute sécurité. Si vous avez besoin d'aide, n'hésitez pas à nous contacter. Nous sommes là pour vous aider à protéger votre compte.",
      success:
        'Votre mot de passe a bien été modifié, vous allez être redirigé vers la page de connexion.',
    },
    brand_footer: {
      title: 'Marques',
      description_1:
        "Wilfind, la première plateforme de shopping en ligne intégrant une intelligence artificielle (IA) innovante, réinvente la recherche du produit parfait en combinant l'expertise de la technologie et la puissance de l'IA. Profitez d'une expérience personnalisée, une expérience qui vous guide vers les choix les plus pertinents en fonction de vos selon vos besoins et préférences.",
      description_2:
        "Rendez votre recherche efficace et agréable grâce à notre IA avancée qui vous présente des recommandations personnalisées en un instant. Plongez dès aujourd'hui dans cette nouvelle ère du shopping en ligne. Faites confiance à Wilfind et vous trouverez ce que vous cherchez.",
    },
    footer: {
      description_1:
        "Wilfind propose une sélection variée de produits de qualité provenant des marques les plus réputées du marché (Apple, Asus, Samsung, Sony...). Ces produits sont vendus par nos partenaires tels qu'Amazon, Fnac et Darty, qui nous aident à offrir une expérience d'achat en ligne fiable et complète.",
      description_2:
        "Utilisez le comparateur de produits pour faciliter votre processus de décision. Comparez les spécifications, les tarifs et les évaluations des différents marchands disponibles sur notre plateforme. Vous pourrez ainsi sélectionner l'option qui répond le mieux à vos attentes et votre budget.",
      description_3:
        "Pour une expérience d'achat encore plus personnalisée, profitez de notre IA (WilBot) conçue pour analyser vos préférences et vos besoins. Elle vous présente des suggestions sur mesure pour vous guider vers le produit idéal, vous faisant ainsi gagner un temps précieux et vous offrant une expérience d'achat unique.",
      description_4:
        "Wilfind est affilié à des partenaires de confiance tels qu'Amazon, Fnac et Darty, qui contribuent à notre rentabilité. Cela signifie que lorsque vous achetez un produit chez l'un de ces partenaires via notre plateforme, nous recevons une commission sans que cela n'affecte le prix que vous payez.",
      who_are_we: 'Qui sommes-nous ?',
      about: 'À propos de Wilfind',
      legal_notice: 'Mentions légales',
      privacy_policy: 'Politique de confidentialité',
      CGS: 'CGV',
      account: 'Compte',
      favorites: 'Favoris',
      cart: 'Panier',
      my_account: 'Mon compte',
      security: 'Sécurité',
      data: 'Données',
      services: 'Services',
      support: 'Support & Aide',
      FAQ: 'FAQ',
      affiliation: 'Affiliation',
      cookies_policy: 'Politique de cookies',
      terms_conditions: 'Conditions générales',
    },
    bottom_footer: {
      all_rights_reserved: 'Tous droits réservés',
      legal_notice: 'Mentions légales',
      privacy_policy: 'Politique de confidentialité',
    },
    account_menu: {
      informations: 'Informations',
      subscription: 'Abonnement',
      security: 'Sécurité',
      history: 'Historique',
      agreements: 'Accords',
      data: 'Données',
      support: 'Support',
    },
    personal_data: {
      title: 'Vos informations',
      success: 'Vos informations ont été mises à jour.',
    },
    security: {
      title: 'Votre sécurité',
      change_password: 'Changer de mot de passe',
      change_email: "Changer d'adresse email",
    },
    data_usage: {
      title: 'Vos données',
      delete_account: 'Supprimer mon compte',
      delete_account_description:
        'Vous recevrez un email pour valider la suppression.',
    },
    two_factor: {
      title: 'Authentification à deux facteurs (2FA)',
      enable: 'Activer la 2FA',
      disable: 'Désactiver la 2FA',
      disable_description:
        'Vous recevrez un email pour valider la désactivation.',
      success_disable: '2FA désactivée.',
      enable_description: "Vous recevrez un email pour valider l'activation.",
      success: '2FA activée.',
      scan_qr_code:
        "Scannez ce QR Code avec votre application d'authentification :",
      code: 'Code',
      modal_title: 'Code Double Authentification (2FA)',
      manually: 'Ou entrez ce code manuellement :',
      email_confirmation: 'Code de confirmation par email',
    },
    legal_notice: {
      title: 'Mentions légales',
      privacy_policy: 'Politique de confidentialité',
      CGS: 'CGV',
    },
    filter_menu: {
      unit: {
        gb: 'Go',
        inch: 'pouces',
      },
      result: 'Résultats pour',
      filters: {
        Marque: 'Marque',
        Catégorie: 'Catégorie',
        Couleur: 'Couleur',
        Stockage: 'Stockage',
        "Taille d'écran": "Taille d'écran",
      },
      price: {
        title: 'Prix',
        minus_150: 'Moins de 150€',
        from_150_to_300: 'De 150€ à 300€',
        from_300_to_500: 'De 300€ à 500€',
        from_500_to_800: 'De 500€ à 800€',
        from_800_to_1000: 'De 800€ à 1000€',
        more_1000: 'Plus de 1000€',
        to: 'à',
      },
      merchants: 'Marchands',
    },
    search_filter: {
      by_default: 'Par défaut',
      price_decreasing: 'Prix décroissant',
      price_increasing: 'Prix croissant',
      news: 'Nouveautés',
    },
    pagination: {
      go_to_page: 'Aller à la page',
      results_on: 'résultats sur',
    },
    product_page: {
      similar_products: 'Produits similaires',
      description: 'Description',
      features: 'Caractéristiques',
      about: 'À propos',
      description_product: 'Description du produit',
      features_product: 'Caractéristiques du produit',
      trust_arguments: {
        secure_payment: 'Paiement sécurisé',
        rgpd: 'Conforme au RGPD',
        secure_redirection: 'Redirection sécurisée',
      },
    },
    reviews: {
      title: 'Avis',
    },
    support: {
      title: 'Support',
      contact_form_title: 'Formulaire de contact',
      contact_form_description:
        'Remplissez le formulaire ci-dessous pour nous contacter.',
      other_contact_methods_title: 'Autres moyens de contact',
      other_contact_methods_description:
        'Vous pouvez également nous contacter via nos réseaux sociaux ou par email.',
      contact_reason_1: 'Problème de connexion',
      contact_reason_2: 'Problème avec Wilbot',
      contact_reason_3: 'Problème avec le site',
      contact_reason_4: 'Autre',
      alert:
        'Vous acceptez de communiquer votre addresse email, nom et prénom pour le bien de votre demande.',
    },
    support_user: {
      title: 'Vos demandes de support',
      new_request: 'Nouvelle demande',
      status: {
        pending: 'En attente',
        in_progress: 'En cours',
        closed: 'Résolu',
      },
      type_of_demand: 'Type de demande',
      description: 'Description',
      request: 'Demande',
      write_your_message: 'Écrivez votre message...',
    },
    about: {
      title: 'À propos de Wilfind',
      who_are_we: 'Qui sommes nous ?',
    },
    faq: {
      title: 'FAQ',
      what_is_wilfind: 'Qu’est-ce que Wilfind ?',
      what_is_wilfind_description:
        "Wilfind, la première plateforme d'achat en ligne intégrant une intelligence artificielle (IA) innovante, réinvente la recherche du produit idéal en combinant l'expertise de spécialistes en technologie et la puissance de l'IA. Appréciez une expérience personnalisée, fluide et agréable, qui vous oriente vers les choix les plus pertinents selon vos besoins et préférences. Optez pour une recherche efficace et plaisante grâce à notre IA avancée qui vous présente des recommandations sur-mesure en un instant. Plongez dès aujourd'hui dans cette nouvelle ère du shopping en ligne. Faites confiance à Wilfind et vous trouverez ce que vous cherchez.",
      what_is_wilbot: "Qu'est-ce que Wilbot ?",
      what_is_wilbot_description:
        'Wilbot est notre Chatbot avec intelligence artificielle (IA) qui a pour but de vous aider à trouver le produit idéal. Il vous suffit de lui poser une question et il continuera la conversation avec vous pour vous aider à trouver le produit idéal. Au fil de la conversation Wilbot vous proposera des produits correspondant à vos besoins avant de vous proposer les produits finaux.',
      what_is_wilbot_description_2:
        "Wilbot combine de l'intelligence artificielle (IA) et de l'expertise technologique pour trouver le produit idéal, cette technologique continue d'évoluer au fil du temps.",
      how_to_access: 'Comment accéder à Wilbot ?',
      how_to_access_description:
        "Pour accéder à Wilbot, il vous suffit de vous inscrire où vous connecter sur Wilfind, puis de vous rendre sur la page de Wilbot, nous limitons l'utilisation aux utilisateurs avec un compte pour éviter de surcharger nos serveurs et donc de pouvoir proposer un service de qualité.",
      limitations: 'Limitations',
    },
    affiliation: {
      title: 'Affiliation',
      what_is_affiliation: "Qu'est-ce que l'affiliation ?",
      what_is_affiliation_description:
        "C'est un partenariat commercial dans lequel nous nous associons à d'autres sites web, qui proposent des produits complémentaires ou similaires aux nôtres.",
      what_is_affiliation_description_2:
        "Lorsque vous achetez un produit via notre site web, il se peut que nous recevions une commission pour cette vente. Cependant, soyez assuré que cela n'entraîne aucun coût supplémentaire pour vous.",
      what_is_affiliation_description_3:
        "L'affiliation est simplement une façon pour nous de vous proposer une plus grande variété de produits de qualité et de continuer à vous offrir un service exceptionnel.",
      how_affiliation_works: "Comment fonctionne l'affiliation ?",
      how_affiliation_works_description:
        'Lorsque vous cliquez sur un produit affilié sur notre site web, vous serez redirigé vers le site partenaire où vous pourrez effectuer votre achat en toute sécurité.',
      how_affiliation_works_description_2:
        'Les sites partenaires sont soigneusement sélectionnés pour leur fiabilité, leur qualité de service et la pertinence de leurs produits. Lorsque vous effectuez un achat, le site partenaire nous informe de cette transaction et nous accordent une commission sur la vente.',
      how_affiliation_works_description_3:
        "C'est grâce à cette commission que nous pouvons continuer à vous proposer une expérience d'achat exceptionnelle, en élargissant constamment notre gamme de produits.",
      how_affiliation_works_description_4:
        "Nous comprenons que la confiance est primordiale dans le processus d'achat en ligne. C'est pourquoi nous nous engageons à ne collaborer qu'avec des sites partenaires réputés et fiables, afin de garantir que votre expérience d'achat reste transparente, sécurisée et agréable.",
      why_affiliation: "Pourquoi l'affiliation ?",
      why_affiliation_description:
        "Chez Wilfind, nous croyons en la puissance de la collaboration pour offrir à nos clients une expérience d'achat exceptionnelle.",
      why_affiliation_description_2:
        "C'est pourquoi nous avons mis en place un programme d'affiliation qui vous permet de profiter d'une sélection encore plus large de produits exceptionnels provenant de sites partenaires de confiance.",
      why_affiliation_description_3:
        "Nous vous invitons à explorer notre catalogue de produits et à découvrir les opportunités offertes par notre programme d'affiliation. Vous trouverez une variété de produits de qualité provenant de différents sites partenaires, soigneusement sélectionnés pour répondre à vos besoins et à vos goûts.",
      why_affiliation_description_4:
        "N'hésitez pas à nous contacter si vous avez des questions ou des préoccupations, notre équipe se fera un plaisir de vous aider.",
    },
    history: {
      title: 'Historique',
      empty: 'Votre historique est vide.',
      empty_description: 'Vous n’avez pas encore effectué de recherche.',
      product: 'Produit',
      search: 'Recherche',
      wilbot: 'Wilbot',
      elements_number: 'Nombre d’éléments',
      i_want_to_delete: 'Je veux supprimer :',
    },
    wilbot: {
      category: {
        title: 'Catégorie',
        smartphone: 'Smartphone',
        laptop: 'Ordinateur portable',
        tablet: 'Tablette',
        desktop: 'Ordinateur de bureau',
        'all-in-one': 'Ordinateur tout-en-un',
      },
      suggested_response: {
        smartphone: 'Smartphone',
        laptop: 'Ordinateur portable',
        tablet: 'Tablette',
        desktop: 'Ordinateur de bureau',
      },
      loading_chat_response: {
        0: 'Recherche des meilleurs produits ',
        1: 'Recherche des meilleurs produits .',
        2: 'Recherche des meilleurs produits ..',
        3: 'Recherche des meilleurs produits ...',
      },
      loading_product_chat_response: {
        0: 'Recherche des informations ',
        1: 'Recherche des informations .',
        2: 'Recherche des informations ..',
        3: 'Recherche des informations ...',
      },
      reset_date: 'Cette limite sera réinitialisée le : ',
      subscription_required:
        'Vous avez atteint votre <0>limite</0> de questions journalières, pour augmenter cette limite vous pouvez voir les différentes <1>offres ici.</1>',
      product_subscription_required:
        'Pour utiliser la fonctionnalité <0>Question +</0>, vous devez être abonné, vous pouvez voir les différentes <1>offres ici.</1>',
      default_response:
        '<0>Bienvenue sur Wilbot,</0> vous pouvez commencer par me donner <1>la catégorie</1> de produit que vous souhaitez rechercher.',
      // default_response: {
      //   smartphone:
      //     '<0>Très bien,</0> vous pouvez commencer par exemple par me donner <1>les usages</1> que vous souhaitez faire avec votre <2>smartphone.</2>',
      //   tablet:
      //     '<0>Très bien,</0> vous pouvez commencer par exemple par me donner <1>les usages</1> que vous souhaitez faire avec votre <2>tablette.</2>',
      //   laptop:
      //     '<0>Très bien,</0> vous pouvez commencer par exemple par me donner <1>les usages</1> que vous souhaitez faire avec votre <2>ordinateur portable.</2>',
      //   desktop:
      //     '<0>Très bien,</0> vous pouvez commencer par exemple par me donner <1>les usages</1> que vous souhaitez faire avec votre <2>unité centrale.</2>',
      //   'all-in-one':
      //     '<0>Très bien,</0> vous pouvez commencer par exemple par me donner <1>les usages</1> que vous souhaitez faire avec votre <2>ordinateur tout en un.</2>',
      // },
      on: 'sur',
      best_product_for_you: 'Meilleur produit pour vous !',
      chat_with_expert: 'Discuter avec un expert',
      new_discussion: 'Nouvelle discussion',
      product_question:
        "Lorsqu'un produit est sélectionné, vous pouvez poser des questions sur ce produit, ensuite le fil de la conversation reviendra à la normale.",
      welcome: 'Bonjour, bienvenue sur Wilfind. Que recherchez-vous ?',
      guide: 'Guide',
      no_product_found:
        "Désoler je n'ai pas trouver de produit correspondant a vos besoins, essayer de modifier vos critères de recherche",
      no_category_found:
        "Désoler je n'ai pas trouver de catégorie correspondant a vos besoins, essayer de modifier vos critères de recherche",
      chat_error: 'Une erreur est survenue, veuillez réessayer.',
      dislike_message_success: 'Merci pour votre retour.',
      like_message_success: 'Merci pour votre retour.',
      dislike_message_error: 'Une erreur est survenue.',
      dislike_placeholder: 'Pourquoi cette réponse ne vous convient pas ?',
      dislike_title: 'Donnez-nous votre avis.',
      like_title: 'Donnez-nous votre avis.',
      like_placeholder: 'Vouliez-vous préciser quelque chose ?',
      write_your_message: 'Écrivez votre message...',
      data_secure: 'Vos données personnelles sont protégées.',
      suggestions: 'Suggestions',
      product_find: 'Produit trouvé',
      product_right_title: 'Résultat en fonction de vos réponses :',
      product_right_subtitle: 'Trouver le produit idéal pour vous.',
      product_right_not_found: 'Aucun résultat',
      redirect_message: ', une fois le produit cliqué vous serez redirigé.',
      view_on_wilfind: 'Voir sur Wilfind',
      final_product:
        'Je vous conseil ce produit, si vous avez d’autres questions, n’hésitez pas !',
      final_products:
        'Je vous conseil ces produit, si vous avez d’autres questions, n’hésitez pas !',
      warning_message:
        'Wilbot est en développement, il peut afficher des informations inexacte ou fausse.',
      missing_filters: {
        title:
          "Malheursement, je n'ai pas trouvé de produit correspondant exactement à votre recherche, mais j'ai trouvé ces produits :",
        smaller_screen: 'Écran plus petit',
        bigger_screen: 'Écran plus grand',
        smaller_storage: 'Moins de stockage',
        bigger_storage: 'Plus de stockage',
        smaller_price: 'Moins cher',
        bigger_price: 'Plus cher',
        different_color: 'Couleur différente',
        foldable_screen: 'Écran pliable',
        not_foldable_screen: 'Écran non pliable',
      },
      not_same_filter: {
        screen_size_plus: 'Écran plus grand',
        screen_size_minus: 'Écran plus petit',
        screen_size: 'Pas la même taille d’écran',
        storage_plus: 'Plus de stockage',
        storage_minus: 'Moins de stockage',
        storage: 'Pas le même stockage',
        price_plus: 'Plus cher',
        price_minus: 'Moins cher',
        price: 'Pas le même prix',
        color: 'Pas la même couleur',
        brand: 'Pas la même marque',
        extra_filter: 'Manque certaines informations supplémentaires',
      },
      exemples: {
        0: '<0>Je cherche un nouveau smartphone</0> pour pouvoir faire du gaming.',
        1: '<0>Je veux acheter un telephone</0> pour prendre des photos et faire des vidéos.',
        2: '<0>Telephone</0> avec le quel je peux faire de la musique.',
        3: '<0>Trouve moi un smartphone</0> pour un budget de 500€.',
      },
      history: {
        history: 'Historique',
        title: 'Historique des conversations :',
        description: 'Retrouvez vos anciennes conversations.',
        filters: {
          dateAsc: 'Les plus récentes',
          dateDesc: 'Les plus anciennes',
        },
        chats: 'Chats',
        liked_chats: 'Chats aimés',
        empty: 'Votre historique est vide.',
      },
      steps: {
        first: {
          title: 'Conversation',
          description:
            'Votre conversation avec Wilbot, il vous posera des questions pour vous aider à trouver le produit parfait.',
        },
        second: {
          title: 'Produits suggérés',
          description:
            'La liste des produits suggérés par Wilbot en fonction de vos réponses.',
        },
        third: {
          title: 'Informations sur le produit',
          description:
            'Si vous cliquez sur un produit, vous aurez plus d’informations sur celui-ci et vous pourrez poser des questions à Wilbot à propos de ce produit.',
        },
        fourth: {
          title: 'Historique',
          description:
            'Vous pouvez accéder à votre historique de conversation et les continuer.',
        },
      },
    },
    manage_cookies: {
      title: 'Gestion des cookies',
    },
    cookies: {
      continue_without_accepting: 'Continuer sans accepter',
      policy: 'Politique cookies',
      description:
        "<0>Wilfind</0> utilise des cookies sur ce site à des fins de bon fonctionnement du site, de sécurité, de mesure d'audience, d'analyse, d'amélioration de votre expérience utilisateur ainsi qu'à des fins publicitaires.<1></1><2></2>Votre consentement à l'installation de cookies non strictement nécessaires est libre et peut être modifié à tout moment.<3></3><3></3>Vous pouvez donner ou retirer votre consentement globalement ou paramétrer vos préfèrences par finalité de cookies.",
      manage_description:
        'Vous pouvez donner ou retirer votre consentement soit globalement soit par finalité de cookies.<0></0>Vous devez accepter ou refuser les cookies de chaque catégorie, puis sauvegarder vos choix détaillés.<1></1><2></2>Si votre navigateur est configuré de manière à refuser l’ensemble des cookies, vous ne pourrez pas profiter de fonctions essentielles de notre site, comme par exemple l’enregistrement de vos préférences de connexion pour les sessions nécessitant une identification.',
      essential:
        "<0>Essentiel :</0> Les cookies nécessaires à la personnalisation et au fonctionnement du site internet : ils vous permettent d'utiliser les principales fonctionnalités du Site et notamment d’enregistrer des informations entre deux consultations du site sur un même appareil, d’enregistrer, des identifiants de connexion de session ou encore des éléments de personnalisation de l’interface (choix de la langue ou présentation). Ils ne requièrent pas votre consentement préalable. Ces cookies sont indispensables au bon fonctionnement du Site. Si vous refusez l’enregistrement de ces cookies dans votre terminal ou son navigateur, ou si vous supprimez ceux qui y sont enregistrés, vous serez informé que votre navigation et votre expérience sur le Site peuvent être limitées.",
      analytics:
        "<0>Analytiques :</0> Les cookies analytiques, de statistique ou cookies de mesure d'audience du site internet qui nous permettent de connaître l'utilisation et les performances d'audience du site et d'en améliorer le fonctionnement pour nos visiteurs, par exemple, établir des statistiques et volumes de fréquentation et d'utilisation des divers éléments composant le site (rubriques et contenus visités, parcours), afin d'améliorer l'intérêt et l'ergonomie du site.",
      ads: '<0>Publicitaires : </0>: Les Cookies dits publicitaires qui nous permettent de choisir en temps réel quelle publicité afficher sur des sites tiers. Ces cookies ne sont déposés que lorsque vous y avez expressément consenti à travers le bandeau présenté lors de votre première connexion.',
      consent:
        'En cochant la case ci-dessous, vous consentez à la collecte, au traitement et au stockage de vos données personnelles conformément à notre politique de confidentialité.',
      privacy_policy: 'Politique de confidentialité',
      manage: 'Préférences',
      accept: 'Tout Accepter',
      decline: 'Tout Refuser',
      save: 'Sauvegarder',
      consult_policy:
        'Consulter la politique de cookies et de protection des données',
    },
    error: {
      subscription_required: "Vous n'avez pas l'abonnement requis.",
      missing_fields: 'Veuillez remplir tous les champs.',
      google_user: 'Impossible car vous êtes connecté avec Google.',
      missing_message: 'Veuillez écrire un message.',
      message_too_long: 'Votre message est trop long.',
      wilbot_limit_message:
        'Vous avez atteint la limite de messages journalière.',
      missing_category: 'Veuillez choisir une catégorie.',
      fill_all_fields: 'Veuillez remplir tous les champs.',
      password_not_secure: 'Votre mot de passe n’est pas assez sécurisé.',
      password_not_valid: 'Votre mot de passe est invalide.',
      name_length: 'Votre nom doit contenir entre 2 et 20 caractères.',
      firstname_length: 'Votre prénom doit contenir entre 2 et 20 caractères.',
      invalid_code: 'Code invalide.',
      product_not_found: 'Produit non trouvé.',
      captcha_error: 'Captcha invalide.',
      deleted_account: 'Votre compte a été supprimé.',
      invalid_credentials: 'Email / mot de passe incorrect.',
      too_many_attempts: 'Trop de tentatives, réessayez dans une heure.',
      email_not_valid: 'Votre email est invalide.',
      email_already_used: 'Votre email est déjà utilisé.',
      code_invalid_or_expired: 'Code invalide ou expiré',
      link_invalid_or_expired: 'Lien invalide ou expiré.',
      email_not_sent: 'Email non envoyé.',
      chat_not_found: 'Conversation introuvable.',
      chat_error: 'Une erreur est survenue dans la conversation.',
      default_error: 'Une erreur est survenue.',
      global: {
        server_error: 'Une erreur est survenue.',
        email_error: "Problème d'envoie email.",
        link_invalid_or_expired: 'Lien invalide ou expiré.',
        captcha_error: 'Captcha invalide.',
      },
    },
    loading: {
      loading_0: 'Chargement',
      loading_1: 'Chargement.',
      loading_2: 'Chargment..',
      loading_3: 'Chargement...',
    },
    delete_account: {
      success: 'Votre compte a bien été supprimé.',
    },
    change_password: {
      success: 'Votre mot de passe a bien été changé !',
      description:
        'Vous pouvez maintenant vous connecter avec votre nouveau mot de passe.',
    },
    change_email: {
      success: 'Votre email a bien été modifié.',
      description:
        'Vous pouvez maintenant vous connecter avec votre nouvelle adresse mail.',
    },
    verify_account: {
      success: 'Votre compte a bien été vérifié.',
      description:
        'Vous pouvez maintenant vous connecter et profiter pleinement de nos services.',
    },
    continue_pop_up: {
      title: 'Se connecter ou s’inscrire en quelques secondes',
      description:
        'Utilisez votre e-mail ou un autre service pour utiliser notre IA gratuitement !',
      thanks_register: 'Merci pour votre inscription !',
      verify_email: 'Vérifier votre adresse mail',
      verification_code: 'Un code de vérification vous a été envoyé par mail.',
      register_description:
        "S'inscrire en quelques secondes pour avoir accès à Wilfind",
    },
    beta: {
      title: 'Accès anticipé',
      description:
        "Rejoignez l'aventure <0>Wilfind</0> et accédez à la version <1>bêta de Wilbot.</1>",
      submit: 'Demander un accès',
      alert:
        'En renseignant votre adresse email, vous serez informé de la sortie de Wilbot et vous pourrez accéder à la version bêta.',
      presentation: {
        0: 'Découvrez <0>Wilbot</0>,',
        1: "trouvez votre produit grace à l'IA.✨",
        2: 'trouvez le produit parfait pour vous.',
        3: 'trouvez le produit parfait simplement.',
        4: 'trouvez le produit parfait rapidement.',
      },
      success: 'Merci pour votre demande ! Vous serez informé par email.',
    },
    subscriptionSuccess: {
      title: 'Merci pour votre abonnement !',
      description:
        "Vous pouvez maintenant profiter pleinement de l'expérience Wilfind.",
    },
    meta: {
      title: {
        home: 'Wilfind - Le premier AI Shop',
        wilbot: 'Wilbot - AI Shopping Assistant',
        comparator: 'Wilfind - Comparateur',
        login: 'Wilfind - Connexion',
        register: 'Wilfind - Inscription',
        favorites: 'Wilfind - Favoris',
        cart: 'Wilfind - Panier',
        history: 'Wilfind - Historique',
        terms_conditions: 'Wilfind - CGU & CGV',
        privacy_policy: 'Wilfind - Politique de confidentialité',
        cookies_policy: 'Wilfind - Politique de cookies',
        affiliate: 'Wilfind - Affiliation',
        about_us: 'Wilfind - À propos',
        help: 'Wilfind - Aide',
        faq: 'Wilfind - FAQ',
        manage_cookies: 'Wilfind - Gestion des cookies',
        subscriptions: 'Wilfind - Abonnements',
        reset_password: 'Wilfind - Réinitialisation du mot de passe',
        change_password: 'Wilfind - Changer de mot de passe',
        change_email: 'Wilfind - Changer d’adresse email',
        verify_account: 'Wilfind - Vérifier votre compte',
        delete_account: 'Wilfind - Supprimer votre compte',
        personal_data: 'Wilfind - Vos informations',
        security: 'Wilfind - Votre sécurité',
        data_usage: 'Wilfind - Vos données',
        two_factor: 'Wilfind - Authentification à deux facteurs',
        support: 'Wilfind - Support',
        manage_subscription: 'Wilfind - Gérer votre abonnement',
      },
      description: {
        global: {},
      },
    },
  },
};

export default fr;
