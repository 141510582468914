import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  comparePopUp: false,
  filterPopUp: false,
  favoriteFilterPopUp: false,
  productsFilterPopUp: false,
  menu: false,
  cart: false,
  profile: false,
  search: false,
  continue: false,
  cookie: false,
  priceChart: false,
};

const popUpSlice = createSlice({
  name: 'popUp',
  initialState,
  reducers: {
    toggleCookie(state, action) {
      state.cookie = !state.cookie;
    },
    toggleSearch(state, action) {
      state.search = !state.search;
      state.cart = false;
      state.menu = false;
      state.profile = false;
      state.continue = false;
      state.priceChart = false;
    },
    toggleCart(state, action) {
      state.cart = !state.cart;
      state.menu = false;
      state.profile = false;
      state.search = false;
      state.continue = false;
      state.priceChart = false;
    },
    toggleMenu(state, action) {
      state.menu = !state.menu;
      state.cart = false;
      state.profile = false;
      state.search = false;
      state.continue = false;
    },
    toggleProfile(state, action) {
      state.profile = !state.profile;
      state.cart = false;
      state.menu = false;
      state.search = false;
      state.continue = false;
      state.priceChart = false;
    },
    toggleContinue(state, action) {
      state.continue = !state.continue;
      state.cart = false;
      state.menu = false;
      state.search = false;
      state.profile = false;
      state.priceChart = false;
    },
    hideProfile(state, action) {
      state.profile = false;
    },
    showCompare(state, action) {
      state.comparePopUp = true;
    },
    hideCompare(state, action) {
      state.comparePopUp = false;
    },
    toggleFilter(state, action) {
      state.filterPopUp = !state.filterPopUp;
    },
    toggleFavoriteFilter(state, action) {
      state.favoriteFilterPopUp = !state.favoriteFilterPopUp;
    },
    toggleProductsFilter(state, action) {
      state.productsFilterPopUp = !state.productsFilterPopUp;
    },
    togglePriceChart(state, action) {
      state.priceChart = !state.priceChart;
    },
    hideProductsFilter(state, action) {
      state.productsFilterPopUp = false;
    },
    cleanPopUp(state, action) {
      state.comparePopUp = false;
      state.filterPopUp = false;
      state.favoriteFilterPopUp = false;
      state.productsFilterPopUp = false;
      state.menu = false;
      state.cart = false;
      state.profile = false;
      state.search = false;
      state.continue = false;
      state.priceChart = false;
    },
    switchProfileMenu(state, action) {
      state.profile = false;
      state.menu = true;
    },
  },
});

export const readPopUp = (state) => state.popUp;

export const {
  toggleCookie,
  showCompare,
  hideCompare,
  toggleFilter,
  toggleFavoriteFilter,
  toggleProductsFilter,
  hideProductsFilter,
  toggleCart,
  toggleMenu,
  toggleProfile,
  toggleSearch,
  toggleContinue,
  togglePriceChart,
  cleanPopUp,
  switchProfileMenu,
  hideProfile,
} = popUpSlice.actions;

export default popUpSlice.reducer;
