import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setTopMessage } from '../features/topMessage/topMessageSlice';
import {
  addCompare,
  readCompare,
  removeCompare,
} from '../features/compare/compareSlice';

function useAddCompare(product, compareIconRef) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const products = useSelector(readCompare);

  const handleAddCompare = async () => {
    if (compareIconRef.current) compareIconRef.current.play();
    setTimeout(() => {
      if (compareIconRef.current) compareIconRef.current.stop();
    }, 2000);
    if (products.length === 3) {
      dispatch(
        setTopMessage({
          value: t('comparator.error_max'),
          type: 'compare_limit',
        })
      );
    } else dispatch(addCompare(product));
  };

  return handleAddCompare;
}

function useRemoveCompare(product, compareIconRef) {
  const dispatch = useDispatch();

  const handleRemoveCompare = async () => {
    if (compareIconRef) {
      compareIconRef.current.play();
      setTimeout(() => {
        if (compareIconRef.current) compareIconRef.current.stop();
      }, 2000);
    }
    dispatch(removeCompare(product));
  };

  return handleRemoveCompare;
}

export { useRemoveCompare, useAddCompare };
