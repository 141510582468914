import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  Marque: [],
  Catégorie: [],
  Couleur: [],
  Stockage: [],
  "Taille d'écran": [],
};

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFilters(state, action) {
      // you receive a list of products your goal is to fill the state with the filters
      // you need to loop over the products and fill the state with the filters
      // const newState = {
      //   Marque: [],
      //   Catégorie: [],
      //   Couleur: [],
      //   Stockage: [],
      //   "Taille d'Écran": [],
      // };
      // action.payload.forEach((product) => {
      //   if (!newState.Marque.includes(product.Marque)) {
      //     newState.Marque.push(product.Marque);
      //   }
      //   if (!newState.Catégorie.includes(product.Catégorie)) {
      //     newState.Catégorie.push(product.Catégorie);
      //   }
      //   if (!newState.Couleur.includes(product.Spec.Couleur)) {
      //     newState.Couleur.push(product.Spec.Couleur);
      //   }
      //   if (!newState.Stockage.includes(product.Spec['Capacité de Stockage'])) {
      //     newState.Stockage.push(product.Spec['Capacité de Stockage']);
      //   }
      //   if (
      //     !newState["Taille d'Écran"].includes(product.Spec["Taille d'Écran"])
      //   ) {
      //     newState["Taille d'Écran"].push(product.Spec["Taille d'Écran"]);
      //   }
      // });
      return (state = action.payload);
    },
  },
});

export const readFilters = (state) => state.filters;

export const { setFilters } = filtersSlice.actions;

export default filtersSlice.reducer;
