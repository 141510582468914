import '../../styles/Footer/main-footer.scss';
import TwitterIcon from '../../Icons/TwitterIcon';
import YoutubeIcon from '../../Icons/YoutubeIcon';
import InstagramIcon from '../../Icons/InstagramIcon';
import RightArrowIcon from '../../Icons/RightArrowIcon';
import { useDispatch } from 'react-redux';
import { toggleMenu } from '../../features/popUp/popUpSlice';
import { setMenuCategory } from '../../features/menuCategory/menuCategorySlice';
import { useTranslation } from 'react-i18next';

export default function MainFooter() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleRedirect = (e) => {
    window.location.href = `/product-results?q=${e.target.innerText}`;
  };

  const handleOpenMenu = (e) => {
    e.preventDefault();
    if (e.target.id === 'all') dispatch(setMenuCategory('Smartphone'));
    else dispatch(setMenuCategory(e.target.id));
    dispatch(toggleMenu());
  };
  return (
    <div className="main-footer">
      <div className="wrapper">
        <div className="presenting">
          <img
            className="wilfind-logo"
            src="/img/wilfind.png"
            alt="Wilfind"
            height="20"
            width="75"
          />
          <div className="socials">
            <a
              href="https://twitter.com/wilfind_"
              target="_blank"
              rel="noreferrer"
              className="icon-wrapper"
              aria-label="Twitter Icon"
            >
              <TwitterIcon />
            </a>
            <a
              href="https://www.youtube.com/channel/UCItt1e2fTvh-SlMN7Mc28Ag"
              target="_blank"
              rel="noreferrer"
              className="icon-wrapper"
              aria-label="Youtube Icon"
            >
              <YoutubeIcon />
            </a>
            <a
              href="https://www.tiktok.com/@wilfind"
              target="_blank"
              rel="noreferrer"
              className="icon-wrapper"
              aria-label="Tiktok Icon"
            >
              <img
                src="/img/socials/tiktok.webp"
                alt="Tiktok"
                height="15"
                width="15"
              />
            </a>
            <a
              href="https://www.instagram.com/wilfind_/"
              target="_blank"
              rel="noreferrer"
              className="icon-wrapper"
              aria-label="Instagram Icon"
            >
              <InstagramIcon />
            </a>
            <a
              href="https://www.snapchat.com/add/wilfind.snap?share_id=NTI3NjQyNTItRkVBQy00NTMxLTg3NjEtQjlENzEzQzkyOTlG&locale=fr_FR&sid=f01d47d2e3c34c3299e8e001eca90085"
              target="_blank"
              rel="noreferrer"
              className="icon-wrapper"
              aria-label="Snapchat Icon"
            >
              <img
                src="/img/socials/snapchat.webp"
                alt="Snapchat"
                height="12"
                width="12"
              />
            </a>
          </div>
          <p>
            {t('footer.description_1')}
            <br />
            <br />
            {t('footer.description_2')}
            <br />
            <br />
            {t('footer.description_3')} <br />
            <br />
            {t('footer.description_4')}
          </p>
          <img
            className="stripe"
            src="/img/stripe_payment.webp"
            alt="Stripe"
            height="50"
            width="157"
          />
        </div>
        <div className="menu">
          <div className="menu-list-wrapper">
            <ul className="menu-list">
              <li>{t('global.products')}</li>
              <li onClick={handleRedirect}>Macbook</li>
              <li onClick={handleRedirect}>iPhone</li>
              <li onClick={handleRedirect}>Zenbook</li>
              <li onClick={handleRedirect}>iMac</li>
              <li onClick={handleRedirect}>Surface studio</li>
              <li onClick={handleRedirect}>Galaxy S23 Ultra</li>
              <li onClick={handleRedirect}>Galaxy Tab</li>
              <li onClick={handleRedirect}>Omen</li>
              <li onClick={handleRedirect}>Ipad mini</li>
              <li onClick={handleRedirect}>Yoga</li>
              <li onClick={handleRedirect}>Pixel 7</li>
              <li onClick={handleRedirect}>ROG</li>
              <li onClick={handleRedirect}>Predator</li>
              <li onClick={handleRedirect}>Inspiron</li>
              <li onClick={handleRedirect}>IdeaCentre</li>
              <li className="view-all" id="all" onClick={handleOpenMenu}>
                <p id="all">{t('global.view_more')}</p>
                <RightArrowIcon id="all" />
              </li>
            </ul>
          </div>
          <div className="menu-list-wrapper">
            <ul className="menu-list">
              <li>{t('global.category.title')}</li>
              <li>
                <a
                  href="/product-results/discover?q=Smartphone"
                  id="Smartphone"
                >
                  {t('global.category.Smartphone')}
                </a>
              </li>
              <li>
                <a
                  href="/product-results/discover?q=Ordinateur Portable"
                  id="Ordinateur Portable"
                >
                  {t('global.category.Ordinateur Portable')}
                </a>
              </li>
              <li>
                <a
                  href="/product-results/discover?q=Ordinateur Tout en Un"
                  id="Ordinateur Tout en Un"
                >
                  {t('global.category.Ordinateur Tout en Un')}
                </a>
              </li>
              <li>
                <a
                  href="/product-results/discover?q=Unité Centrale"
                  id="Unité Centrale"
                >
                  {t('global.category.Unité Centrale')}
                </a>
              </li>
              <li>
                <a href="/product-results/discover?q=Tablette" id="Tablette">
                  {t('global.category.Tablette')}
                </a>
              </li>
              <li className="view-all" id="all" onClick={handleOpenMenu}>
                <p id="all">{t('global.view_more')}</p>
                <RightArrowIcon id="all" />
              </li>
            </ul>
            <ul className="menu-list">
              <li>{t('footer.who_are_we')}</li>
              <li>
                <a href="/about-us">{t('footer.about')}</a>
              </li>
              <li>
                <a href="/cookies-policy">{t('footer.cookies_policy')}</a>
              </li>
              <li>
                <a href="/terms-conditions">{t('footer.terms_conditions')}</a>
              </li>
              <li>
                <a href="/privacy-policy">{t('footer.privacy_policy')}</a>
              </li>
            </ul>
          </div>
          <div className="menu-list-wrapper">
            <ul className="menu-list">
              <li>{t('footer.account')}</li>
              <li>
                <a href="/favorites">{t('footer.favorites')}</a>
              </li>
              <li>
                <a href="/cart">{t('footer.cart')}</a>
              </li>
              <li>
                <a href="/account">{t('footer.my_account')}</a>
              </li>
              <li>
                <a href="/account/security">{t('footer.security')}</a>
              </li>
              <li>
                <a href="/account/security">{t('footer.data')}</a>
              </li>
              <li>
                <a href="/manage-cookies">{t('global.manage_cookie')}</a>
              </li>
            </ul>
            <ul className="menu-list">
              <li>{t('footer.services')}</li>
              <li>
                <a href="/help">{t('footer.support')}</a>
              </li>
              <li>
                <a href="/faq">{t('footer.FAQ')}</a>
              </li>
              <li>
                <a href="/affiliate">{t('footer.affiliation')}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
