import { configureStore } from '@reduxjs/toolkit';
import favoritesReducer from '../features/favorites/favoritesSlice';
import queryReducer from '../features/query/querySlice';
import historyReducer from '../features/history/historySlice';
import cartReducer from '../features/cart/cartSlice';
import leftMenuReducer from '../features/leftMenu/leftMenuSlice';
import productReducer from '../features/product/productSlice';
import compareReducer from '../features/compare/compareSlice';
import popUpReducer from '../features/popUp/popUpSlice';
import productsReducer from '../features/products/productsSlice';
import authReducer from '../features/auth/authSlice';
import userReducer from '../features/users/userSlice';
import modeReducer from '../features/mode/modeSlice';
import chatsListReducer from '../features/chat/chatsListSlice';
import activeChatReducer from '../features/chat/activeChatSlice';
import menuCategoryReducer from '../features/menuCategory/menuCategorySlice';
import filtersReducer from '../features/filters/filtersSlice';
import languageReducer from '../features/language/languageSlice';
import topMessageReducer from '../features/topMessage/topMessageSlice';
import { apiSlice } from './api/apiSlice';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

export const store = configureStore({
  reducer: {
    favorites: favoritesReducer,
    query: queryReducer,
    history: historyReducer,
    cart: cartReducer,
    leftMenu: leftMenuReducer,
    product: productReducer,
    compare: compareReducer,
    popUp: popUpReducer,
    products: productsReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    user: userReducer,
    mode: modeReducer,
    chatsList: chatsListReducer,
    activeChat: activeChatReducer,
    menuCategory: menuCategoryReducer,
    filters: filtersReducer,
    language: languageReducer,
    topMessage: topMessageReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true,
});

setupListeners(store.dispatch);
