export default function ThinRightArrow() {
    return (
        <svg className="thin-right-arrow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_115_2070)">
                <path d="M9.99992 3.33333L9.05992 4.27333L12.1133 7.33333H1.33325V8.66666H12.1133L9.05325 11.7267L9.99992 12.6667L14.6666 8L9.99992 3.33333Z" fill="#007FF3" />
            </g>
            <defs>
                <clipPath id="clip0_115_2070">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}