import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const chatsListSlice = createSlice({
  name: 'chatsList',
  initialState,
  reducers: {
    setChatsList(state, action) {
      return action.payload;
    },
  },
});

export const readChatsList = (state) => state.chatsList;

export const { setChatsList } = chatsListSlice.actions;

export default chatsListSlice.reducer;
