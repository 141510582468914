import { createSlice } from '@reduxjs/toolkit';

const initialState = null;

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    addProduct(state, action) {
      return (state = action.payload);
    },
  },
});

export const readProduct = (state) => state.product;

export const { addProduct } = productSlice.actions;

export default productSlice.reducer;
