import '../../styles/Footer/brand-footer.scss';
import LeftArrowIcon from '../../Icons/LeftArrowIcon';
import RightArrowIcon from '../../Icons/RightArrowIcon';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { useTranslation } from 'react-i18next';

export default function BrandFooter() {
  const { t } = useTranslation();
  // TODO: Link the brand images to the brand page
  return (
    <div className="brand-footer">
      <div className="wrapper">
        <h4>{t('brand_footer.title')}</h4>
        <div className="nav-brand">
          <div className="left-arrow-wrapper brand-footer-left-navigation">
            <LeftArrowIcon />
          </div>
          <Swiper
            tag="nav"
            modules={[Navigation]}
            slidesPerView={2}
            loop={true}
            navigation={{
              prevEl: '.brand-footer-left-navigation',
              nextEl: '.brand-footer-right-navigation',
            }}
            breakpoints={{
              400: {
                slidesPerView: 3,
              },
              500: {
                slidesPerView: 4,
              },
              600: {
                slidesPerView: 5,
              },
              768: {
                slidesPerView: 6,
              },
              1024: {
                slidesPerView: 7,
              },
            }}
          >
            <SwiperSlide>
              <a href="https://apple.com" target="_blank" rel="noreferrer">
                <img
                  loading="lazy"
                  src="/img/brands/apple.webp"
                  alt="Apple"
                  height="20"
                  width="20"
                />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="https://lenovo.com" target="_blank" rel="noreferrer">
                <img
                  loading="lazy"
                  src="/img/brands/lenovo-1.webp"
                  alt="Lenovo"
                  height="20"
                  width="100"
                />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="https://acer.com" target="_blank" rel="noreferrer">
                <img
                  loading="lazy"
                  src="/img/brands/acer.webp"
                  alt="Acer"
                  height="20"
                  width="60"
                />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="https://oppo.com" target="_blank" rel="noreferrer">
                <img
                  loading="lazy"
                  src="/img/brands/oppo.webp"
                  alt="Oppo"
                  height="20"
                  width="70"
                />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="https://xiaomi.com" target="_blank" rel="noreferrer">
                <img
                  loading="lazy"
                  src="/img/brands/xiaomi.webp"
                  alt="Xiaomi"
                  height="20"
                  width="50"
                />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="https://samsung.com" target="_blank" rel="noreferrer">
                <img
                  loading="lazy"
                  src="/img/brands/samsung.webp"
                  alt="Samsung"
                  height="20"
                  width="70"
                />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="https://asus.com" target="_blank" rel="noreferrer">
                <img
                  loading="lazy"
                  src="/img/brands/asus-rog.webp"
                  alt="Asus Rog"
                  height="20"
                  width="70"
                />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="https://hp.com" target="_blank" rel="noreferrer">
                <img
                  loading="lazy"
                  src="/img/brands/hp.webp"
                  alt="Hp"
                  height="20"
                  width="20"
                />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="https://dell.com" target="_blank" rel="noreferrer">
                <img
                  loading="lazy"
                  src="/img/brands/dell.webp"
                  alt="Dell"
                  height="20"
                  width="60"
                />
              </a>
            </SwiperSlide>
          </Swiper>
          <div className="right-arrow-wrapper brand-footer-right-navigation">
            <RightArrowIcon />
          </div>
        </div>
      </div>
    </div>
  );
}
