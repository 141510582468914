import { createSlice } from '@reduxjs/toolkit';

const initialState = localStorage.getItem('cart')
  ? JSON.parse(localStorage.getItem('cart'))
  : [];

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCart(state, action) {
      localStorage.removeItem('cart');
      localStorage.setItem('cart', JSON.stringify(action.payload));
      return action.payload;
    },
    addCart(state, action) {
      const product = state.find((item) => item._id === action.payload._id);
      if (product) product.cartQuantity++;
      else state.push({ ...action.payload, cartQuantity: 1 });
      localStorage.setItem('cart', JSON.stringify(state));
    },
    removeCart(state, action) {
      const newCart = state.filter((item) => item._id !== action.payload);
      localStorage.setItem('cart', JSON.stringify(newCart));
      return newCart;
    },
    decrementCart(state, action) {
      const product = state.find((item) => item._id === action.payload);
      if (product.cartQuantity === 1) return;
      else product.cartQuantity--;
    },
    incrementCart(state, action) {
      const product = state.find((item) => item._id === action.payload);
      if (product.cartQuantity === 10) return;
      else product.cartQuantity++;
    },
    updateCartQuantity(state, action) {
      const product = state.find((item) => item._id === action.payload._id);
      if (action.payload.cartQuantity < 1) return;
      if (action.payload.cartQuantity > 10) return;
      product.cartQuantity = action.payload.cartQuantity;
      localStorage.setItem('cart', JSON.stringify(state));
    },
    resetCart(state, action) {
      localStorage.removeItem('cart');
      return (state = []);
    },
  },
});

export const readCart = (state) => state.cart;
export const readCartQuantity = (state) => state.cart.length;

export const {
  addCart,
  removeCart,
  decrementCart,
  incrementCart,
  updateCartQuantity,
  setCart,
  resetCart,
} = cartSlice.actions;

export default cartSlice.reducer;
