import { Outlet } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useRefreshMutation } from './authApiSlice';
import { useSelector } from 'react-redux';
import { selectCurrentToken } from './authSlice';
import { useGetUserInfoMutation } from '../users/userApiSlice';
import Loading from '../../components/Other/Loading';

const PersistLogin = () => {
  const token = useSelector(selectCurrentToken);
  const effectRan = useRef(false);
  const [getUserInfo] = useGetUserInfoMutation();

  const [trueSuccess, setTrueSuccess] = useState(false);

  const [refresh, { isUninitialized, isLoading, isSuccess, isError }] =
    useRefreshMutation();

  useEffect(() => {
    if (effectRan.current === true || 1) {
      // React 18 Strict Mode

      const verifyRefreshToken = async () => {
        // console.log('verifying refresh token');
        try {
          //const response =
          await refresh();
          await getUserInfo();
          //const { accessToken } = response.data
          setTrueSuccess(true);
        } catch (err) {
          // console.error(err);
        }
      };

      if (!token) verifyRefreshToken();
    }

    return () => (effectRan.current = true);

    // eslint-disable-next-line
  }, []);

  let content;
  if (isLoading) {
    //persist: yes, token: no
    // console.log('loading');
    content = <Loading />;
  } else if (isError) {
    //persist: yes, token: no
    // console.log('error');
    content = <Outlet />;
  } else if (isSuccess && trueSuccess) {
    //persist: yes, token: yes
    // console.log('success');
    content = <Outlet />;
  } else if (token && isUninitialized) {
    //persist: yes, token: yes
    // console.log('token and uninit');
    // console.log(isUninitialized);
    content = <Outlet />;
  }

  return content;
};
export default PersistLogin;
