import BrandFooter from './BrandFooter';
import '../../styles/Footer/footer.scss';
import MainFooter from './MainFooter';
import BottomFooter from './BottomFooter';

export default function Footer() {
  return (
    <footer>
      <BrandFooter />
      <MainFooter />
      <BottomFooter />
    </footer>
  );
}
