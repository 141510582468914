const language = localStorage.getItem('i18nextLng');

export const getDescription = (product) => {
  const language = localStorage.getItem('i18nextLng');
  switch (language) {
    case 'fr':
      return product.description_fr;
    case 'es':
      return product.description_es;
    default:
      return product.description_en;
  }
};

export const getLowestPrice = (product) => {
  const factor =
    language === 'fr' || language === 'es'
      ? 1
      : process.env.REACT_APP_USD_FACTOR;

  let price =
    product?.fnac_search_price && product?.darty_search_price
      ? product?.fnac_search_price < product?.darty_search_price
        ? product?.fnac_search_price
        : product?.darty_search_price
      : product?.fnac_search_price
      ? product?.fnac_search_price
      : product?.darty_search_price;

  return Number.parseFloat(price).toFixed(2) * factor;
};

export const getLowestOldPrice = (product) => {
  const factor =
    language === 'fr' || language === 'es'
      ? 1
      : process.env.REACT_APP_USD_FACTOR;

  let price =
    product?.fnac_product_price_old && product?.darty_product_price_old
      ? product?.fnac_product_price_old > product?.darty_product_price_old
        ? product?.fnac_product_price_old
        : product?.darty_product_price_old
      : product?.fnac_product_price_old
      ? product?.fnac_product_price_old
      : product?.darty_product_price_old;

  // return price;

  return Number.parseFloat(price).toFixed(2) * factor;
};

export const getPrice = (merchant, product) => {
  const factor =
    language === 'fr' || language === 'es'
      ? 1
      : process.env.REACT_APP_USD_FACTOR;

  let price =
    merchant === 'fnac'
      ? product?.fnac_search_price
      : merchant === 'darty'
      ? product?.darty_search_price
      : product?.amazon_search_price;

  return Number.parseFloat(price).toFixed(2) * factor;
};

export const getOffPercentage = (product) => {
  return product?.fnac_product_price_old && product?.darty_product_price_old
    ? product?.fnac_product_price_old < product?.darty_product_price_old
      ? Math.round(
          ((product?.fnac_product_price_old - product?.fnac_search_price) /
            product?.fnac_product_price_old) *
            100
        )
      : Math.round(
          ((product?.darty_product_price_old - product?.darty_search_price) /
            product?.darty_product_price_old) *
            100
        )
    : product?.fnac_product_price_old
    ? Math.round(
        ((product?.fnac_product_price_old - product?.fnac_search_price) /
          product?.fnac_product_price_old) *
          100
      )
    : Math.round(
        ((product?.darty_product_price_old - product?.darty_search_price) /
          product?.darty_product_price_old) *
          100
      );
};

export const getImage = (product) => {
  return product?.fnac_merchant_image_url
    ? product?.fnac_merchant_image_url
    : product?.darty_merchant_image_url;
};

export function transformStorage(value, language) {
  if (value < 1024) {
    if (language === 'fr') {
      return `${value} Go`;
    } else {
      return `${value} Gb`;
    }
  } else {
    if (language === 'fr') {
      if (value % 1024 === 0) return `${value / 1024} To`;
      else return `${(value / 1024).toFixed(1)} To`;
    } else {
      if (value % 1024 === 0) return `${value / 1024} Tb`;
      else return `${(value / 1024).toFixed(1)} Tb`;
    }
  }
}

export const handleTranslateSpec = (key, value, t) => {
  if (typeof value === 'boolean') {
    return value ? t('product.features.true') : t('product.features.false');
  }
  switch (key) {
    case 'Couleur':
      return t(`product.colors.${value}`);
    case 'Stockage':
      return transformStorage(value, localStorage.getItem('i18nextLng'));
    case "Densité de pixels sur l'écran":
      return `${value} PPI`;
    case "Définition de l'écran":
      return `${value} Pixels`;
    case "Taux de rafraîchissement de l'écran":
      return `${value} Hz`;
    case "Luminosité maximal de l'écran":
      return `${value} Nits`;
    case 'RAM':
      return `${value} ${
        localStorage.getItem('i18nextLng') === 'fr' ? 'Go' : 'Gb'
      }`;
    case 'Zoom Maximal':
      return `X${value}`;
    case 'Fréquence maximal vidéo':
      return `${value} FPS`;
    case 'Masse':
      return `${value} Kg`;
    case 'Note de réparabilité':
      return `${value} / 10`;
    case "Taille d'écran":
      return `${value} ${
        localStorage.getItem('i18nextLng') === 'fr' ? 'Pouces' : '"'
      }`;
    case 'Définition photo':
      return `${value} Mpx`;
    case 'Ouverture du diaphragme':
      return `f/${value}`;
    case 'Taille focal':
      return `${value} mm`;
    default:
      return value;
  }
};

export const getNameAutocomplete = (product, t) => {
  const language = localStorage.getItem('i18nextLng');
  return `${product?.Modèle}`;
};

export const getName = (product, t) => {
  const language = localStorage.getItem('i18nextLng');
  switch (language) {
    case 'fr':
      return `${product?.Modèle}, ${
        product?.Spec?.['Stockage'] &&
        transformStorage(product?.Spec?.['Stockage'], language) + ','
      }
      ${
        product?.Spec?.['Couleur'] &&
        t(`product.colors.${product?.Spec?.['Couleur']}`) + ','
      } 
      ${
        product.Spec["Taille d'écran"]
          ? product?.Spec?.["Taille d'écran"] + ' Pouces,'
          : ''
      }
      ${product?.Spec?.['Processeur'] && product?.Spec?.['Processeur']}`;
    default:
      return `${product?.Modèle}, ${
        product?.Spec?.['Stockage'] &&
        transformStorage(product?.Spec?.['Stockage'], language) + ','
      }
      ${
        product?.Spec?.['Couleur'] &&
        t(`product.colors.${product?.Spec?.['Couleur']}`) + ','
      } 
      ${
        product.Spec["Taille d'écran"]
          ? product.Spec["Taille d'écran"] + ' ",'
          : ''
      }
      ${product?.Spec?.['Processeur'] && product?.Spec?.['Processeur']}`;
  }
};

export const getDetails = (product) => {
  if (product.Catégorie === 'Smartphone' || product.Catégorie === 'Tablette') {
    return ['RAM', "Taille d'écran", "Dalle d'écran"];
  } else {
    return ['RAM', 'Processeur', 'Carte graphique'];
  }
};

export const getMoreDetails = (product) => {
  switch (product.Catégorie) {
    case 'Smartphone':
      return ['RAM', "Taille d'écran", "Dalle d'écran", 'Processeur'];
    case 'Tablette':
      return ['RAM', "Taille d'écran", 'Stockage', 'Processeur'];
    case 'Ordinateur Portable':
      return [
        'RAM',
        'Stockage',
        'Processeur',
        'Carte graphique',
        "Taille d'écran",
      ];
    case 'Unité Centrale':
      return ['RAM', 'Stockage', 'Processeur', 'Carte graphique'];
    case 'Ordinateur Tout en Un':
      return ['RAM', 'Stockage', 'Processeur', 'Carte graphique'];
    default: {
      return ['RAM', 'Stockage', 'Couleur'];
    }
  }
};

export const getFeatures = (category) => {
  if (category === 'Smartphone' || category === 'Tablette') {
    return [
      {
        title: 'Global',
        features: ['Os', 'Couleur'],
      },
      {
        title: 'Performance',
        features: [
          'Processeur',
          'Processeur graphique',
          'Carte graphique',
          'RAM',
        ],
      },
      {
        title: 'Stockage',
        features: ['Stockage', 'Type de stockage'],
      },
      {
        title: 'Écran',
        features: [
          "Taille d'écran",
          "Dalle d'écran",
          "Définition de l'écran",
          "Densité de pixels sur l'écran",
          "Taux de rafraîchissement de l'écran",
          "Taux de rafraîchissement de l'écran adaptatif",
          "Luminosité maximal de l'écran",
          'HDR écran',
          "Temps de réponse de l'écran",
          'Always On Display',
          'True Tone',
          'Écran tactile',
        ],
      },
      {
        title: 'Vidéo',
        features: ['Définition vidéo', 'Fréquence maximal vidéo'],
      },
      {
        title: 'Autre',
        features: [
          'Autonomie',
          'Recharge',
          'Webcam',
          'Masse',
          'Indice DAS Tronc',
          'Indice DAS Tête',
          'Indice DAS membre',
          'Note de réparabilité',
          'Dimensions',
          'Capteurs',
          'Connectivité',
          'Connectiques',
          'Norme de Résistance',
          'Sécurité',
          'Autres informations',
        ],
      },
    ];
  }

  if (
    category === 'Ordinateur Portable' ||
    category === 'Ordinateur Tout en Un'
  ) {
    return [
      {
        title: 'Global',
        features: ['Os', 'Couleur'],
      },
      {
        title: 'Performance',
        features: [
          'Processeur',
          'Processeur graphique',
          'Carte graphique',
          'RAM',
        ],
      },
      {
        title: 'Stockage',
        features: ['Stockage', 'Type de stockage'],
      },
      {
        title: 'Écran',
        features: [
          "Taille d'écran",
          "Dalle d'écran",
          "Définition de l'écran",
          "Densité de pixels sur l'écran",
          "Taux de rafraîchissement de l'écran",
          "Taux de rafraîchissement de l'écran adaptatif",
          "Luminosité maximal de l'écran",
          'HDR écran',
          "Temps de réponse de l'écran",
          'Always On Display',
          'True Tone',
          'Écran tactile',
        ],
      },
      {
        title: 'Autre',
        features: [
          'Autonomie',
          'Recharge',
          'Webcam',
          'Masse',
          'Indice DAS Tronc',
          'Indice DAS Tête',
          'Indice DAS membre',
          'Note de réparabilité',
          'Dimensions',
          'Capteurs',
          'Connectivité',
          'Connectiques',
          'Norme de Résistance',
          'Sécurité',
          'Autres informations',
        ],
      },
    ];
  }

  if (category === 'Unité Centrale') {
    return [
      {
        title: 'Global',
        features: ['Os', 'Couleur'],
      },
      {
        title: 'Performance',
        features: [
          'Processeur',
          'Processeur graphique',
          'Carte graphique',
          'RAM',
        ],
      },
      {
        title: 'Stockage',
        features: ['Stockage', 'Type de stockage'],
      },
      {
        title: 'Autre',
        features: [
          'Autonomie',
          'Recharge',
          'Webcam',
          'Masse',
          'Indice DAS Tronc',
          'Indice DAS Tête',
          'Indice DAS membre',
          'Note de réparabilité',
          'Dimensions',
          'Capteurs',
          'Connectivité',
          'Connectiques',
          'Norme de Résistance',
          'Sécurité',
          'Autres informations',
        ],
      },
    ];
  }
};

export const getFeaturesComparator = (category) => {
  if (category === 'Smartphone' || category === 'Tablette') {
    return [
      {
        title: 'Performance',
        features: [
          'Processeur',
          'Processeur graphique',
          'Carte graphique',
          'RAM',
        ],
      },
      {
        title: 'Stockage',
        features: ['Stockage', 'Type de stockage'],
      },
      {
        title: 'Écran',
        features: [
          "Taille d'écran",
          "Dalle d'écran",
          "Définition de l'écran",
          "Densité de pixels sur l'écran",
          "Taux de rafraîchissement de l'écran",
          "Taux de rafraîchissement de l'écran adaptatif",
          "Luminosité maximal de l'écran",
          'HDR écran',
          "Temps de réponse de l'écran",
          'Always On Display',
          'True Tone',
          'Écran tactile',
        ],
      },
      {
        title: 'Photo',
      },
      {
        title: 'Vidéo',
        features: ['Définition vidéo', 'Fréquence maximal vidéo'],
      },
      {
        title: 'Autre',
        features: [
          'Os',
          'Couleur',
          'Autonomie',
          'Recharge',
          'Webcam',
          'Masse',
          'Indice DAS Tronc',
          'Indice DAS Tête',
          'Indice DAS membre',
          'Note de réparabilité',
          'Dimensions',
          'Capteurs',
          'Connectivité',
          'Connectiques',
          'Norme de Résistance',
          'Sécurité',
          'Autres informations',
        ],
      },
    ];
  }

  if (
    category === 'Ordinateur Portable' ||
    category === 'Ordinateur Tout en Un'
  ) {
    return [
      {
        title: 'Performance',
        features: [
          'Processeur',
          'Processeur graphique',
          'Carte graphique',
          'RAM',
        ],
      },
      {
        title: 'Stockage',
        features: ['Stockage', 'Type de stockage'],
      },
      {
        title: 'Écran',
        features: [
          "Taille d'écran",
          "Dalle d'écran",
          "Définition de l'écran",
          "Densité de pixels sur l'écran",
          "Taux de rafraîchissement de l'écran",
          "Taux de rafraîchissement de l'écran adaptatif",
          "Luminosité maximal de l'écran",
          'HDR écran',
          "Temps de réponse de l'écran",
          'Always On Display',
          'True Tone',
          'Écran tactile',
        ],
      },
      {
        title: 'Autre',
        features: [
          'Os',
          'Couleur',
          'Autonomie',
          'Recharge',
          'Webcam',
          'Masse',
          'Indice DAS Tronc',
          'Indice DAS Tête',
          'Indice DAS membre',
          'Note de réparabilité',
          'Dimensions',
          'Capteurs',
          'Connectivité',
          'Connectiques',
          'Norme de Résistance',
          'Sécurité',
          'Autres informations',
        ],
      },
    ];
  }

  if (category === 'Unité Centrale') {
    return [
      {
        title: 'Performance',
        features: [
          'Processeur',
          'Processeur graphique',
          'Carte graphique',
          'RAM',
        ],
      },
      {
        title: 'Stockage',
        features: ['Stockage', 'Type de stockage'],
      },
      {
        title: 'Autre',
        features: [
          'Os',
          'Couleur',
          'Autonomie',
          'Recharge',
          'Webcam',
          'Masse',
          'Indice DAS Tronc',
          'Indice DAS Tête',
          'Indice DAS membre',
          'Note de réparabilité',
          'Dimensions',
          'Capteurs',
          'Connectivité',
          'Connectiques',
          'Norme de Résistance',
          'Sécurité',
          'Autres informations',
        ],
      },
    ];
  }
};

export const getSpec = (product) => {
  return `${product.Spec?.["Taille d'écran"]},${product.Spec?.['Stockage']},${product?.Spec?.Couleur},${product?.Spec?.Processeur}`;
};

export const getDefaultChecked = (product) => {
  // there is fnac_search_price, darty_search_price and amazon_search_price
  if (language !== 'fr' && product?.['Lien Amazon']) {
    return 'amazon';
  } else
    return product?.fnac_search_price &&
      product?.darty_search_price &&
      product?.amazon_search_price
      ? product?.fnac_search_price < product?.darty_search_price &&
        product?.fnac_search_price < product?.amazon_search_price
        ? 'fnac'
        : product?.darty_search_price < product?.fnac_search_price &&
          product?.darty_search_price < product?.amazon_search_price
        ? 'darty'
        : 'amazon'
      : product?.fnac_search_price && product?.darty_search_price
      ? product?.fnac_search_price < product?.darty_search_price
        ? 'fnac'
        : 'darty'
      : product?.fnac_search_price && product?.amazon_search_price
      ? product?.fnac_search_price < product?.amazon_search_price
        ? 'fnac'
        : 'amazon'
      : product?.darty_search_price && product?.amazon_search_price
      ? product?.darty_search_price < product?.amazon_search_price
        ? 'darty'
        : 'amazon'
      : product?.fnac_search_price
      ? 'fnac'
      : product?.darty_search_price
      ? 'darty'
      : 'amazon';
};

export const getDefaultLink = (product) => {
  if (language !== 'fr' && product?.['Lien Amazon']) {
    return product?.['Lien Amazon'];
  } else
    return product?.fnac_aw_deep_link
      ? product?.fnac_aw_deep_link
      : product?.darty_aw_deep_link;
};

export const getInStock = (product) => {
  // if there is fnac_in_stock that is 1 or darty_in_stock that is 1 so return true
  return product?.fnac_in_stock || product?.darty_in_stock;
};

export const getMerchantLink = (product) => {
  const language = localStorage.getItem('i18nextLng');

  if (language === 'fr') {
    return product?.fnac_search_price && product?.darty_search_price
      ? product?.fnac_search_price < product?.darty_search_price
        ? product?.fnac_aw_deep_link
        : product?.darty_aw_deep_link
      : product?.fnac_aw_deep_link
      ? product?.fnac_aw_deep_link
      : product?.darty_aw_deep_link;
  } else {
    return product?.['Lien Amazon'];
  }
};

export const getFilters = (category) => {
  if (category === 'Smartphone' || category === 'Tablette') {
    return ['performance', 'screen', 'photo', 'video', 'gaming'];
  } else if (category === 'Ordinateur Portable') {
    return ['performance', 'screen', 'gaming', 'work', 'school', 'transport'];
  } else if (category === 'Unité Centrale') {
    return ['performance', 'gaming', 'work', 'school'];
  } else if (category === 'Ordinateur Tout en Un') {
    return ['performance', 'screen', 'gaming', 'work', 'school'];
  }
};

export const showProductPrice = (price, currency) => {
  const priceString = price.toString(); // Convert the price to a string

  if (priceString.includes('.')) {
    const [integerPart, decimalPart] = priceString.split('.');
    return (
      <>
        {integerPart}
        {currency}
        <span className="decimal-price">{decimalPart}</span>
      </>
    );
  } else {
    return (
      <>
        {price}
        {currency}
      </>
    );
  }
};
