import { createSlice } from '@reduxjs/toolkit';

const initialState = { mode: 'simple' };

const modeSlice = createSlice({
  name: 'mode',
  initialState,
  reducers: {
    toggleMode(state, action) {
      state.mode = state.mode === 'simple' ? 'expert' : 'simple';
    },
    changeMode(state, action) {
      state.mode = action.payload;
    },
  },
});

export const readMode = (state) => state.mode;

export const { toggleMode, changeMode } = modeSlice.actions;

export default modeSlice.reducer;
