export default function TwitterIcon() {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_115_1993)">
                <path d="M15.875 3.52765C15.2893 3.78457 14.6652 3.95487 14.0145 4.03755C14.6839 3.6379 15.1948 3.00987 15.435 2.25288C14.8109 2.62498 14.1218 2.8878 13.3875 3.03448C12.7949 2.40349 11.9503 2.0127 11.0289 2.0127C9.2413 2.0127 7.80214 3.46366 7.80214 5.24243C7.80214 5.49837 7.8238 5.74446 7.87695 5.97874C5.19256 5.84782 2.81727 4.56124 1.22159 2.60135C0.943016 3.08468 0.779609 3.6379 0.779609 4.23345C0.779609 5.3517 1.35547 6.34296 2.21384 6.91685C1.69508 6.90701 1.18616 6.7564 0.755 6.51916C0.755 6.52901 0.755 6.5418 0.755 6.5546C0.755 8.1237 1.87423 9.42701 3.34194 9.72724C3.07911 9.7991 2.79266 9.83355 2.49537 9.83355C2.28866 9.83355 2.07997 9.82174 1.88408 9.77843C2.30244 11.0571 3.48959 11.9972 4.90119 12.0277C3.80263 12.8871 2.40777 13.4049 0.897734 13.4049C0.632938 13.4049 0.378969 13.3931 0.125 13.3606C1.5553 14.2829 3.25039 14.8096 5.07837 14.8096C11.0201 14.8096 14.2685 9.8877 14.2685 5.62141C14.2685 5.47868 14.2636 5.34087 14.2567 5.20404C14.8975 4.74926 15.436 4.18127 15.875 3.52765Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_115_1993">
                    <rect width="15.75" height="15.75" fill="white" transform="translate(0.125 0.536133)" />
                </clipPath>
            </defs>
        </svg>

    )
}