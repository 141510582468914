import { createSlice } from '@reduxjs/toolkit';

const initialState = localStorage.getItem('query')
  ? { value: localStorage.getItem('query') }
  : { value: '' };

const querySlice = createSlice({
  name: 'query',
  initialState,
  reducers: {
    updateQuery(state, action) {
      state.value = action.payload;
      localStorage.setItem('query', action.payload);
    },
  },
});

export const readQuery = (state) => state.query.value;

export const { updateQuery } = querySlice.actions;

export default querySlice.reducer;
