import '../../styles/Header/nav-header.scss';
import LeftArrowIcon from '../../Icons/LeftArrowIcon';
import RightArrowIcon from '../../Icons/RightArrowIcon';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';

export default function NavHeader() {
  const handleRedirect = (e) => {
    window.location.href = `/product-results?q=${e.target.innerText}`;
  };

  return (
    <div className="nav-header">
      <div className="left-arrow-wrapper nav-header-left-navigation">
        <LeftArrowIcon />
      </div>
      <Swiper
        tag="nav"
        modules={[Navigation]}
        slidesPerView={3}
        navigation={{
          prevEl: '.nav-header-left-navigation',
          nextEl: '.nav-header-right-navigation',
        }}
        breakpoints={{
          400: {
            slidesPerView: 4,
          },
          500: {
            slidesPerView: 5,
          },
          600: {
            slidesPerView: 6,
          },
          768: {
            slidesPerView: 7,
          },
          1024: {
            slidesPerView: 8,
          },
          1280: {
            slidesPerView: 9,
          },
        }}
      >
        <SwiperSlide href="/product-results?q=Macbook Pro" tag="a">
          Macbook Pro
        </SwiperSlide>
        <SwiperSlide href="/product-results?q=Hp Pavillon" tag="a">
          Hp Pavillon
        </SwiperSlide>
        <SwiperSlide href="/product-results?q=Core i9" tag="a">
          Core i9
        </SwiperSlide>
        <SwiperSlide href="/product-results?q=Iphone 14" tag="a">
          Iphone 14
        </SwiperSlide>
        <SwiperSlide href="/product-results?q=Xiamo 13 Pro" tag="a">
          Xiamo 13 Pro
        </SwiperSlide>
        <SwiperSlide href="/product-results?q=Ipad Pro 2023" tag="a">
          Ipad Pro 2023
        </SwiperSlide>
        <SwiperSlide href="/product-results?q=Zenbook" tag="a">
          Zenbook
        </SwiperSlide>
        <SwiperSlide href="/product-results?q=Gaming pc" tag="a">
          Gaming pc
        </SwiperSlide>
        <SwiperSlide href="/product-results?q=Samsung" tag="a">
          Samsung
        </SwiperSlide>
        <SwiperSlide href="/product-results?q=Apple" tag="a">
          Apple
        </SwiperSlide>
        <SwiperSlide href="/product-results?q=Xiamo Tablette" tag="a">
          Xiamo Tablette
        </SwiperSlide>
        <SwiperSlide href="/product-results?q=Asus" tag="a">
          Asus
        </SwiperSlide>
        <SwiperSlide href="/product-results?q=Lenovo" tag="a">
          Lenovo
        </SwiperSlide>
        <SwiperSlide href="/product-results?q=Hp" tag="a">
          Hp
        </SwiperSlide>
      </Swiper>
      <div className="right-arrow-wrapper nav-header-right-navigation">
        <RightArrowIcon />
      </div>
    </div>
  );
}
