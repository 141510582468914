import { createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import i18n from '../../i18n';

const initialState = localStorage.getItem('compare')
  ? JSON.parse(localStorage.getItem('compare'))
  : [];

const compareSlice = createSlice({
  name: 'compare',
  initialState,
  reducers: {
    addCompare(state, action) {
      if (state.length === 3) {
        message.error(i18n.t('comparator.error_max'));
        return;
      }
      state.push(action.payload._id);
      localStorage.setItem('compare', JSON.stringify(state));
    },
    removeCompare(state, action) {
      const newCompare = state.filter(
        (item) => item !== action.payload._id
      );
      localStorage.setItem('compare', JSON.stringify(newCompare));
      return newCompare;
    },
    resetCompare(state, action) {
      return (state = []);
    },
    filterCompare(state, action) {
      switch (action.payload) {
        case 'by_default':
          return JSON.parse(localStorage.getItem('compare'));
        case 'performance': {
          const newCompare = state.sort((a, b) => {
            return b?.search_score?.performance - a?.search_score?.performance;
          });
          return newCompare;
        }
        case 'screen': {
          const newCompare = state.sort((a, b) => {
            return b?.search_score?.screen - a?.search_score?.screen;
          });
          return newCompare;
        }
        case 'photo': {
          const newCompare = state.sort((a, b) => {
            return b?.search_score?.photo - a?.search_score?.photo;
          });
          return newCompare;
        }
        case 'video': {
          const newCompare = state.sort((a, b) => {
            return b?.search_score?.video - a?.search_score?.video;
          });
          return newCompare;
        }
        case 'gaming': {
          const newCompare = state.sort((a, b) => {
            return (
              b?.search_score?.performance +
              b?.search_score?.screen -
              (a?.search_score?.performance + a?.search_score?.screen)
            );
          });
          return newCompare;
        }
        case 'work': {
          const newCompare = state.sort((a, b) => {
            return (
              b?.search_score?.performance +
              b?.search_score?.screen -
              (a?.search_score?.performance + a?.search_score?.screen)
            );
          });
          return newCompare;
        }
        case 'school': {
          const newCompare = state.sort((a, b) => {
            return (
              b?.search_score?.performance +
              b?.Spec?.Stockage -
              (a?.search_score?.performance + a?.Spec?.Stockage)
            );
          });
          return newCompare;
        }
        case 'transport': {
          const newCompare = state.sort((a, b) => {
            return b?.Spec?.Masse - a?.Spec?.Masse;
          });
          return newCompare;
        }
        case 'asc_price': {
          const newCompare = state.sort((a, b) => {
            return (
              (a.fnac_search_price
                ? a.fnac_search_price
                : a.darty_search_price) -
              (b.fnac_search_price ? b.fnac_search_price : b.darty_search_price)
            );
          });
          return newCompare;
        }
        case 'desc_price': {
          const newCompare = state.sort((a, b) => {
            return (
              (b.fnac_search_price
                ? b.fnac_search_price
                : b.dart_search_price) -
              (a.fnac_search_price ? a.fnac_search_price : a.dart_search_price)
            );
          });
          return newCompare;
        }
        default:
          return state;
      }
    },
  },
});

export const readCompare = (state) => state.compare;

export const { addCompare, removeCompare, resetCompare, filterCompare } =
  compareSlice.actions;

export default compareSlice.reducer;
