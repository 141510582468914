export default function YoutubeIcon() {
  return (
    <svg
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.03125 11.1655V11.1661C1.04281 11.4649 1.04666 11.7654 1.11846 12.0623C1.19489 12.3764 1.30414 12.6827 1.51254 12.9679C1.79431 13.3538 2.18006 13.6798 2.66979 13.9459C3.02049 14.137 3.40362 14.2887 3.82774 14.3845C4.44609 14.5238 5.08408 14.5773 5.73139 14.5799C6.13525 14.5817 6.53888 14.5887 6.94289 14.5875C9.87683 14.5789 12.8108 14.6023 15.7449 14.5749C16.1342 14.5712 16.5172 14.538 16.8993 14.4892C17.6317 14.3955 18.2789 14.1769 18.8327 13.8319C19.4781 13.4297 19.9136 12.9373 20.1102 12.3432C20.2388 11.9548 20.271 11.5608 20.2764 11.1654V11.0865C20.2764 11.056 20.2598 4.36821 20.2582 4.27981C20.254 4.03701 20.2284 3.79598 20.1633 3.55705C20.084 3.26591 19.972 2.98219 19.7726 2.71882C19.5619 2.44048 19.298 2.1903 18.9713 1.97162C18.4714 1.63659 17.8944 1.39971 17.2225 1.27438C16.6158 1.16132 15.9969 1.13613 15.3734 1.13656C15.3714 1.1346 15.3702 1.13229 15.37 1.12988H5.93424C5.93424 1.13214 5.93424 1.13435 5.9337 1.13656C5.57985 1.14108 5.22553 1.1392 4.87352 1.16972C4.48792 1.20321 4.10595 1.25365 3.74099 1.34787C3.1661 1.49646 2.66656 1.7279 2.2399 2.03698C1.75645 2.38691 1.41554 2.79187 1.23495 3.26332C1.07355 3.68395 1.03841 4.11292 1.03757 4.54496"
        fill="#232323"
      />
      <path
        d="M11.1178 15.0943L6.82085 15.0157C5.42959 14.9883 4.03487 15.043 2.67088 14.7591C0.595949 14.3348 0.448953 12.2547 0.295138 10.5098C0.083196 8.05683 0.165245 5.55928 0.565207 3.12674C0.790999 1.76182 1.67958 0.947371 3.05374 0.858744C7.69254 0.5371 12.3622 0.575218 16.9907 0.725276C17.4795 0.739033 17.9717 0.814219 18.4537 0.899802C20.8331 1.31721 20.8911 3.67447 21.0453 5.65883C21.1991 7.66367 21.1342 9.6788 20.8402 11.67C20.6043 13.3186 20.1531 14.7012 18.2486 14.8347C15.8625 15.0092 13.5311 15.1497 11.1383 15.105C11.1384 15.0943 11.1246 15.0943 11.1178 15.0943ZM8.59162 10.9204C10.3898 9.88714 12.1536 8.87107 13.9415 7.84471C12.14 6.81141 10.3795 5.79534 8.59162 4.76898V10.9204Z"
        fill="white"
      />
    </svg>
  );
}
