import { Suspense, lazy, useEffect, useState } from 'react';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import { Routes, Route, useLocation } from 'react-router-dom';
import CookiePopUp from './components/PopUp/CookiePopUp';
import PersistLogin from './features/auth/PersistLogin';
import RequireAuth from './components/RequireAuth';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  readPopUp,
  cleanPopUp,
  toggleCookie,
} from './features/popUp/popUpSlice';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import {
  clearTopMessage,
  readTopMessage,
} from './features/topMessage/topMessageSlice';
import { AnimatePresence } from 'framer-motion';
import i18next from 'i18next';
import { selectCurrentToken } from './features/auth/authSlice';
import { useGetCookiesMutation } from './features/cookies/cookiesApiSlice';
import Loading from './components/Other/Loading';
import { resetCompare } from './features/compare/compareSlice';

// Pages
const Home = lazy(() => import('./pages/Home'));
const Login = lazy(() => import('./pages/Login'));
const Register = lazy(() => import('./pages/Register'));
const ProductResults = lazy(() => import('./pages/ProductResults'));
const ProductPage = lazy(() => import('./pages/ProductPage'));
const Favorites = lazy(() => import('./pages/Favorites'));
const Cart = lazy(() => import('./pages/Cart'));
const Comparator = lazy(() => import('./pages/Comparator'));
const History = lazy(() => import('./pages/History'));
const TermsConditions = lazy(() => import('./pages/TermsConditions'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const CookiesPolicy = lazy(() => import('./pages/CookiesPolicy'));
const Affiliate = lazy(() => import('./pages/Affiliate'));
const AboutUs = lazy(() => import('./pages/AboutUs'));
const Help = lazy(() => import('./pages/Help'));
const Faq = lazy(() => import('./pages/Faq'));
const ManageCookies = lazy(() => import('./pages/ManageCookies'));
const Subscriptions = lazy(() => import('./pages/Subscriptions'));
const SubscriptionSuccess = lazy(() => import('./pages/SubscriptionSuccess'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const PasswordReset = lazy(() => import('./pages/PasswordReset'));
const ChangePassword = lazy(() => import('./pages/ChangePassword'));
const ChangeEmail = lazy(() => import('./pages/ChangeEmail'));
const VerifyAccount = lazy(() => import('./pages/VerifyAccount'));
const DeleteAccount = lazy(() => import('./pages/DeleteAccount'));
const WilBot = lazy(() => import('./pages/Wilbot'));
const PersonalData = lazy(() => import('./pages/PersonalData'));
const Security = lazy(() => import('./pages/Security'));
const DataUsage = lazy(() => import('./pages/DataUsage'));
const TwoFactor = lazy(() => import('./pages/TwoFactor'));
const Support = lazy(() => import('./pages/Support'));
const ManageSubscription = lazy(() => import('./pages/ManageSubscription'));
const Page404 = lazy(() => import('./pages/404'));

// Components
const ContinuePopUp = lazy(() => import('./components/PopUp/ContinuePopUp'));
const CartPopUp = lazy(() => import('./components/PopUp/CartPopUp'));
const ProfilePopUp = lazy(() => import('./components/PopUp/ProfilePopUp'));
const MenuPopUp = lazy(() => import('./components/PopUp/MenuPopUp'));

function App() {
  // const { trackPageView } = useMatomo();
  const dispatch = useDispatch();
  const popUp = useSelector(readPopUp);
  const location = useLocation();
  const [mobileMenuCategory, setMobileMenuCategory] = useState('');
  const { i18n } = useTranslation();
  // const { pushInstruction } = useMatomo();
  const topMessage = useSelector(readTopMessage);
  const token = useSelector(selectCurrentToken);
  const [getCookies] = useGetCookiesMutation();

  useEffect(() => {
    if (token) {
      const userCookies = getCookies();
      Cookies.set('cookieConsent', JSON.stringify(userCookies), {
        expires: 180,
      });
    } else if (!Cookies.get('cookieConsent')) {
      Cookies.set(
        'cookieConsent',
        JSON.stringify({
          essential: true,
          analytics: false,
          ads: false,
        }),
        {
          expires: 180,
        }
      );
      dispatch(toggleCookie());
    } else {
      let cookieConsent = JSON.parse(Cookies.get('cookieConsent'));
      if (!cookieConsent.analytics) {
        // pushInstruction('forgetCookieConsentGiven');
      }
    }
    const loading = document.getElementById('loading-wrapper');
    if (loading) {
      loading.outerHTML = '';
    }
  }, []);

  useEffect(() => {
    dispatch(cleanPopUp());
    window.scrollTo(0, 0);
    // trackPageView();
  }, [location.pathname]);

  useEffect(() => {
    if (i18next.language) {
      if (!['fr', 'en', 'es'].includes(i18next.language.split('-')[0])) {
        i18n.changeLanguage('en');
      } else i18n.changeLanguage(i18next.language.split('-')[0]);
    } else i18n.changeLanguage('en');

    // if the local storage compare is not only string, reset it
    const compare = JSON.parse(localStorage.getItem('compare'));
    if (compare && !compare.every((item) => typeof item === 'string')) {
      localStorage.removeItem('compare');
      dispatch(resetCompare());
    }
  }, []);

  useEffect(() => {
    if (topMessage?.type) {
      if (topMessage.type === 'compare_limit') {
        return;
      } else if (topMessage.type === 'autorize') {
        setTimeout(() => {
          dispatch(clearTopMessage());
        }, 15000);
      } else {
        if (
          topMessage.type !== 'compare_limit' ||
          topMessage.type !== 'autorize'
        ) {
          setTimeout(() => {
            dispatch(clearTopMessage());
          }, 5000);
        }
      }
    }
  }, [topMessage]);

  return (
    <div className="App">
      <Header
        mobileMenuCategory={mobileMenuCategory}
        setMobileMenuCategory={setMobileMenuCategory}
      />

      <Suspense>
        <MenuPopUp
          mobileMenuCategory={mobileMenuCategory}
          setMobileMenuCategory={setMobileMenuCategory}
        />
      </Suspense>
      {(popUp.profile ||
        popUp.cart ||
        popUp.menu ||
        popUp.search ||
        popUp.productsFilterPopUp) && <div className="hider" />}
      {(popUp.cookie || popUp.continue || popUp.priceChart) && (
        <div className="hider" style={{ zIndex: 7 }} />
      )}
      <Suspense>
        <CartPopUp />
      </Suspense>
      <Suspense>
        <ProfilePopUp />
      </Suspense>
      <Suspense fallback={<Loading />}>
        <AnimatePresence mode="wait">
          <Routes key={location.pathname} location={location}>
            <Route element={<PersistLogin />}>
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Login />} />
              <Route path="/register" element={<Register />} />
              {/* <Route element={<RequireAuth />}> */}
              <Route path="/" element={<Home />} />
              <Route
                path="/product-results/:options?"
                element={<ProductResults />}
              />
              <Route path="/product-page/:id" element={<ProductPage />} />
              <Route path="/favorites" element={<Favorites />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/comparator" element={<Comparator />} />
              <Route path="/history" element={<History />} />
              <Route path="/terms-conditions" element={<TermsConditions />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/cookies-policy" element={<CookiesPolicy />} />
              <Route path="/affiliate" element={<Affiliate />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/help" element={<Help />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/manage-cookies" element={<ManageCookies />} />
              <Route path="/subscriptions" element={<Subscriptions />} />
              <Route
                path="/subscription-success"
                element={<SubscriptionSuccess />}
              />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route
                path="/password-reset/:userId/:token"
                element={<PasswordReset />}
              />
              <Route
                path="/change-password/:userId/:token"
                element={<ChangePassword />}
              />
              <Route
                path="/change-email/:userId/:token"
                element={<ChangeEmail />}
              />
              <Route
                path="/verify-account/:userId/:token"
                element={<VerifyAccount />}
              />
              <Route
                path="/delete-account/:userId/:token"
                element={<DeleteAccount />}
              />

              <Route path="/wilbot" element={<WilBot />} />
              <Route element={<RequireAuth />}>
                <Route path="/account">
                  <Route index element={<PersonalData />} />
                  <Route path="security" element={<Security />} />
                  <Route path="data-usage" element={<DataUsage />} />
                  <Route path="two-factor" element={<TwoFactor />} />
                  <Route path="support" element={<Support />} />
                  <Route
                    path="manage-subscription"
                    element={<ManageSubscription />}
                  />
                </Route>
              </Route>
            </Route>
            {/* </Route> */}
          </Routes>
        </AnimatePresence>
      </Suspense>
      {popUp.continue && (
        <Suspense>
          <ContinuePopUp />
        </Suspense>
      )}
      {location.pathname !== '/wilbot' ? <Footer /> : ''}
      {popUp.cookie && <CookiePopUp />}
    </div>
  );
}

export default App;
