import { apiSlice } from '../../app/api/apiSlice';

export const cookiesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCookies: builder.mutation({
      query: () => ({
        url: 'api/user/cookies',
        method: 'GET',
        credentials: 'include',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          console.log(err);
        }
      },
    }),
    updateCookies: builder.mutation({
      query: (data) => ({
        url: 'api/user/cookies',
        method: 'POST',
        credentials: 'include',
        body: data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {}
      },
    }),
  }),
});

export const { useGetCookiesMutation, useUpdateCookiesMutation } =
  cookiesApiSlice;
