import { TypeAnimation } from 'react-type-animation';
import { useTranslation } from 'react-i18next';

export default function Loading({ type = 'large' }) {
  const { t } = useTranslation();
  return (
    <div
      className={
        type === 'large'
          ? 'loading-wrapper loading-wrapper-large'
          : 'loading-wrapper'
      }
    >
      <div className="wrapper">
        <img src="/img/wilfind.png" alt="Wilfind" />
        <TypeAnimation
          sequence={[
            t('loading.loading_0'),
            1000,
            t('loading.loading_1'),
            1000,
            t('loading.loading_2'),
            1000,
            t('loading.loading_3'),
            1000,
          ]}
          speed={5}
          repeat={Infinity}
        />
      </div>
    </div>
  );
}
