import { createSlice } from '@reduxjs/toolkit';

const initialState = localStorage.getItem('favorites')
  ? JSON.parse(localStorage.getItem('favorites'))
  : [];

const favoritesSlice = createSlice({
  name: 'favorites',
  initialState,
  reducers: {
    setFavorites(state, action) {
      localStorage.removeItem('favorites');
      localStorage.setItem('favorites', JSON.stringify(action.payload));
      return action.payload;
    },
    addFavorite(state, action) {
      const duplicate = state.find((item) => item._id === action.payload._id);
      if (duplicate) {
        const newFavorites = state.filter(
          (favorite) => favorite._id !== action.payload._id
        );
        localStorage.setItem('favorites', JSON.stringify(newFavorites));
        return newFavorites;
      } else {
        state.push(action.payload);
        localStorage.setItem('favorites', JSON.stringify(state));
      }
    },
    removeFavorite(state, action) {
      const newFavorite = state.filter(
        (favorite) => favorite._id !== action.payload
      );
      localStorage.setItem('favorites', JSON.stringify(newFavorite));
      return newFavorite;
    },
    resetFavorites(state, action) {
      localStorage.removeItem('favorites');
      return (state = []);
    },
    filterFavorites(state, action) {
      // sort state by price asc or desc
      if (action.payload === 'priceAsc') {
        return state.sort((a, b) => a.Prix - b.Prix);
      } else if (action.payload === 'priceDesc') {
        return state.sort((a, b) => b.Prix - a.Prix);
      } else if (action.payload === 'revelance') {
        return (state = JSON.parse(localStorage.getItem('favorites')));
      }
    },
    filterPrice(state, action) {
      // filter state by price range
      const filteredProducts = JSON.parse(localStorage.getItem('favorites'));
      const filtered = filteredProducts.filter((product) => {
        return (
          product.Prix >= action.payload[0] && product.Prix <= action.payload[1]
        );
      });
      return (state = filtered);
    },
    searchFilter(state, action) {
      // filter state with an objects of filters
      const filters = action.payload;
      const filteredProducts = JSON.parse(localStorage.getItem('favorites'));
      const filtered = filteredProducts.filter((product) => {
        return Object.keys(filters).every((key) => {
          if (!filters[key].length) {
            return true;
          }
          if (key === 'Marque') return filters[key].includes(product[key]);
          else return filters[key].includes(product.Spec[key]);
        });
      });
      return (state = filtered);
    },
  },
});

export const readFavorites = (state) => state.favorites;
export const readFavoritesQuantity = (state) => state.favorites.length;

export const {
  addFavorite,
  removeFavorite,
  setFavorites,
  resetFavorites,
  searchFilter,
  filterPrice,
  filterFavorites,
} = favoritesSlice.actions;

export default favoritesSlice.reducer;
